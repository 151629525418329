export function getStorage(item) {
  let data = localStorage.getItem(item) || null;
  return JSON.parse(dec(data));
}

export function setStorage(item, value) {
  localStorage.setItem(item, enc(JSON.stringify(value)));
  return true;
}

export function deleteStorage(item) {
  localStorage.removeItem(item);
  return true;
}

const shouldEncode = () => process.env.NODE_ENV === "production";

function utf8_to_b64(str) {
  return btoa(unescape(encodeURIComponent(str)));
}
function b64_to_utf8(str) {
  return decodeURIComponent(escape(atob(str)));
}

const enc = (s) => (s ? (shouldEncode() ? utf8_to_b64(s) : s) : s);
const dec = (s) => (s ? (shouldEncode() ? b64_to_utf8(s) : s) : s);

/* sauce: 2019 @thinkdj: Uppcoming localStorage code */

/* ====================================== */
/*   LocalStorage Quick SYNCHRONOUS API   */
/* ====================================== */

/* getItem() will get the item as it was saved (number, string, Object, array ...) */
export function getItem(keyStr, defaultValue = null) {
  let returnable = defaultValue;
  if (!keyStr) return returnable;
  let parseArray = keyStr.split(".");
  //console.log("parseArr", parseArray);
  if (parseArray.length === 0) return returnable;
  let dataLS = localStorage.getItem(String(parseArray.shift()));
  //console.log("data parsed",dataLS);
  if (dataLS) {
    //dataLS = enc(dataLS);
    dataLS = isJsonParseable(dataLS) ? JSON.parse(dataLS) : dataLS;
    returnable = dataLS;
    if (parseArray.length && typeof dataLS === "object") {
      // parseArray is already `shift`ed
      let dataSub = Object.assign(dataLS);
      for (let i = 0; i < parseArray.length; i++) {
        let key = String(parseArray[i]);
        if (dataSub.hasOwnProperty(key)) {
          returnable = dataSub[key]; // set returnable
          dataSub = dataSub[key]; // reset data for next loop
        } else returnable = defaultValue;
      }
    }
  }
  return returnable;
}

export function setItem(keyStr, data) {
  if (!keyStr) return false;
  // Process
  let keyNames = keyStr.split(".");
  let keyNameTop = String(keyNames[0]);
  let nestedLevels = keyNames.length;
  if (nestedLevels > 1) {
    // Get main; save sub --> https://stackoverflow.com/a/26909342/849829
    let objNew,
      objTemp = {};
    objNew = objTemp;
    // Create
    for (let i = 1; i < nestedLevels - 1; i++) {
      // Start from 1
      objTemp = objTemp[keyNames[i]] = {};
    }
    // Set data
    objTemp[keyNames[nestedLevels - 1]] = data;
    // Get existing data
    let dataFromLS = getItem(keyNameTop, {});
    // Merge with old data
    data = Object.assign({}, dataFromLS, objNew);
  }
  // Data
  data = JSON.stringify(data);
  //data = enc(data);
  localStorage.setItem(keyNameTop, data);
}

export function removeItem(key) {
  localStorage.removeItem(key);
}

export function removeItemsContaining(keyStr) {
  if (!keyStr) return false;
  let lsArray = Object.keys(localStorage);
  //NOTE: for i < localStorage.length was IGNORING some items; Object.keys works best
  lsArray.forEach((lsKey) => {
    if (lsKey && lsKey.includes(keyStr)) {
      localStorage.removeItem(lsKey);
      console.log("StorageService - Removed Item from localStorage: ", lsKey);
    }
  });
  return true;
}

export function countItemsContaining(keyStr) {
  if (!keyStr) return false;
  let lsArray = Object.keys(localStorage);
  let total = 0;
  //NOTE: for i < localStorage.length was IGNORING some items; Object.keys works best
  lsArray.forEach((lsKey) => {
    if (lsKey && lsKey.includes(keyStr)) {
      total += 1;
    }
  });
  return total;
}

export function isJsonParseable(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
