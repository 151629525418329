import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, IconButton, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { Icon } from "@iconify/react";
import { fetchNotificationHistory } from "src/utils/api";
import { formatDateTime } from "src/utils/helperFunctions";

const NotificationHistoryDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const [notifications, setNotifications] = useState<any[]>([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const notificationsData = await fetchNotificationHistory();
        setNotifications(notificationsData);
      } catch (error) {
        console.error("Error fetching notification history:", error);
      }
    };

    if (open) {
      fetchNotifications();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography variant="h6">Notification History</Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Icon icon="mdi:close" />
        </IconButton>
      </DialogTitle>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Notification</TableCell>
              <TableCell>Entity ID</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notifications?.map((notification: any) => (
              <TableRow key={notification.id}>
                <TableCell>{notification.message}</TableCell>
                <TableCell>{notification.entity_id}</TableCell>
                <TableCell>{formatDateTime(notification.created_at)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  );
};

export default NotificationHistoryDialog;
