import React, { useEffect, useState, useCallback } from "react";
import {
    Box,
    Button,
    MenuItem,
    Dialog,

    IconButton,
    Card,
    Typography,
    CardContent,
    Grid,
    ToggleButton,
    ToggleButtonGroup,
    Stack,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { useNotifications } from 'src/context/NotificationsContext';

// Form Validation
import { useAuth } from "src/context/authContext";
import { axiosInstance as axios } from "../../utils/axios";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { RHFToggle } from 'src/components/RHFTextField';
import { LoadingButton } from '@mui/lab';
import FormProvider from "src/components/FormProvider";
import RHFTextField from "src/components/RHFTextField";
import { RHFUploadSingleFile } from "src/components/hook-form/RHFUpload";
import useAlerts from "src/components/Alerts";

interface Department {
    id: number;
    name: string;
}
const CreateSnag = ({ open, onClose, getdata }: any) => {
    const theme = useTheme();
    const { refreshNotifications } = useNotifications();
    const { showAlert } = useAlerts();

    const { user } = useAuth()
    const [assignables, setAssignables] = useState([]);
    const [areas, setAreas] = useState([]);
    const [items, setItems] = useState<any>([]);
    // const [departmentOptions, setDepartmentOptions] = useState([]);
    const [departmentOptions, setDepartmentOptions] = useState<Department[]>([]);

    const [departmentAssignmentText, setDepartmentAssignmentText] = useState('');
    const [manualDept, setManualDept] = useState<string | null>(null);
    const [originalDept, setOriginalDept] = useState('');
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isloading, setIsLoading] = useState(false);

    const RequestSchema = Yup.object().shape({
        area: Yup.string(),
        location: Yup.string().when('area', {
            is: '',
            then: Yup.string().required('Location/Area Required'),
        }),
        item: Yup.string().required('Item Required'),
        dept: Yup.string(),
        assign: Yup.string().required('Assign Required'),
        comments: Yup.string(),
        priority: Yup.string().required('Priority Required'),
        extra: Yup.string(),
        guestComplaint: Yup.boolean(),
        attachment: Yup.mixed(),
    });

    const defaultValues = {
        area: '',
        location: '',
        item: '',
        dept: '',
        assign: 'dept',
        comments: '',
        priority: 'normal',
        extra: 'NA',
        guestComplaint: false,
        attachment: {},
        organization: '',
    };
    type FormValues = {
        area: string;
        location: string;
        item: string;
        dept: string;
        assign: string;
        comments: string;
        priority: string;
        extra: string;
        guestComplaint: boolean;
        attachment: object;
        organization: string;
    };

    const methods = useForm<FormValues>({
        resolver: yupResolver(RequestSchema),
        defaultValues,
    });
    const { register, handleSubmit, getValues, control, setError, reset, setValue, watch } = methods;

    const onSubmit = (data: any) => {
        // console.log(data);
        onCreateWizard(data);
    };

    const org = useWatch({
        control,
        name: 'organization',
    });

    const handleDrop = useCallback(
        (acceptedFiles: any) => {
            const file = acceptedFiles[0];

            if (file) {
                setValue(
                    'attachment',
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                );
            }
        },
        [setValue]
    );

    const getAssignables = async () => {
        axios.get(`hotel/${user?.hotel_id}/assignables/all?hotel_id=${user?.hotel_id}`).then((res) => {
            setAssignables(res.data);
        });
    };

    const getItems = async (organization: any) => {
        try {
            if(organization){

            const res = await axios.get(`hotel/${organization}/all-items-with-dept?cache=true`);
            setItems(res.data);
            }
        } catch (error) {
            console.error("Error fetching items:", error);
        }
    };

    const getDepartments = async (organization: any) => {
        try {
            if(organization){
            const response = await axios.get(`/hotel/${organization}/desks`);
            const departmentData = response.data.map((dept: any) => ({
                id: dept.id,
                name: dept.name,
            }));
            setDepartmentOptions(departmentData);
        }
        } catch (error) {
            console.error('Error fetching department values:', error);
        }
    };


    const getAreas = async () => {
        axios.get(`hotel/${user?.hotel_id}/locations?cache=true`).then((res) => {
            setAreas(res.data);
        });
    };

    useEffect(() => {
        if (user) {
            getItems(user?.hotel_id);
            getAssignables();
            getAreas();
            getDepartments(user?.hotel_id)
        }
    }, [user]);

    useEffect(() => {
        const orgId = getValues('organization');
        getDepartments(orgId);
        getAreas();
        getItems(orgId);
        setValue('dept', '');
    }, [org]);

    const onCreateWizard = async (data: any) => {
        setIsLoading(true);
        const requestBody = {
            hotel_id: data.organization,
            trig: 'aodm',
            desk_id: data.dept,
            room_no: data.area != '' ? data.area + ' ' + data.location : data.location,
            comments: data.comments,
            items: [
                {
                    id: data.item,
                    qty: 1,
                    comments: null,
                },
            ],
            file: Object.keys(data.attachment).length !== 0 ? {} : null,
            priority: data.priority || defaultValues.priority,
            extra: data.extra,
            gc: data.guestComplaint,
            assign_to: data.assign || defaultValues.assign,
        };
        axios
            .post(`requestAdmin`, requestBody)
            .then((res) => {
                if (Object.keys(data.attachment).length !== 0) {
                    const formData = new FormData();
                    formData.append('file', data.attachment);
                    axios
                        .post(`upload/attach/audit/${res.data.audit_id}`, formData)
                        .then((r) => {
                            setIsLoading(false);
                            reset({
                                ...defaultValues,
                                organization: user?.hotel.id, // retain the organization value
                            });
                        })
                        .catch((err) => {
                            setIsLoading(false);
                            reset({
                                ...defaultValues,
                                organization: user?.hotel.id, // retain the organization value
                            });
                        });
                } else {
                    setIsLoading(false);
                    reset({
                        ...defaultValues,
                        organization: user?.hotel.id, // retain the organization value
                    });
                }
                showAlert("Snag created successfully","success")
                getdata()
                refreshNotifications();
                onClose()
            })
            .catch((e) => {
                setIsLoading(false);
                reset({
                    ...defaultValues,
                    organization: user?.hotel.id, // retain the organization value
                });
                let errorMessage = "An error occurred. Please try again.";
                if (e.response && e.response.data && e.response.data.messages) {
                    errorMessage = e.response.data.messages.join(', ');
                }
                showAlert(errorMessage, "error");
            });
    };

    // Changing the item department on item change
    const item = useWatch({
        control,
        name: 'item',
    });
    useEffect(() => {
        const itemId = getValues('item');
        const item = items?.find((item: any) => item.id == itemId);
        setValue('dept', item?.dept?.name);
    }, [item]);

    const selectedItem = watch('item');
    useEffect(() => {
        // Handle setting the department when a task is selected
        if (selectedItem) {
            const task = items.find((item: any) => item.id === selectedItem);
            if (task && task.dept) {
                if (!manualDept) {
                    // Only set the department from task if no manual override
                    setValue('dept', task.dept.id);
                    setOriginalDept(task.dept.id);
                    setDepartmentAssignmentText(
                        `${task.name} is auto-assigned to ${task.dept.name} department.`
                    );
                }
            }
        }
    }, [selectedItem, items, setValue, manualDept]);

    const handleResetDepartment = useCallback(() => {
        if (originalDept) {
            setValue('dept', originalDept);
        } else {
            console.error('No original department set');
        }
    }, [originalDept, setValue]);

    useEffect(() => {
        const orgId = getValues('organization');
        getDepartments(orgId);
        setValue('dept', '');
    }, [org, setValue]);

    useEffect(() => {
        if (user && user.hotel) {
            setValue('organization', user.hotel.id, { shouldDirty: true });
        }
    }, [user, setValue]);

    const handlePriorityChange = (event: React.MouseEvent<HTMLElement>, newPriority: string) => {
        setValue('priority', newPriority); // make sure to correctly set the value in react-hook-form
    };
    const handleClearFile = () => {
        setValue('attachment', {}); // Clear the file from the form state
    };

    const selectedOrganization = watch('organization');
    useEffect(() => {
        if (selectedOrganization) {
            axios
                .get(`hotel/${selectedOrganization}/locations?cache=true`)
                .then((res) => {
                    setAreas(res.data); // Update the areas state with the fetched data
                })
                .catch((error) => {
                    console.error('Error fetching areas:', error);
                    setAreas([]); // Ensure areas is empty if fetch fails or no areas are returned
                });
        } else {
            setAreas([]); // Clear areas if no organization is selected
        }
    }, [selectedOrganization]);

    const formatFileSize = (size: number) => {
        if (size < 1024) return `${size} bytes`;
        const kb = size / 1024;
        if (kb < 1024) return `${kb.toFixed(2)} KB`;
        return `${(kb / 1024).toFixed(2)} MB`;
    };

    return (
        // <Box sx={{ maxWidth: "90%", margin: "auto", padding: "20px" }}>
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            PaperProps={{
                style: {
                    borderRadius: 20,
                    overflow: "hidden", // Ensures the rounded corners are visible
                },
            }}
        >
            <Box sx={{ p: 2, width: '100%' }}>
                {' '}
                {/* Reduced padding */}
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Typography
                            variant="h4"
                            sx={{
                                mb: 2,
                                textAlign: "center",
                                fontWeight: "bold",
                                width: "100%",
                            }}
                        >
                            Create New Snag
                        </Typography>
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: "grey[500]",
                            }}
                        >
                            <Icon icon="mdi:close" />
                        </IconButton>

                        <Grid item xs={12} md={6}>
                            {' '}
                            {/* Left side */}
                            <Card style={{ borderRadius: "16px" }}>
                                <CardContent sx={{ p: 2 }}>
                                    {' '}
                                    {/* Reduced padding */}
                                    <Stack spacing={1}>
                                        {' '}
                                        {/* Reduced spacing */}
                                        <RHFTextField name="organization" label="Org *" select fullWidth>
                                            {user?.hotels?.map((org: any) => (
                                                <MenuItem key={org.id} value={org.id}>
                                                    {org.name}
                                                </MenuItem>
                                            ))}
                                        </RHFTextField>

                                        <RHFTextField
                                            name="location"
                                            label="Additional Location Details *"
                                            fullWidth
                                        />

                                        <RHFTextField name="item" label="Task/Snag *" select fullWidth>
                                            {items.map((item: any) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </RHFTextField>
                                        <Controller
                                            name="dept"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                        {field.value && (
                                                            <Box
                                                                sx={{
                                                                    backgroundColor: '#E0E0F8', // Light blue background
                                                                    color: '#333', // Dark text color for better readability
                                                                    padding: '4px 8px',
                                                                    borderRadius: '4px',
                                                                    fontSize: '0.875rem', // Smaller font size
                                                                    fontWeight: 'bold',
                                                                }}
                                                            >
                                                                {`${items.find((item: any) => item.id === selectedItem)?.name
                                                                    } is auto-assigned to `}
                                                                <span style={{ textDecoration: 'underline' }}>
                                                                    {manualDept ||
                                                                        departmentOptions.find((d) => d.id === Number(field.value))
                                                                            ?.name}
                                                                </span>
                                                                {' department.'}
                                                            </Box>
                                                        )}
                                                        <Button
                                                            variant="outlined"
                                                            sx={{ borderColor: '#7575a3', color: '#7575a3' }} // Styling the button to match the box
                                                            onClick={() => setIsDrawerOpen(true)}
                                                        >
                                                            Change Department?
                                                        </Button>
                                                    </Typography>
                                                </>
                                            )}
                                        />
                                        <RHFTextField
                                            name="comments"
                                            label="Comments (Optional)"
                                            multiline
                                            rows={3}
                                            fullWidth
                                        />{' '}
                                        {/* Reduced rows */}
                                        <Controller
                                            name="priority"
                                            control={control}
                                            render={({ field }) => (
                                                <div>
                                                    <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                                                        Priority
                                                    </Typography>
                                                    <ToggleButtonGroup
                                                        value={field.value}
                                                        exclusive
                                                        onChange={handlePriorityChange}
                                                        aria-label="priority"
                                                        fullWidth
                                                        sx={{
                                                            display: 'flex', // Ensures the buttons fill the container
                                                            '& .MuiToggleButtonGroup-grouped': {
                                                                flexGrow: 1, // Each button grows equally
                                                                margin: theme.spacing(0.5),
                                                                border: 0, // Removing the default border style
                                                                '&.Mui-disabled': {
                                                                    border: 0,
                                                                },
                                                                '&:not(:first-of-type)': {
                                                                    borderRadius: theme.shape.borderRadius,
                                                                },
                                                                '&:first-of-type': {
                                                                    borderRadius: theme.shape.borderRadius,
                                                                },
                                                                '&:not(:last-child)': {
                                                                    marginRight: 6,
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <ToggleButton
                                                            value="normal"
                                                            aria-label="normal"
                                                            sx={{
                                                                backgroundColor: 'transparent', // Neutral background
                                                                color: '#333', // Default text color
                                                                border: '2px solid purple', // Purple border
                                                                '&.Mui-selected, &.Mui-selected:hover': {
                                                                    borderColor: 'darkpurple', // Dark purple border on selection
                                                                    color: '#fff',
                                                                    backgroundColor: 'purple', // Purple background on selection
                                                                },
                                                            }}
                                                        >
                                                            Normal
                                                        </ToggleButton>
                                                        <ToggleButton
                                                            value="urgent"
                                                            aria-label="urgent"
                                                            sx={{
                                                                backgroundColor: 'transparent',
                                                                color: '#333',
                                                                border: '2px solid orange', // Orange border
                                                                '&.Mui-selected, &.Mui-selected:hover': {
                                                                    borderColor: 'darkorange',
                                                                    color: '#fff',
                                                                    backgroundColor: 'orange',
                                                                },
                                                            }}
                                                        >
                                                            Urgent
                                                        </ToggleButton>
                                                        <ToggleButton
                                                            value="extra urgent"
                                                            aria-label="extra urgent"
                                                            sx={{
                                                                backgroundColor: 'transparent',
                                                                color: '#333',
                                                                border: '2px solid red', // Red border
                                                                '&.Mui-selected, &.Mui-selected:hover': {
                                                                    borderColor: 'darkred', // Dark red border on selection
                                                                    color: '#fff',
                                                                    backgroundColor: 'red',
                                                                },
                                                            }}
                                                        >
                                                            Extra Urgent
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </div>
                                            )}
                                        />
                                        <RHFToggle
                                            name="guestComplaint"
                                            label="Passenger Complaint?"
                                            sx={{ ml: 0.2, mb: 1, display: 'flex' }}
                                        />
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={5}>
                            {' '}
                            {/* Right side */}
                            <Card>
                                <CardContent sx={{ p: 2 }}>
                                    {' '}
                                    {/* Reduced padding */}
                                    <Stack spacing={1}>
                                        {' '}
                                        {/* Reduced spacing */}
                                        <RHFUploadSingleFile
                                            name="attachment"
                                            accept="image/*"
                                            maxSize={3145728}
                                            onDrop={handleDrop}
                                            onClear={handleClearFile} // Pass the clear function
                                        />

                                        <Typography sx={{ fontSize: '0.875rem' }}>
                                            All file types supported. Max file size: 6 MB
                                        </Typography>
                                        {isDrawerOpen && (
                                            <Box
                                                sx={{
                                                    p: 2,
                                                    bgcolor: '#E0E0F8', // Light blue background
                                                    color: '#333', // Dark text color
                                                    border: '1px solid',
                                                    borderColor: 'divider',
                                                    borderRadius: '4px',
                                                }}
                                            >
                                                <Typography variant="h6" sx={{ mb: 1  }}>
                                                    Change Department

                                                    <IconButton
                                                    aria-label="close"
                                                    onClick={() => setIsDrawerOpen(false)}
                                                    sx={{
                                                        // position: "absolute",
                                                        // right: 1,
                                                        // top: 8,
                                                        // mb: 100,
                                                        marginLeft:22,
                                                        // marginBottom:2,
                                                        color: "grey[500]",
                                                    }}
                                                >
                                                    <Icon icon="mdi:close" />
                                                </IconButton>
                                                </Typography>
                                                
                                                <Stack spacing={2}>
                                                    <RHFTextField name="organization" label="Org" select fullWidth>
                                                        {user?.hotels?.map((org: any) => (
                                                            <MenuItem key={org.id} value={org.id}>
                                                                {org.name}
                                                            </MenuItem>
                                                        ))}
                                                    </RHFTextField>
                                                    <RHFTextField name="dept" label="Department" select fullWidth>
                                                        {departmentOptions.map((department: any) => (
                                                            <MenuItem key={department.id} value={department.id}>
                                                                {department.name}
                                                            </MenuItem>
                                                        ))}
                                                    </RHFTextField>
                                                    <Box sx={{ mt: 2, display: 'flex', width: '100%' }}>
                                                        <Button
                                                            variant="outlined"
                                                            onClick={handleResetDepartment}
                                                            sx={{ flex: 1, mr: 1 }}
                                                        >
                                                            Reset Department
                                                        </Button>
                                                        <Button
                                                            variant="outlined"
                                                            onClick={() => setIsDrawerOpen(false)}
                                                            sx={{ flex: 1 }}
                                                        >
                                                            Save
                                                        </Button>
                                                    </Box>
                                                </Stack>
                                            </Box>
                                        )}
                                    </Stack>
                                </CardContent>
                            </Card>
                            <Box
                                sx={{
                                    display: 'flex', // Use flexbox to center the button
                                    justifyContent: 'center', // Horizontally center the button
                                    alignItems: 'center', // Vertically align items in the center, if full height alignment needed
                                    pt: 4, // Padding top to move button slightly downwards
                                }}
                            >
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    loading={isloading}
                                    startIcon={<Icon icon="mdi:plus" />} // Use Iconify icon for the plus icon
                                    sx={{
                                        mt: 1,
                                        backgroundColor: '#283593', // Adjust this color to match your brand's exact blue
                                        color: 'white',
                                        ':hover': {
                                            backgroundColor: '#1a237e', // Adjust hover color to a suitable shade
                                        },
                                        height: 48,
                                        borderRadius: 1,
                                        fontWeight: 'bold',
                                        fontSize: '0.875rem',
                                        textTransform: 'uppercase',
                                        justifyContent: 'center',
                                        padding: '0 30px',
                                        boxShadow: 'none',
                                    }}
                                >
                                    Create Snag
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </FormProvider>
            </Box>
        </Dialog>
        // </Box>
    );
};

export default CreateSnag;
