import React, { useState } from "react";
import {
  Menu,
  Typography,
  Box,
  Divider,
  Button,
  Card,
  IconButton,
  List, ListItem, ListItemText, Chip
} from "@mui/material";
import { Icon } from "@iconify/react";
import { clearNotification, clearAllNotifications } from "src/utils/api";
import { useNotifications } from "../context/NotificationsContext";
import NotificationHistoryDialog from "./NotificationHistoryDialog";
import { formatDateTime } from "src/utils/helperFunctions";

const NotificationBox = ({
  anchorEl,
  open,
  onClose,
}: any) => {
  const { notifications, refreshNotifications } = useNotifications();
  const [isHistoryDialogOpen, setHistoryDialogOpen] = useState(false);

  const handleClearNotificationClick = async (id: any) => {
    try {
      await clearNotification(id);
      refreshNotifications(); // Refresh notifications list
    } catch (error) {
      console.error("Error clearing notification:", error);
    }
  };

  const handleClearAllClick = async () => {
    try {
      await clearAllNotifications();
      refreshNotifications(); // Refresh notifications list
    } catch (error) {
      console.error("Error clearing all notifications:", error);
    }
  };

  const handleViewNotificationHistoryClick = () => {
    setHistoryDialogOpen(true);
    onClose()
  };

  const handleCloseHistoryDialog = () => {
    setHistoryDialogOpen(false);
  };

  const renderNotification = (notification:any) => {
    const isUnread = notification.is_read === 0; // Check if notification is unread
    const backgroundColor = isUnread ? "#f5f5f5" : "transparent"; // Set background color based on read status
    return (
      <List sx={{ width: '100%', bgcolor: 'background.paper', p:0, mr:1.5 }}>
            <ListItem sx={{ padding: '8px 16px', display:"grid", p:0, m:0, bgcolor: backgroundColor }}>
                        <Typography 
                            sx={{ fontWeight: isUnread ? 'bold' : 'normal', flexGrow: 0, marginRight: 0 }}
                        >
                            {notification.message} 
                            <Chip 
                            label={`ID: ${notification.entity_id}`} 
                            sx={{ fontWeight: 'bold', ml: 0.5, height: '20px', fontSize: '0.75rem', }} 
                        />
                        </Typography>                        
                    <Typography variant="caption" color="text.secondary">
                        {formatDateTime(notification.created_at)}
                    </Typography>
            </ListItem>
        </List>
    );
}

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            minWidth: 300,
            minHeight: 200,
            maxWidth: 400,
            maxHeight: "400px",
            overflowY: "auto",
          },
        }}
      >
        <Card
          sx={{
            m: 1,
            p: 0.4,
            height: 35,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            boxShadow:
              "0px 2px 4px 0px rgba(0,0,0,0.4),0px 7px 13px -3px rgba(0,0,0,0.3), 0px -3px 0px 0px rgba(0,0,0,0.2) inset",
          }}
        >
          <Typography sx={{ ml: 1 }} variant="h6">
            NOTIFICATIONS
          </Typography>
          <Button
            size="small"
            onClick={handleClearAllClick}
            sx={{ ml: 2, mb: 1, mt: 0.6, color: "red" }}
            variant="text"
            color="error"
          >
            <Icon
              icon="icon-park:done-all"
              style={{ height: 20, width: 20, padding: 0 }}
            />
          </Button>
        </Card>
        <Box sx={{ p: 1 }}>
          {notifications?.length === 0 ? (
            <Typography sx={{ p: 2, textAlign: "center" }}>
              No notifications
            </Typography>
          ) : (
            notifications?.map((notification: any, index: number) => {
              // Add a check to ensure notification and message exist
              if (!notification || !notification.message) {
                return null; // Skip this item
              }
              return (
                <React.Fragment key={notification.id}>
                  <Box
                    sx={{
                      p: 1.3,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom:
                        index < notifications?.length - 1 ? "1px solid" : "none",
                      borderColor: "divider",
                      position: "relative",
                      "&:hover .remove-icon": {
                        display: "block",
                      },
                    }}
                  >
                    {renderNotification(notification)}
                    <IconButton
                      onClick={() =>
                        handleClearNotificationClick(notification.id)
                      }
                      size="small"
                      color="error"
                      className="remove-icon"
                      sx={{
                        display: "none",
                        position: "absolute",
                        right: 0,
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <Icon
                        icon="circum:read"
                        style={{ height: 25, width: 25, paddingLeft: 1 }}
                      />
                    </IconButton>
                  </Box>
                  {index < notifications?.length - 1 && <Divider />}
                </React.Fragment>
              );
            })
          )}
          <Button
            sx={{ mt: 2, width: "100%" }}
            variant="outlined"
            color="primary"
            onClick={handleViewNotificationHistoryClick} // Open history dialog
          >
            View Notification History
          </Button>
        </Box>
      </Menu>
      <NotificationHistoryDialog open={isHistoryDialogOpen} onClose={handleCloseHistoryDialog} />
    </>
  );
};

export default NotificationBox;
