// import { useState } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import { Divider, ListItemButton, ListItemIcon, Chip } from "@mui/material";
import Iconify from "./Iconify";
import hyd_logo from "../assets/hyd_logo_bird.png";
import vestaPNG from "../assets/vesta.png";
import { deleteStorage } from "src/utils/storage";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import useAlerts from "src/components/Alerts";
import { useAodmAdminCheck } from "src/utils/helperFunctions";

const drawerWidth = 184;
const closedDrawerWidth = 70;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  // width: `calc(${theme.spacing(7)} + 1px)`,
  // [theme.breakpoints.up("sm")]: {
  //   width: `calc(${theme.spacing(8)} + 1px)`,
  // },
  width: closedDrawerWidth,
  [theme.breakpoints.up("sm")]: {
    width: closedDrawerWidth,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Navbar() {
  const { isAdmin } = useAodmAdminCheck();
  const { showAlert } = useAlerts();
  const theme = useTheme();
  // const [open, setOpen] = useState(false);
  // const [permanentOpen, setPermanentOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path: any) => {
    return location.pathname === path;
  };

  const handleMouseEnter = (item: string) => setHoveredItem(item);
  const handleMouseLeave = () => setHoveredItem(null);

  const handleSnagsClick = () => {
    navigate("/snags");
  };

  const handleObservationClick = () => {
    navigate("/observations");
  };

  const handleInformationClick = () => {
    navigate("/information");
  };

  const handleDashboardClick = () => {
    navigate("/dashboard");
  };

  const handleReportsClick = () => {
    navigate("/reports");
  };

  const handleConfiguration=()=>{
    if(isAdmin) navigate('/configuration')
    else showAlert("Only AODM ADMINS can access this", "warning");
  }

  const renderLabel = (label: string) =>
    hoveredItem === label && (
      <Chip
        label={label}
        sx={{
          maxWidth: 500,
          position: "absolute",
          left: closedDrawerWidth + 5,
          bgcolor: "rgba(235, 235, 235, 0.8)",
          borderRadius: 3,
          color: "black",
        }}
      />
    );
  /* FOR Dynamic Navbvar (open aand close on hover) */
  /* const handleMouseEnter = () => {
    setOpen(true);
  };
  const handleMouseLeave = () => {
    if (!permanentOpen) {
      setOpen(false);
    }
  };
  const handlePermanentOpen = () => {
    setPermanentOpen(!permanentOpen);
  };
  */

  const handleLogout = () => {
    deleteStorage("user");
    navigate("/");
  };

  const LogoBox = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        maxWidth: '50px',
        alignItems: 'center',
      }}
    >
      <img src={hyd_logo} alt="Logo" style={{ width: '100%' }} />
      <img src={vestaPNG} alt="Logo" style={{ width: '100%', marginTop: '1px' }} />
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        // open={open}
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
      >
        <div style={{ position: "fixed" }}>
          <LogoBox/>
          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "10px",
              height: "550px",
            }}
          >
            {/* Dashboard */}
          <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? "initial" : "center"
                  px: 2.5,
                  // ...(isActive("/observations") &&
                  //   open && {
                  //     bgcolor: theme.palette.action.active
                  //     background: `linear-gradient(90deg, ${theme.palette.obs.main}, ${theme.palette.obs.light})`,
                  //   }),
                }}
                onMouseEnter={() => handleMouseEnter("Dashboard")}
                onMouseLeave={handleMouseLeave}
                onClick={handleDashboardClick}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    // mr: open ? 1.5 : "auto",
                    mr: "1.5",
                    justifyContent: "center",
                    ...(isActive("/dashboard") && {
                      boxShadow: `0 0 0 7px ${theme.palette.snags.main}`,
                      bgcolor: theme.palette.snags.main,
                      color: "#ffffff",
                    }),
                  }}
                >
                  <Iconify icon={"line-md:list"} width={25} height={25} />
                </ListItemIcon>
                {renderLabel("Dashboard")}
                {/* <ListItemText primary="Dashboard"sx={{ opacity: open ? 1 : 0 }} /> */}
              </ListItemButton>
            </ListItem>
            {/* Observations */}
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  // ...(isActive("/observations") &&
                  //   open && {
                  //     // bgcolor: theme.palette.action.active,
                  //     background: `linear-gradient(90deg, ${theme.palette.obs.main}, ${theme.palette.obs.light})`,
                  //   }),
                }}
                onMouseEnter={() => handleMouseEnter("Observations")}
                onMouseLeave={handleMouseLeave}
                onClick={handleObservationClick}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    // mr: open ? 1.5 : "auto",
                    mr: "1.5",
                    justifyContent: "center",
                    ...(isActive("/observations") && {
                      boxShadow: `0 0 0 7px ${theme.palette.obs.main}`,
                      bgcolor: theme.palette.obs.main,
                      color: "#ffffff",
                    }),
                  }}
                >
                  <Iconify icon={"line-md:search"} width={25} height={25} />
                </ListItemIcon>
                {renderLabel("Observations")}
                {/* <ListItemText primary="Observations"sx={{ opacity: open ? 1 : 0 }} /> */}
              </ListItemButton>
            </ListItem>
            {/* Snags */}
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  // ...(isActive("/snags") &&
                  //   open && {
                  //     background: `linear-gradient(90deg, ${theme.palette.snags.light}, ${theme.palette.snags.main})`,
                  //     color: "#ffffff",
                  //   }),
                }}
                onMouseEnter={() => handleMouseEnter("Snags")}
                onMouseLeave={handleMouseLeave}
                onClick={handleSnagsClick}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    // mr: open ? 1.5 : "auto",
                    mr: "auto",
                    justifyContent: "center",
                    // ...(isActive("/snags") &&
                    //   open && {
                    //     color: "white",
                    //   }),
                    ...(isActive("/snags") && {
                      boxShadow: `0 0 0 7px ${theme.palette.snags.main}`,
                      bgcolor: theme.palette.snags.main,
                      color: "#ffffff",
                    }),
                  }}
                >
                  <Iconify icon={"ri:tools-fill"} width={25} height={25} />
                </ListItemIcon>
                {renderLabel("Snags")}
                {/* <ListItemText primary="Snags" sx={{ opacity: open ? 1 : 0 }} /> */}
              </ListItemButton>
            </ListItem>
            {/* Information */}
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  // ...(isActive("/information") &&
                  //   open && {
                  //     background: `linear-gradient(90deg, ${theme.palette.information.light}, ${theme.palette.information.main})`,
                  //     // color:'#ffffff'
                  //   }),
                }}
                onMouseEnter={() => handleMouseEnter("Information")}
                onMouseLeave={handleMouseLeave}
                onClick={handleInformationClick}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    // mr: open ? 1.5 : "auto",
                    mr: "auto",
                    p: 0,
                    justifyContent: "center",
                    ...(isActive("/information") && {
                      boxShadow: `0 0 0 7px ${theme.palette.information.main}`,
                      bgcolor: theme.palette.information.main,
                      color: "#ffffff",
                    }),
                  }}
                >
                  <Iconify icon={"line-md:alert-circle"} width={25} height={25} />
                </ListItemIcon>
                {renderLabel("Information")}
                {/* <ListItemText primary="Information" sx={{ opacity: open ? 1 : 0 }} /> */}
              </ListItemButton>
            </ListItem>
            {/* Reports - Coming Soon */}
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  // ...(isActive("/reports") &&
                  //   open && {
                  //     background: `linear-gradient(90deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
                  //     color: "#ffffff",
                  //   }),
                }}
                onMouseEnter={() => handleMouseEnter("Reports")}
                onMouseLeave={handleMouseLeave}
                onClick={handleReportsClick}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    // mr: open ? 1.5 : "auto",
                    mr: "auto",
                    justifyContent: "center",
                    ...(isActive("/reports") && {
                      boxShadow: `0 0 0 7px ${theme.palette.primary.main}`,
                      bgcolor: theme.palette.primary.main,
                      color: "#ffffff",
                    }),
                  }}
                >
                  <Iconify
                    icon={"line-md:document-report"}
                    width={25}
                    height={25}
                    // color={theme.palette.action.disabled}
                  />
                </ListItemIcon>
                {renderLabel("Reports")}
                {/* <ListItemText primary="Reports" sx={{ opacity: open ? 1 : 0 }}
                /> */}
              </ListItemButton>
            </ListItem>
            {/* Configure */}
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  // justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  // ...(isActive("/configure") &&
                  //   open && {
                  //     background: `linear-gradient(90deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
                  //     color: "#ffffff",
                  //   }),
                }}
                onMouseEnter={() => handleMouseEnter("Configuration")}
                onMouseLeave={handleMouseLeave}
                onClick={handleConfiguration}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    // mr: open ? 1.5 : "auto",
                    mr: "auto",
                    justifyContent: "center",
                    ...(isActive("/configuration") && {
                      boxShadow: `0 0 0 7px ${theme.palette.obs.main}`,
                      bgcolor: theme.palette.obs.main,
                      color: "#ffffff",
                    }),
                  }}
                >
                  <Iconify icon={"line-md:cog"} width={25} height={25} />
                </ListItemIcon>
                {renderLabel("Configuration")}
                {/* <ListItemText primary="Configure" sx={{ opacity: open ? 1 : 0 }} /> */}
              </ListItemButton>
            </ListItem>
          </List>
          <Box
            sx={{ alignSelf: "flex-end", marginLeft: 1.8, position: "fixed" }}
          >
            <IconButton onClick={handleLogout}>
              <Iconify icon={"tabler:logout"} width={25} height={25} />
            </IconButton>
          </Box>
        </div>
      </Drawer>
    </Box>
  );
}
