import { Icon } from "@iconify/react";
import {
  Chip,
  Box,
  Card,
  CardContent,
  IconButton,
  InputBase,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getHistoryLogs, updateHistoryLogs } from "../../utils/api";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import {formatDateTime} from "src/utils/helperFunctions";
import useAlerts from "src/components/Alerts";

const InformationHistoryLog = (props: any) => {
  const { id } = props;
  const entityType = "information"; // Adjusted entity type
  const [logData, setLogData] = useState<any[]>([]);
  const [comment, setComment] = useState("");
  const [images, setImages] = useState<File[]>([]);
  const { showAlert } = useAlerts();

  useEffect(() => {
    fetchHistoryLogs();
  }, [id, entityType]);

  const fetchHistoryLogs = async () => {
    try {
      const response = await getHistoryLogs({
        entity_id: id,
        entity_type: entityType,
      });
      setLogData(response.logs);
    } catch (error) {
      console.error("Error fetching history logs:", error);
    }
  };

  const formatTitle = (log: any) => {
    const { user_name, comment, images_url } = log;
    if (comment === "New information") {
      return `${user_name} created a new information`;
    } else {
      const numImages = images_url ? images_url.length : 0;
      if (comment && numImages === 0) {
        return `${user_name} added a new comment`;
      } else if (comment && numImages > 0) {
        return `${user_name} added a new comment and ${numImages} ${
          numImages === 1 ? "image" : "images"
        }`;
      } else if (!comment && numImages > 0) {
        return `${user_name} added ${numImages} ${
          numImages === 1 ? "image" : "images"
        }`;
      } else {
        return "";
      }
    }
  };

  const renderContent = (log: any) => {
    const { comment, created_at, images_url } = log;
    const title = formatTitle(log);

    let renderedComment = null;
    if (comment && comment !== "New information") {
      renderedComment = (
        <Typography variant="body1" color="black" sx={{ mb: 1 }}>
          {comment}
        </Typography>
      );
    }

    let renderedImages = null;
    if (images_url && images_url.length > 0) {
      renderedImages = (
        <div>
          {images_url.map((imageUrl: any, index: any) => (
            <a
              key={index}
              href={imageUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "inline-block", marginRight: "10px" }}
            >
              <img
                src={imageUrl}
                alt={`Image ${index + 1}`}
                style={{
                  maxWidth: "100px",
                  maxHeight: "100px",
                  verticalAlign: "middle",
                }}
              />
            </a>
          ))}
        </div>
      );
    }

    return (
      <CardContent sx={{ position: "relative", top: 0 }}>
        <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
          {title}
        </Typography>
        {(renderedComment != null || renderedImages != null) && (
          <Card variant="outlined" sx={{ p: 2 }}>
            {renderedComment}
            {renderedImages}
          </Card>
        )}
        <Typography variant="caption" color="textSecondary">
          {formatDateTime(created_at)}
        </Typography>
      </CardContent>
    );
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setImages([...images, ...Array.from(event.target.files)]);
    }
  };

  const handleImageRemove = (index: number) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const handleChangeComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const SelectedImagesBox = () => {
    return (
      <Card
        sx={{
          mt: 2,
          bgcolor: "#f0f0f0",
          borderRadius: 2,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          mb: 2,
        }}
      >
        {images.map((file: File, index: number) => (
          <Chip
            key={index}
            label={file.name}
            onDelete={() => handleImageRemove(index)}
            sx={{
              bgcolor: "#ffffff",
              borderRadius: 16,
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
              m: 0.5,
            }}
            deleteIcon={
              <IconButton
                sx={{
                  bgcolor: "rgba(255, 255, 255, 0.8)",
                  "&:hover": {
                    bgcolor: "rgba(255, 255, 255, 1)",
                  },
                }}
                size="small"
                onClick={() => handleImageRemove(index)}
              >
                <Icon icon="eva:close-circle-fill" />
              </IconButton>
            }
          />
        ))}
      </Card>
    );
  };

  const handleSubmit = async () => {
    if (comment === "" && images.length === 0) {
      showAlert(
        "At least one of comment or images must be provided",
        "warning"
      );
      return;
    } else {
      try {
        const formData = new FormData();
        formData.append("entity_id", id);
        formData.append("entity_type", entityType);
        formData.append("comment", comment);
        // Append each file to FormData
        images.forEach((file, index) => {
          formData.append(`images[${index}]`, file);
        });
        const response = await updateHistoryLogs(formData);
        if (response.log) {
          // Reset formValues after successful submission
          setComment("");
          setImages([]);
          // Fetch updated history logs
          fetchHistoryLogs();
          showAlert("Information updated successfully", "success");
        } else {
          showAlert(
            "Error updating information. Please try again",
            "error"
          );
        }
      } catch (error) {
        console.error("Error updating history logs:", error);
        showAlert(
          "Error updating information. Please try again",
          "error"
        );
      }
    }
  };

  return (
    <div>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {logData.map((log, index) => (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineDot />
              {index < logData.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>{renderContent(log)}</TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 2,
          border: "1px solid #5A5A5A",
          borderRadius: "12px",
          p: 1,
        }}
      >
        <label htmlFor="file-upload">
          <IconButton component="span">
            <Icon icon="material-symbols:attach-file" />
          </IconButton>
        </label>
        <input
          id="file-upload"
          type="file"
          style={{ display: "none" }}
          onChange={handleImageUpload}
          accept="image/*"
          multiple
        />
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Add comment here"
          onChange={handleChangeComment}
          value={comment}
        />
        <IconButton
          sx={{ bgcolor: "#5E87AB", color: "#fff", borderRadius: "50%", p: 1 }}
          onClick={handleSubmit}
        >
          <Icon icon="material-symbols:send" />
        </IconButton>
      </Box>
      {images && <SelectedImagesBox />}
    </div>
  );
};

export default InformationHistoryLog;

