// src/context/NotificationsContext.js
import React, { createContext, useState, useContext, useCallback } from 'react';
import { fetchUnreadNotificationsCount, fetchNotifications } from 'src/utils/api';

const NotificationsContext = createContext();

export const useNotifications = () => useContext(NotificationsContext);

export const NotificationsProvider = ({ children }) => {
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);

  // Single function to refresh notifications and update count
  const refreshNotifications = useCallback(async () => {
    try {
      // Fetch notifications
      const notificationsData = await fetchNotifications();
      setNotifications(notificationsData);

      // Fetch unread notifications count
      const countData = await fetchUnreadNotificationsCount();
      setNotificationCount(countData);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  }, []);

  return (
    <NotificationsContext.Provider value={{ notificationCount, refreshNotifications, notifications }}>
      {children}
    </NotificationsContext.Provider>
  );
};
