import React, { useEffect, useState } from 'react'
import {
  Button,
  Typography,
  Stack,
  Card,
  CardContent,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Tabs,
  Tab,
  TextField,
  MenuItem,
  Grid,
} from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Icon } from '@iconify/react';
import { axiosInstance as axios } from "../../utils/axios";
import { useForm, Controller } from 'react-hook-form';
import useAlerts from "../../components/Alerts";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'src/components/FormProvider';
import RHFTextField from 'src/components/RHFTextField';
import { formatDateTime } from "src/utils/helperFunctions";
import { styled } from "@mui/system";
import { useAuth } from 'src/context/authContext';


interface Department {
  id: number;
  name: string;
  value: string;
}

const ScrollableDialogContent = styled(DialogContent)({
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#555",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#f1f1f1",
    borderRadius: "10px",
  },
});


const capitalizeAndSuffix = (str: any) => {
  if (!str) {
    return '';
  }
  let str1 = str[0].toUpperCase() + str.slice(1);
  let len = str1.length;
  if (str1[len - 1] == 'd' && str1[len - 2] == 'e') {
    return str1;
  } else if (str[len - 1] == 'e') {
    return str1 + 'd';
  } else {
    return str1 + 'ed';
  }
};

const SnagHistoryLog = (props: any) => {

  const { onClose, open, requestData, reAssignOpt,isreassignOpen, handleReassignClose, getTabledata, setimage } = props;
  // console.log("request Data", requestData);
  const handleClose = () => {
    onClose();
  };

  const [requestDetails, setRequestDetails] = useState<any>([]);
  const getRequestDetails = async () => {
    setRequestDetails(null)
    let cacheBuster = new Date().getTime();
    axios.get(`audit-trail/requests/${requestData?.id}?time=`+cacheBuster).then((res) => {
      setRequestDetails(res.data);
      setimage(res.data)
      getTabledata()

    });
  };

  useEffect(() => {
    getRequestDetails();
  }, [requestData]);

  // Request History Component (start)
  const RequestHistory = () => {
    // const formatTitle = (log: any) => {
    //   const user_name = log?.user?.fname || 'Escalated';
    //   const comment = log?.meta?.comment;
    //   const images_url = log?.meta?.attachments;

    //   if (!comment) {
    //     if (!images_url || images_url.length === 0) {
    //       return `${user_name} created a new Snag`;
    //     }
    //     return `${user_name} added ${images_url.length} ${images_url.length === 1 ? 'image' : 'images'}`;
    //   } else if (comment && (!images_url || images_url.length === 0)) {
    //     return `${user_name} added a new comment`;
    //   } else {
    //     return `${user_name} added a new comment and ${images_url.length} ${images_url.length === 1 ? 'image' : 'images'}`;
    //   }
    // };    
    const formatTitle = (log: any) => {
      const user_name = log?.user?.fname;
      const comment = log?.meta?.comment;
      const images_url = log?.meta?.attachments;
      const status = capitalizeAndSuffix(log?.meta?.status ?? log.action);
    
      switch (status) {
        case 'Closed':
          return `${user_name} has closed the Request`;
        case 'Attached':
          return `${user_name} added a new Attachment`;
        case 'Parked':
          return `${user_name} has Parked the Request`;
        case 'Rejected':
          return `${user_name} has Rejected the Request`;
        case 'Reopened':
          return `${user_name} has Reopened the Request`;
        case 'Dept-reassigned':
          return `Dept-reassigned`;
        case 'Assigned':
          return `Assigned`;
        case 'Escalated':
          return 'Escalated';
        case 'Uploading-failed':
          return `Uploading-failed`;
        default:
          if (!comment) {
            if (!images_url || images_url.length === 0) {
              return `${user_name} created a new Snag`;
            }
            return `${user_name} added ${images_url.length} ${images_url.length === 1 ? 'image' : 'images'}`;
          } else if (comment && (!images_url || images_url.length === 0)) {
            return `${user_name} added a new comment`;
          } else {
            return `${user_name} added a new comment and ${images_url.length} ${images_url.length === 1 ? 'image' : 'images'}`;
          }
      }
    };
    return (
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {requestDetails?.slice().reverse().map((item: any, index: any) => {
          return (
            <TimelineItem key={index}>

              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>

                <CardContent sx={{ position: "relative", top: 0 }}>
                  <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                    {formatTitle(item)} 
                  </Typography>
                  {item?.meta?.comment ? (
                    <Card variant="outlined" sx={{ p: 2 }}>
                      {item?.meta?.comment}
                      
                      {item?.meta?.attachments && (
                    <a
                      href={item?.meta?.attachments[0]?.url}
                      target="_blank"
                      style={{ textDecoration: 'none' }}
                    >
                      {' '}
                      <Typography sx={{ fontSize: '11px' }}>
                        <Icon
                          icon="mingcute:attachment-2-line"
                          style={{ verticalAlign: 'middle' }}
                        />
                        {item?.meta?.attachments[0]?.filename}
                      </Typography>{' '}
                    </a>
                  )}
                    </Card>
                  ) : (
                    <></>
                  )}
                  <Typography variant="caption" color="textSecondary">
                    {formatDateTime(item?.created_at)}
                  </Typography>
                </CardContent>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    );
  };
  // Request Historry Component (end)

  const UpdateRequest = () => {
    const [images, setImages] = useState<File[]>([]);
    const { showAlert } = useAlerts();

    const [tabIndex, setTabIndex] = useState(0);
    const [comments, setComments] = useState('');
    const [selectedOrganization, setSelectedOrganization] = useState<string>('');
    const [selectedDepartment, setSelectedDepartment] = useState<string>('0');
    const [departmentOptions, setDepartmentOptions] = useState<Department[]>([]);
    const [reassignOpens, setReassignOpens] = useState(false);

    const handleReassignOpen = () => {
      isreassignOpen();
    };

    const handleReassignCloses = () => {
      handleReassignClose();
    };
    // const handleReassignOpen = () => setReassignOpens(reassignOpen);
    // const handleReassignClose = () => setReassignOpens(false);
    const schema = Yup.object().shape({
      picture: Yup.mixed(),
      comment: Yup.string().required('Comment Required'),
    });
    // second schema for close/park request
    const schema2 = Yup.object().shape({
      picture: Yup.mixed(),
      comment: Yup.string(),
    });
    const { user } = useAuth();
    type FormValues = {
      picture: object;
      comment: string;
      organization: string;
    };
    const defaultValues = {
      picture: {},
      comment: '',
      organization: ''
    };
    const methods = useForm<FormValues>({
      resolver: yupResolver(schema),
      defaultValues,
    });

    const { register, handleSubmit, getValues, setError, setValue, control } = methods;

    const [btnType, setBtnType] = useState('');
    // type: comment/close/park/reject

    // const updateRequest = async (type: any, data: any, image: any) => {
    //   axios.post(`hotel/request/${type}`, data).then((r) => {
    //     handleClose();
    //     if (Object.keys(image).length !== 0) {
    //       const formData = new FormData();
    //       formData.append('file', image[0]);
    //       axios.post(`upload/attach/audit/${r.data.audit_id}`, formData);
    //     }
    //     getRequestDetails();
    //   });
    // };

    const updateRequest = async (type: string, data: any, image: any) => {
      try {
        const response = await axios.post(`hotel/request/${type}`, data);
        handleClose();
        if (image.length > 0) {
          const formData = new FormData();
          formData.append('file', image[0]);
          await axios.post(`upload/attach/audit/${response.data.audit_id}`, formData);
        }
        getRequestDetails();
      } catch (error) {
        console.error('Error updating request:', error);
      }
    };

    const [reAssignOpt, setReAssignOpt] = useState<any>([]);
    const getReAssignOptions = async () => {
      let r = await axios.get(`hotel/${user?.hotel_id}/assignables/list?hotel_id=${user?.hotel_id}`);
      // console.log('ReAssign Options checking now--->>>>', r.data);
      setReAssignOpt(r.data);
    };
  
    useEffect(() => {
      // pass page number as prop
      getReAssignOptions();
    }, []);
    const onSubmit = (data: any) => {
      if (!data.comment.trim()) {
        showAlert("At least one of comment or images must be provided",
          "warning");
        return;
      }
      // console.log('Form data', data);
      let postData = {
        id: requestData?.id,
        comment: data.comment,
      };
      updateRequest(btnType, postData, data.picture);
      // getTabledata()
    };

    //  for close/park request
    const submit2 = async (btn: any) => {
      const data = getValues();
      if (!data.comment.trim() && btn === 'comment') {
        showAlert("At least one of comment or images must be provided",
          "warning");
        return;
      }
      let postData = {
        id: requestData?.id,
        comment: data.comment,
      };
      try {
        // Conditional validation based on the action
        if (['close'].includes(btn)) {
          await schema2.validate(data, { abortEarly: false });
          if (btn === 'close'){
            showAlert("Request has been closed successfully", "success");
            getRequestDetails();
          } else if (btn === 'park'){
            showAlert("Request has been park successfully", "success");
            getRequestDetails();
          }
        } else if (btn === 'reopen') {
          // Potentially a different schema or no validation if not needed
          await schema.validate(data, { abortEarly: false });
          if (btn === 'reopen'){
            showAlert("Request has been reopen successfully", "success");
            getRequestDetails();
          }
        } else if (btn === 'park') {
          // Potentially a different schema or no validation if not needed
          await schema.validate(data, { abortEarly: false });
          if (btn === 'park'){
            showAlert("Request has been park successfully", "success");
            getRequestDetails();
          }
        }
        updateRequest(btn, postData, data.picture);
        if (btnType === 'reject'){
          showAlert("Request has been reject successfully", "success");
          getRequestDetails();
        } else if (btnType === 'comment'){
          showAlert("comment has been added successfully", "success");
          getRequestDetails();
        }
        // getTabledata()
      } catch (err) {
        console.log(err);
      }
    };
    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        setImages([...images, ...Array.from(event.target.files)]);
      }
    };

    // const getDepartments = async (organization: any) => {
    //   axios
    //     .get(`/hotel/${organization}/desks`)

    //     .then((response) => {
    //       const departmentData = response.data.map((dept: any) => ({
    //         id: dept.id,
    //         name: dept.name,
    //       }));
    //       setDepartmentOptions(departmentData);
    //     })
    //     .catch((error) => {
    //       console.error('Error fetching department values:', error);
    //     });
    // };

    const getDepartments = async (organization: string) => {
      try {
        const response = await axios.get(`/hotel/${organization}/desks`);
        const departmentData = response.data.map((dept: any) => ({
          id: dept.id,
          name: dept.name,
        }));
        setDepartmentOptions(departmentData);
      } catch (error) {
        console.error('Error fetching department values:', error);
      }
    };
    const [selectedReAssign, setSelectedReAssign] = useState<any>('');
    const handleReAssign = (e: any) => {
      console.log('Re-Assign user', e.target.value);
      setSelectedReAssign(e.target.value);
    };

    const handleTabChange = (event: any, newValue: any) => {
      setTabIndex(newValue);
    };
      const reAssignOptions = reAssignOpt?.map((item: any, idx: any) => {
        return (
          <MenuItem value={item} key={idx}>
            {' '}
            {item.display}{' '}
          </MenuItem>
        );
      });
      const [reAssignComment, setReAssignComment] = useState('');
      const onReAssignComment = (e: any) => {
        setReAssignComment(e.target.value);
      };
  
      useEffect(() => {
        if (user && user.hotels) {
          if (user.hotels.length === 1) {
            const singleHotelId = user.hotels[0].id;
            setSelectedOrganization(singleHotelId);
            setValue('organization', singleHotelId);
            getDepartments(singleHotelId);
          } else if (user.hotels.length > 1 && selectedOrganization === '') {
            // Optionally, initialize to the first by default or leave it unset
            setSelectedOrganization(user.hotels[0].id);
          }
        }
      }, [user, setValue]);
  
      
      
      const [saveDisabled, setSaveDisabled] = useState(false);

       // Reassign Dept and Org 
    const reassignDepartment = async () => {
      if (selectedOrganization && selectedDepartment) {
        const postData = {
          hotel_id: selectedOrganization,
          desk_id: selectedDepartment,
          request_id: requestData?.id,
          comment: comments
        };

        try {
          const response = await axios
            .post('task/assign-dept', postData)
          // console.log('Reassign Department Response:', response.data);
          showAlert("Department reassigned successfully", "success");
          getRequestDetails();
          getTabledata()
          handleReassignCloses()
        }
        catch (error) {
          console.error('Error reassigning department:', error);
          showAlert("Error reassigning department:", "warning");

        }
      }
      else {
        console.error('Please select both organization and department before reassigning.');
        showAlert('Please select both organization and department before reassigning.')
      }
    };
    // const onReAssignSave = async () => {
    //   setSaveDisabled(true);
    //   let postData = {
    //     user_id: selectedReAssign?.id,
    //     request_id: requestData?.id,
    //     comment: reAssignComment,
    //   };
    //   axios
    //     .post('task/assign', postData)
    //     .then((res) => {
    //       showAlert("user reassigned successfully", "success");
    //       handleReassignCloses()
    //       setSaveDisabled(false);
    //       setSelectedReAssign('');
    //       getRequestDetails();
    //     })
    //     .catch((err) => {
    //       setSaveDisabled(false);
    //       getRequestDetails();
    //       setSelectedReAssign('');
    //     });
    // };
    const onReAssignSave = async () => {
      setSaveDisabled(true);
      const postData = {
        user_id: selectedReAssign?.id,
        request_id: requestData?.id,
        comment: reAssignComment,
      };
      try {
        await axios.post('task/assign', postData);
        showAlert("User reassigned successfully", "success");
        handleReassignCloses();
        setSaveDisabled(false);
        setSelectedReAssign('');
        getRequestDetails();
      } catch (error) {
        setSaveDisabled(false);
        console.error('Error reassigning user:', error);
        showAlert('Error reassigning user', 'warning');
        setSelectedReAssign('');
      }
    };
    return (
      <FormProvider onSubmit={handleSubmit(onSubmit)} methods={methods}>
        <Stack spacing={3}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', mb: 3 }}>
              <Box sx={{ width: '100%', mt: 2 }}>
                <Typography sx={{ mb: 1, fontWeight: 'bold' }}>Attachment</Typography>
                <Typography sx={{ fontSize: '13px', mb: 2 }}>
                  [Optional] All file types supported. Max file size: 6 MB
                </Typography>
                <input
                  type="file"
                  {...register('picture')}
                  style={{
                    padding: 10,
                    border: '1px solid #D3D3D3',
                    borderRadius: 8,
                    height: 45,
                    width: '100%',
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box>
            <Typography sx={{ mb: 1, mt: -2, fontWeight: 'bold' }}>Enter Comments </Typography>
            <Stack spacing={2} direction="row">
              <RHFTextField
                name="comment"
                sx={{ width: '100%' }}
              >

              </RHFTextField>
              <Button
                variant="contained"
                color="primary"
                size="small"
                type="submit"
                value="comment"
                onClick={(e) => setBtnType(e.currentTarget.value)}
              >
                ADD COMMENT
              </Button>

            </Stack>

            {/* <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', mt: 2, border: '1px solid #5A5A5A', borderRadius: '12px', p: 1 }}>
        <IconButton component="label">
          <Icon icon="material-symbols:attach-file" />
          <input
            id="file-upload"
            type="file"
            {...register('picture')}
            style={{ display: 'none' }}
            accept="image/*"
            multiple
            onChange={handleImageChange}
          />
        </IconButton>

        <Controller
          name="comment"
          control={control}
          // rules={{ required: btnType === 'reject' || btnType === 'reopen' }}
          render={({ field }) => (
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Add comment here"
              {...field}
            />
          )}
        />

        <IconButton
          sx={{
            bgcolor: '#5E87AB',
            color: '#fff',
            borderRadius: '50%',
            p: 1,
          }}
          type="submit"
        >
          <Icon icon="material-symbols:send" />
        </IconButton>
      </Box> */}

          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="success"
                size="small"
                type="button"
                value="close"
                onClick={(e) => {
                  setBtnType(e.currentTarget.value);
                  submit2(e.currentTarget.value);
                }}
              >
                CLOSE REQUEST
              </Button>
              <Button
                variant="contained"
                color="warning"
                size="small"
                type="submit"
                value="park"
                onClick={(e) => {
                  setBtnType(e.currentTarget.value);
                  // submit2(e.currentTarget.value);
                }}
              >
                PARK REQUEST
              </Button>

              <Button
                variant="contained"
                color="error"
                size="small"
                type="submit"
                value="reject"
                onClick={(e) => setBtnType(e.currentTarget.value)}
              >
                REJECT REQUEST
              </Button>
              {[-1, 1, 3].includes(requestData?.status) && (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#3f5161', // Custom grayish-blue color
                    color: 'white',
                  }}
                  size="small"
                  // type="button"
                  type="submit"
                  value="reopen"
                  onClick={(e) => {
                    setBtnType(e.currentTarget.value);
                    // submit2(e.currentTarget.value);
                  }}
                >
                  REOPEN REQUEST
                </Button>
              )}
            </Stack>

          </Box>
        </Stack>

        <Dialog
        open={isreassignOpen}
        // onClose={onCloses}
        onClose={handleReassignCloses}
        aria-labelledby="reassign-dialog-title"
        fullWidth
        //   maxWidth={"sm"}
        //   PaperProps={{
        //     style: {
        //       borderRadius: 20,
        //       padding: "20px",
        //       boxSizing: "border-box",
        //     },
        //   }}
        PaperProps={{
          style: {
            borderRadius: 20,
            padding: "20px",
            boxSizing: "border-box",
            height: "auto", // Make height auto to adjust with content
            width: "auto", // Adjust the width as needed
            maxWidth: "100vh",
            maxHeight: "70vh", // Max height to ensure it doesn't overflow the viewport
            overflow: "hidden", // Ensure no overflow for the whole dialog
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "#231F20",
              fontWeight: "bold",
              textAlign: "center",
              width: "100%",
            }}
          >
            Reassign Task
          </Typography>
          <IconButton onClick={handleReassignCloses}>
            <Icon icon="material-symbols:close" />
          </IconButton>
        </DialogTitle>
        {/* <DialogContent> */}
        <ScrollableDialogContent>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Reassign Department" />
            <Tab label="Reassign to User Directly" />
          </Tabs>
          <Box sx={{ mt: 2 }}>
            {tabIndex === 0 && (
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4}>
                        <Typography>Currently Assigned to</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Org"
                          value={user?.hotels[0]?.name}
                          InputProps={{
                            readOnly: true,
                          }}
                          // fullWidth
                          margin="dense"
                          sx={{ width: '150px', }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Department"
                          value={requestData?.desk?.name}
                          InputProps={{
                            readOnly: true,
                          }}
                          // fullWidth
                          margin="dense"
                          // sx={{ width: '100px' }}
                          sx={{ width: '200', minWidth: 200, flex: 1 }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4}>
                        <Typography>New Assignment</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        {user?.hotels && user.hotels.length > 1 ? (
                          <TextField
                            label="Select Organization"
                            select
                            value={selectedOrganization}
                            onChange={(e) => {
                              const newOrgId = e.target.value;
                              setSelectedOrganization(newOrgId);
                              // setSelectedDepartment('0'); // Reset department to 'All' when changing organization
                              setSelectedDepartment(''); // Reset department to '' when changing organization
                              getDepartments(newOrgId); // Fetch departments when the organization changes
                            }}
                            // fullWidth
                            // sx={{ width: '100%' }}
                            sx={{ width: '150px' }}
                          >
                            {user.hotels.map((hotel: any) => (
                              <MenuItem key={hotel.id} value={hotel.id}>
                                {hotel.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        ) : (
                          <TextField
                            label="Organization"
                            value={user?.hotels.length === 1 ? user.hotels[0].name : "No available organizations"}
                            InputProps={{
                              readOnly: true,
                            }}
                            sx={{ width: '150px' }}

                          // sx={{ width: '100%', marginBottom: 2, minWidth: 200, flex: 1, marginRight: 2 }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        {/* Department Dropdown */}
                        <TextField
                          label="Select Department"
                          select
                          value={selectedDepartment}
                          onChange={(e) => setSelectedDepartment(e.target.value)}
                          sx={{ width: '200px', minWidth: 200, flex: 1 }}
                        >
                          {departmentOptions.map((dept: Department) => (
                            <MenuItem key={dept.id} value={dept.id}>
                              {dept.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4}>
                        <Typography>Comments (optional)</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          label="Comments (optional)"
                          value={comments}
                          onChange={(e) => setComments(e.target.value)}
                          fullWidth
                          multiline
                          rows={4}
                          margin="dense"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      onClick={reassignDepartment}
                      color="primary"
                      variant="contained"
                      fullWidth
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
            {tabIndex === 1 && (
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4}>
                        <Typography>Currently Assigned to</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        {/* <TextField
                          label="Org"
                          value={selectedOrganization}
                          select
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          margin="dense"
                        >
                          {user?.hotels.map((hotel: any) => (
                            <MenuItem key={hotel.id} value={hotel.id}>
                              {hotel.name}
                            </MenuItem>
                          ))}
                        </TextField> */}
                        <TextField
                          label="Org"
                          value={user?.hotels[0]?.name}
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        {/* <TextField
                          label="User"
                          value={selectedDepartment}
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth
                          margin="dense"
                        /> */}
                        <TextField
                          // label="User"
                          // value={requestData?.user?.name}
                          label={requestData?.assigned_to_user?.name ? "User" : "Department"}
                          value={requestData?.assigned_to_user?.name ? requestData.assigned_to_user.name : requestData?.desk?.name}
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth
                          margin="dense"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4}>
                        <Typography>New Assignment</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        {/* <Autocomplete
                          options={orgOptions}
                          getOptionLabel={(option: any) => option.label}
                          onChange={(e, value) => setNewOrg(value?.value || "")}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Organization"
                              margin="dense"
                              fullWidth
                            />
                          )}
                        /> */}
                         {user?.hotels && user.hotels.length > 1 ? (
                          <TextField
                            label="Select Organization"
                            select
                            value={selectedOrganization}
                            onChange={(e) => {
                              const newOrgId = e.target.value;
                              setSelectedOrganization(newOrgId);
                              // setSelectedDepartment('0'); // Reset department to 'All' when changing organization
                              setSelectedDepartment(''); // Reset department to '' when changing organization
                              getDepartments(newOrgId); // Fetch departments when the organization changes
                            }}
                            fullWidth
                            // sx={{ width: '100%' }}
                          >
                            {user.hotels.map((hotel: any) => (
                              <MenuItem key={hotel.id} value={hotel.id}>
                                {hotel.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        ) : (
                          <TextField
                            label="Organization"
                            value={user?.hotels.length === 1 ? user.hotels[0].name : "No available organizations"}
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                          // sx={{ width: '100%', marginBottom: 2, minWidth: 200, flex: 1, marginRight: 2 }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        {/* <Autocomplete
                          options={departmentOptions}
                          getOptionLabel={(option: any) => option.label}
                          onChange={(e, value) => setNewDepartment(value?.value || "")}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Department"
                              margin="dense"
                              fullWidth
                            />
                          )}
                        /> */}
                         <TextField
        label="Assign To"
        value={selectedReAssign}
        onChange={handleReAssign}
        select
        sx={{ width: '100%' }}
      >
        {reAssignOptions}
      </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4}>
                        <Typography>Comments (optional)</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          label="Comments (optional)"
                          value={reAssignComment}
                          onChange={onReAssignComment}
                          fullWidth
                          multiline
                          rows={4}
                          margin="dense"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      onClick={onReAssignSave}
                      color="primary"
                      variant="contained"
                      fullWidth
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </ScrollableDialogContent>
        {/* </DialogContent> */}

      </Dialog>
      </FormProvider>
    )

  }

  return (
    <>
      <RequestHistory />
      <UpdateRequest  />

    </>
  )
}

export default SnagHistoryLog
