import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Login } from "./pages/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import ApiLoading from "./utils/axios";
import "./App.css";
import CustomTheme from "./utils/themes/CustomTheme";
import Layout from "./components/Layout";
import Information from "./pages/informationPage/Information";
import Configuration from "./pages/configurationPage/Configuration";
import Users from "./pages/configurationPage/User";
import Departments from "./pages/configurationPage/Departments";
import Locations from "./pages/configurationPage/Locations";
import Category from "./pages/configurationPage/Category";
import Observations from "./pages/observationPage/Observations";
import { closeSnackbar, SnackbarProvider } from 'notistack';
import { AuthProvider } from "./context/authContext";
import RenderSnags from "./pages/snagPage/Snags";
import Iconify from "./components/Iconify";
import AuthGuard from "./guards/AuthGuard";
import AodmAdminGuard from "./guards/AodmAdminGuard";
import { NotificationsProvider } from "./context/NotificationsContext";
import DownloadReports from "./pages/reportsPage/DownloadReports";

function App() {
  return (
    <div className="App">
      <CustomTheme>
        <SnackbarProvider maxSnack={3} autoHideDuration={3500}
          action={(snackbarId) => (
            <Iconify icon={"ion:close-circle"} width={25} height={25} onClick={() => closeSnackbar(snackbarId)} />
          )}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <Router>
            <AuthProvider>
              <AuthGuard>
                <NotificationsProvider>
                  <AppContent />
                </NotificationsProvider>
              </AuthGuard>
              {/* <ApiLoading /> */}
            </AuthProvider>
          </Router>
        </SnackbarProvider>
      </CustomTheme>
    </div>
  );
}

const AppContent = () => {
  const location = useLocation();
  const hideNavbar = location.pathname === "/";

  return (
    <>
      {!hideNavbar && <Layout />} {/* Conditionally render Navbar, Header */}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/observations" element={<Observations />} />
        <Route path="/snags" element={<RenderSnags />} />
        <Route path="/information" element={<Information />} />
        <Route path="/reports" element={<DownloadReports />} />
        <Route path="/configuration" element={<AodmAdminGuard><Configuration /></AodmAdminGuard>} />
        <Route path="/configuration/users" element={<AodmAdminGuard><Users /></AodmAdminGuard>} />
        <Route path="/configuration/departments" element={<AodmAdminGuard><Departments /></AodmAdminGuard>} />
        <Route path="/configuration/locations" element={<AodmAdminGuard><Locations /></AodmAdminGuard>} />
        <Route path="/configuration/category" element={<AodmAdminGuard><Category /></AodmAdminGuard>} />
        <Route path="/dashboard" element={<Dashboard />} />  
      </Routes>
    </>
  );
};

export default App;
