import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Grid,
  Card,
  IconButton,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { format, formatDistanceToNow } from "date-fns";
import {formatDateTime} from "src/utils/helperFunctions";
import InformationHistoryLog from "./InformationHistoryLog";

interface InformationDetailsProps {
  open: boolean;
  onClose: () => void;
  data: any;
}

const InformationDetails: React.FC<InformationDetailsProps> = ({
  open,
  onClose,
  data,
}) => {
  const theme = useTheme();
  // const imageUrl = '/static/images/avatar/1.jpg'; // Example image URL
  if (!data) {
    return null;
  }
  const imageUrls = data?.attachments ?? "";
  const createdAt = new Date(data.created_at);
  const formattedCreatedAt = format(createdAt, "MMMM d, hh:mm a");
  const relativeTime = formatDistanceToNow(createdAt, { addSuffix: true });
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 20,
        },
      }}
    >
      <DialogTitle>
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", textAlign: "center" }}
        >
          Information Details
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8, color: "grey[500]" }}
        >
          <Icon icon="mdi:close" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ padding: 2 }}>
          <Card
            sx={{
              p: 2,
              mt: 2,
              mb: 2,
              color: "#5A5A5A",
              border: "1.4px dashed #7A96AF",
              borderRadius: "12px",
            }}
          >
            <Grid container spacing={2} gap={2}>
              <Grid item xs>
                <Box
                  sx={{
                    bgcolor: "#7A96AF",
                    borderRadius: 2,
                    p: 0.7,
                    display: "inline-block",
                    color: "#231F20",
                  }}
                >
                  <Typography variant="body2">
                    <Icon
                      icon="line-md:text-box"
                      width={15}
                      height={15}
                      style={{ position: "relative", top: 3 }}
                    />{" "}
                    ID: {data?.id}
                  </Typography>
                </Box>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#231F20",
                    mb: 2,
                    mt: 2,
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  {" "}
                  <Icon
                    icon="line-md:map-marker-alt-filled"
                    width={20}
                    height={20}
                    style={{ position: "relative", top: 3 }}
                  />{" "}
                  {data?.loc_details}
                </Typography>

                <Box
                  sx={{
                    p: 1,
                    display: "inline-flex",
                    alignItems: "center",
                    backgroundColor: theme.palette.information.light,
                    borderRadius: "8px",
                  }}
                >
                  <Typography variant="body1" sx={{ color: "black" }}>
                    {data?.info}
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#2D2D2D",
                    mb: 2,
                    mt: 2,
                    width: "100%",
                  }}
                >
                  {data?.observation}
                </Typography>
                <Box sx={{ mt: 3 }}>
                  <Grid container spacing={-12}>
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                          color: theme.palette.information.main,
                        }}
                      >
                        <Icon icon="material-symbols:person" />
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          AODM:
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                          color: theme.palette.information.main,
                        }}
                      >
                        <Icon icon="material-symbols:calendar-today" />
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          Created at:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body2"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                          color: "#2D2D2D",
                        }}
                      >
                        {data?.aodm}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                          color: "#2D2D2D",
                        }}
                      >
                        {" "}
                        {formatDateTime(data?.created_at)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item sx={{ maxHeight: "500px", overflowY: "auto" }}>
                {imageUrls &&
                  imageUrls.map((url: any, index: any) => (
                    <Grid item xs={2} md={2} key={index} ml={2}>
                      <a href={url} target="_blank" rel="noopener noreferrer">
                        <Box
                          component="img"
                          sx={{
                            width: 167.3,
                            height: 167.3,
                            objectFit: "cover",
                          }}
                          src={url}
                          alt={`Image ${index + 1}`}
                        />
                      </a>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Card>
          <Box
            sx={{ borderTop: "1px solid #ccc", paddingTop: 2, marginTop: 2 }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#231F20",
                mb: 2,
                fontWeight: "bold",

                width: "100%",
              }}
            >
              Information History / Audit Log
            </Typography>
            <InformationHistoryLog id={data?.id} />
          </Box>
        </Box>
        {/* <DialogActions sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button
            onClick={onClose}
            sx={{ bgcolor: '#57AB35', color: '#fff', '&:hover': { backgroundColor: theme.palette.information.light } }}
          >
            CLOSE INFORMATION
          </Button>
        </DialogActions> */}
      </DialogContent>
    </Dialog>
  );
};

export default InformationDetails;
