import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { FormControlLabel, Switch, Typography } from '@mui/material';
import { TextField, TextFieldProps, InputAdornment, IconButton, MenuItem } from '@mui/material';
import { Icon } from '@iconify/react';

type IProps = {
  name: string;
  select?: boolean;
  onClear?: () => void;
  icon?: string; // Optional icon for dropdown
  clearIcon?: string; // Optional icon for the clear button
};

type Props = IProps & TextFieldProps;

const RHFTextField: React.FC<Props> = ({
  name,
  select,
  onClear,
  clearIcon = 'mdi:close', // default clear icon
  ...other
}: Props) => {
  const { control, setValue } = useFormContext();

  // Function to handle clearing the input
  const handleClear = () => {
    setValue(name, '');
    if (onClear) onClear();
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          error={!!error}
          helperText={error?.message}
          select={select}
          {...other}
          InputProps={{
            ...other.InputProps,
            endAdornment: (
              <>
                {name === 'area' && field.value && (
                  <InputAdornment position="end" style={{ paddingRight: '10px' }}>
                    <IconButton onClick={handleClear} edge="end">
                      <Icon icon={clearIcon} /> {/* Using Iconify for clear icon */}
                    </IconButton>
                  </InputAdornment>
                )}
              </>
            ),
          }}
        >
          {select && other.children}
        </TextField>
      )}
    />
  );
};

export function RHFToggle({ name, label, ...props }: any) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Switch
              {...field}
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
              {...props}
              sx={{
                '& .MuiSwitch-switchBase': {
                  color: '#fff', // Keep the knob white when off
                },
                '& .MuiSwitch-switchBase.Mui-checked': {
                  color: '#fff', // Keep the knob white when on
                  '& + .MuiSwitch-track': {
                    backgroundColor: '#0057ff', // Your brand's blue color
                  },
                },
                '& .MuiSwitch-track': {
                  backgroundColor: '#333', // Dark grey for the track when off
                },
              }}
            />
          }
          label={
            <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
              {label}
            </Typography>
          }
          sx={{ margin: 0, justifyContent: 'space-between', ...props.sx }}
          labelPlacement="start"
        />
      )}
    />
  );
}

export default RHFTextField;
