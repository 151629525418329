import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { Icon } from "@iconify/react";

const DeleteDialog = (props:any) => {
  const { open, onClose, onConfirm, title = "Confirm Deletion", description = "Are you sure you want to delete this item?" } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle><Icon icon="line-md:alert-twotone" style={{ fontSize: 27, color:'#d78733',  position:'relative', top:6}} /> {title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{color:"black"}}>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="info">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="error" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
