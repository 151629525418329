import React from "react";
import ApexCharts from "react-apexcharts";
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Box,
} from "@mui/material";
import EmptyContent from "src/components/EmptyContent";

// StatusBreakdown Component
const StatusBreakdown = ({ open, closed }: any) => {
  const validOpen = typeof open === "number" ? open : 0;
  const validClosed = typeof closed === "number" ? closed : 0;

  const chartOptions: any = {
    chart: {
      type: "pie",
      height: 440,
    },
    labels: ["Open", "Closed"],
    colors: ["#FF9800", "#4CAF50"],
    legend: {
      position: "right",
      horizontalAlign: "left",
      verticalAlign: "middle",
      floating: true,
    },
    tooltip: {
      y: {
        formatter: (val: any) => `${val.toFixed(2)}%`,
      },
    },
  };

  const chartData: any = [validOpen, validClosed];

  return (
    <Card sx={{ width: "100%", maxHeight: 493 }}>
      <CardHeader title="Status Breakdown" sx={{ ml: 4 }} />
      <CardContent>
        {validOpen > 0 || validClosed > 0 ? (
          <ApexCharts
            options={chartOptions}
            series={chartData}
            type="pie"
            height={410}
          />
        ) : (
          <EmptyContent />
        )}
      </CardContent>
    </Card>
  );
};

// Component to render pie charts for department/category totals
const AnalysisCharts = ({ data }: any) => {
  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: "pie" as const,
      height: 300,
    },
    labels: data.map((item: any) => item.name),
    legend: {
      show: false,
    },
  };

  const chartData = data.map((item: any) => item.totalObservations);

  return (
    <Box sx={{ mt: 5 }}>
      <ApexCharts
        options={chartOptions}
        series={chartData}
        type="pie"
        height={360}
      />
    </Box>
  );
};

// Component to render department and category wise analysis tables and charts
const AnalysisTablesandCharts = ({ data }: any) => {
  const isDataEmptyForChart = (data: any[]) => {
    return data.every(
      (row) =>
        row.totalObservations === 0 &&
        row.closedObservations === 0 &&
        row.openObservations === 0
    );
  };

  const renderTableWithChart = (data: any[], title: string) => {
    const isEmpty = isDataEmptyForChart(data); // Check if the data is empty for chart once

    return (
      <Card sx={{ width: "100%", mb: 2 }}>
        <CardHeader title={title} />
        <CardContent>
          {isEmpty ? (
            <EmptyContent /> // Show EmptyContent if the data is empty
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <TableContainer
                  component={Paper}
                  sx={{
                    maxHeight: 400,
                    overflowY: "auto",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  <Table>
                    <TableHead sx={{ backgroundColor: "#e0e0e0" }}>
                      <TableRow>
                        <TableCell sx={{ textAlign: "center" }}>
                          <strong>Name</strong>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <strong>Total Observations</strong>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <strong>Closed Observations</strong>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <strong>Open Observations</strong>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <strong>Closure Rate</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((row: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell sx={{ textAlign: "center" }}>
                            {row.name}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {row.totalObservations}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {row.closedObservations}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {row.openObservations}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              color:
                                row.closureRate > 70
                                  ? "green"
                                  : row.closureRate < 50
                                  ? "red"
                                  : "inherit",
                              fontWeight:
                                row.closureRate > 70 || row.closureRate < 50
                                  ? "bold"
                                  : "inherit",
                            }}
                          >
                            {row.closureRate}%
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} md={4}>
                <AnalysisCharts data={data} />
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    );
  };

  const { departmentData = [], categoryData = [] } = data || {};

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {renderTableWithChart(departmentData, "Department-Wise Analysis")}
      </Grid>
      <Grid item xs={12}>
        {renderTableWithChart(categoryData, "Category-Wise Analysis")}
      </Grid>
    </Grid>
  );
};

export { StatusBreakdown, AnalysisTablesandCharts, AnalysisCharts };
