import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputBase,
  IconButton,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  TablePagination,
  Switch,
  Grid,
  Card,
  CardContent,
  MenuItem,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { getInformation, getLocations } from "src/utils/api";
import { axiosInstance as axios } from "src/utils/axios";
import {formatDateTime} from "src/utils/helperFunctions";
import InformationDetails from "./InformationDetails";
import useAlerts from "src/components/Alerts";
import DeleteDialog from "src/components/DeleteDialog";
import { useAodmAdminCheck } from 'src/utils/helperFunctions';
import useFilters from "src/hook/useFilter";
import Filter from "src/components/Filters";
import { useNotifications } from 'src/context/NotificationsContext';
import { LoadingButton } from '@mui/lab';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import CancelIcon from '@mui/icons-material/Cancel';

const columns = [
  { field: "aodm", headerName: "AODM", width: '13%' },
  { field: "location", headerName: "LOCATION", width: '27%' },
  { field: "information", headerName: "INFORMATION", width: '60%' },
];


interface MyDateRangePickerProps {
  onDateRangeChange: (startDate: string, endDate: string) => void;
  dateRange: { startDate: string; endDate: string } | null; 
}

function MyDateRangePicker({ onDateRangeChange, dateRange }: MyDateRangePickerProps) {
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);

   // Reset date pickers when the parent clears the date range
   useEffect(() => {
    if (!dateRange) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [dateRange]);

  const handleStartDateChange = (date: dayjs.Dayjs | null) => {
    setStartDate(date);
    if (date && endDate) {
      onDateRangeChange(date.toISOString(), endDate.toISOString());
    }
  };

  const handleEndDateChange = (date: dayjs.Dayjs | null) => {
    setEndDate(date);
    if (startDate && date) {
      onDateRangeChange(startDate.toISOString(), date.toISOString());
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ p: 2, borderRadius: 6, maxWidth: '500px', margin: '0 auto' }}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            <DateTimePicker
              label="Start Date"
              value={startDate}
              onChange={handleStartDateChange}
              format="MM/DD/YYYY hh:mm a" // Control the display format
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: 'outlined',
                  size: 'small', // Smaller input size
                  InputLabelProps: { shrink: true }, // Always show label on top
                  sx: {
                    borderRadius: '8px', // Rounded corners
                    backgroundColor: 'white',
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateTimePicker
              label="End Date"
              value={endDate}
              onChange={handleEndDateChange}
              format="MM/DD/YYYY hh:mm a" // Control the display format
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: 'outlined',
                  size: 'small', // Smaller input size
                  InputLabelProps: { shrink: true }, // Always show label on top
                  sx: {
                    borderRadius: '8px', // Rounded corners
                    backgroundColor: 'white',
                  }
                }
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
}

const Information = () => {
  const { filterOptions, selectedColumnData, setSelectedColumnData, formatFilters } = useFilters();
  const { refreshNotifications } = useNotifications();
  const theme = useTheme();
  const { showAlert } = useAlerts();
  const [openDialog, setOpenDialog] = useState(false);
  const initialFormValues = {
    location: "",
    information: "",
    locationdetails:"",
  };
  const [formValues, setFormValues] = useState(initialFormValues);
  const [informationData, setInformationData] = useState<any>([]);
  const [locations, setLocations] = useState<any>([]);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [detailOpen, setDetailOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  // const [selectedColumnData, setSelectedColumnData] = useState<any>({});
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [showTodayInformation, setShowTodayInformations] = useState(false);
  const [todayInformationCount, setTodayInformationCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState<{ startDate: string; endDate: string } | null>(null);

  const [infoToDel, setInfoToDelete] = useState(null);
  let [aodmName, setAodmName] = useState("");
  let { isAdmin } = useAodmAdminCheck(aodmName);

  const columnToFilterKeyMap: { [key: string]: string } = {
    aodm: 'user',
    location: 'locations',
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchLocations = async () => {
    try {
      const locations = await getLocations();
      setLocations(locations);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleDetailOpen = (row: any) => {
    setSelectedRowData(row);
    setDetailOpen(true);
  };

  const handleDetailClose = () => {
    setDetailOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };
  const [images, setImages] = useState<File[]>([]);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setImages([...images, ...Array.from(event.target.files)]);
    }
  };

  const handleImageRemove = (index: number) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const createInfo = async () => {
    const formData = new FormData();
    formData.append("location_id", formValues.location);
    formData.append("info", formValues.information);
    formData.append("loc_details", formValues.locationdetails);

    // Append each file to FormData
    images.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    if (!formValues.locationdetails || !formValues.information) {
      showAlert("Please fill out all required fields", "warning");
      return;
    }

    try {
      setLoading(true);
      await axios.post("/aodm/createInformation", formData);
      showAlert("Information created successfully", "success");;
      setFormValues(initialFormValues) // Clear the form
      fetchInformation(); // Refresh Info list
      refreshNotifications();
      handleClose(); // Close dialog
      setLoading(false);
    } catch (error) {
      showAlert("Failed to create information. Please try again!", "error");
    }
  };

  const fetchInformation = async (filters: { [key: string]: string } = {}) => {
    try {
      const response = await getInformation({
        ...filters,
        searchWord: searchQuery,
        page: page +1 ,
        rowsPerPage: rowsPerPage,
      });
  
      const { total, data } = response;
      // const informations = await getInformation(filters);
      setInformationData(data);
      setTotalCount(total);
      if (!filters.today) {
        const today = new Date();
        const count = data.filter((information: any) =>
          isToday(information, today)
        ).length;
        setTodayInformationCount(count);
      } else {
        setTodayInformationCount(data.length);
      }
    } catch (error) {
      console.error("Error fetching information:", error.message);
    }
  };

  // useEffect(() => {
  //   fetchInformation(formatFilters(selectedColumnData));
  // }, [page, rowsPerPage, selectedColumnData]);
  // const handleDateRangeChange = (startDate: string, endDate: string, filters: { [key: string]: string } = {}) => {
  //   const newFilters = {
  //     ...filters,
  //     start_date: startDate,
  //     end_date: endDate,
  //   };
  //   // setFilters(newFilters);
  //   fetchInformation(newFilters);
  // };

  const handleDateRangeChange = (startDate: string, endDate: string) => {
    setDateRange({ startDate, endDate });
    fetchInformation({ start_date: startDate, end_date: endDate });
  };

  const clearDateRange = () => {
    setDateRange(null);
    fetchInformation(); // Fetch without date filters
  };

  const fetchInformations = (filters: { [key: string]: string }) => {
    fetchInformation(filters);
  };

  const stopDetailPropagation = (event:any) => {
    // Prevent the event from propagating to the TableRow and opening detail dialog
    event.stopPropagation();
  };
  
  const handleDeleteClick = (infoId: any, aodm: any) => {
    setAodmName(aodm)
    setInfoToDelete(infoId);
    setDeleteDialogOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setInfoToDelete(null);
  };

  const handleDeleteInformation = async () => {
    if(!isAdmin) {
      showAlert("Only AODM Admins can delete information created by other users", "warning");
      setDeleteDialogOpen(false);
      setInfoToDelete(null);
      return}
    try {
      await axios.post('/aodm/info/deleteInfo', { info_id: infoToDel });
      showAlert("Information deleted successfully", "success");
      setDeleteDialogOpen(false);
      setInfoToDelete(null);
      fetchInformation();
    } catch (error) {
      console.error('Failed to delete information', error);
    }
  };

  useEffect(() => {
    fetchInformation();
    fetchLocations();
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

// Debounce the API call inside useEffect
useEffect(() => {
  const delayDebounceFn = setTimeout(() => {
    // Fetch table data after a delay (e.g., 500ms)
    fetchInformation(formatFilters(selectedColumnData));
  }, 500); // Delay of 500ms

  return () => clearTimeout(delayDebounceFn); // Cleanup the timeout if searchQuery changes before delay is over
}, [searchQuery, page, rowsPerPage, selectedColumnData]);   

  const handleTodayObservations = () => {
    fetchInformation({ today: 'true' });
    setShowTodayInformations(true); // Show close icon
  };

  const handleToggleChange = () => {
    if (showTodayInformation) {
      fetchInformation();
    } else {
      handleTodayObservations();
    }
    setShowTodayInformations(!showTodayInformation);
  };

  // const handleTodayObservations = () => {
  //   const today = new Date();
  //   const filteredData = informationData.filter((information: any) =>
  //     isToday(information, today)
  //   );
  //   setInformationData(filteredData);
  //   // setTableData(filteredData);
  //   setShowTodayInformations(true); // Show close icon
  // };

  const isToday = (observation: any, today: Date) => {
    const observationDate = new Date(observation.created_at);
    return (
      observationDate.getDate() === today.getDate() &&
      observationDate.getMonth() === today.getMonth() &&
      observationDate.getFullYear() === today.getFullYear()
    );
  };

  // const handleResetInformation = () => {
  //   fetchInformation();
  //   setInformationData(informationData);
  //   setShowTodayInformations(false); // Hide close icon
  // };
  const filteredData = informationData
    // .filter((row: any) => {
    //   return Object.keys(selectedColumnData).every((field: string) => {
    //     const filterValues = selectedColumnData[field];
    //     if (!filterValues || filterValues.length === 0) return true;
    //     return filterValues.includes(row[field].toString());
    //   });
    // })
    // .filter((row: any) => {
    //   return Object.values(row).some(
    //     (value: any) =>
    //       (value !== null && value !== undefined && value.toString().toLowerCase().includes(searchQuery.toLowerCase())) ||
    //       row.id.toString().includes(searchQuery) // Include the id field in the search
    //   );
    // });

  return (
    <Box sx={{ maxWidth: "90%", margin: "auto", padding: "20px" }}>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          backgroundColor: theme.palette.information.main,
          color: "#fff",
          borderRadius: "8px",
          textTransform: "none",
          padding: "10px 20px",
          marginLeft: "48px",
          display: "flex",
          alignItems: "center",
          gap: "8px",
          marginBottom:'15px',
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "24px",
            height: "24px",
            border: "2px solid #fff",
            borderRadius: "4px",
            "&:hover": {
              backgroundColor: theme.palette.information.main,
              boxShadow: "none",
            },
          }}
        >
          <Icon icon="mdi:plus" color="#fff" />
        </Box>
        CREATE INFORMATION
      </Button>
      <br />
      <TableContainer
        component={Paper}
        style={{
          maxWidth: "95%",
          margin: "auto",
          padding: "0 20px",
          marginLeft: "50px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
            padding: "10px 0",
          }}
        >
          <Box
            sx={{
              backgroundColor: "transparent",
              color: theme.palette.information.main,
              borderRadius: "8px",
              textTransform: "none",
              border: "1px solid #6c8ebf",
              padding: "8px 16px",
              fontSize: "16px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Today’s Informations (
              {todayInformationCount}

            )
          </Box>
          <Switch
        checked={showTodayInformation}
        onChange={handleToggleChange}
        color="primary"
      />
      {/* <MyDateRangePicker onDateRangeChange={handleDateRangeChange}/> */}
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
    <MyDateRangePicker
      onDateRangeChange={handleDateRangeChange}
      dateRange={dateRange} // Pass current date range
    />
    {dateRange && (
      <button
        onClick={clearDateRange}
        style={{
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          padding: 0, // Remove extra padding
          margin: 0, // Remove extra margin
          display: 'flex', // Align the icon properly
          alignItems: 'center',
        }}
      >
        <CancelIcon style={{ fontSize: '24px', color: '#f00' }} />
      </button>
    )}
  </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "2px 10px",
              marginLeft: "auto",
              width: "300px",
            }}
          >
            <Icon icon="mdi:search" />
            <InputBase
              placeholder="Search informations"
              inputProps={{ "aria-label": "search" }}
              sx={{ ml: 1, width: "100%" }}
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Box>
        </Box>
        <Divider />
        <Table>
          <TableHead>
            {/* <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.field}
                  sx={{
                    fontWeight: "bold",
                    color: theme.palette.information.main,
                    width: column.width,
                  }}
                >
                  {column.headerName}
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow> */}
             <TableRow sx={{ borderBottom: `5px solid #F6F5F5` }}>
            {columns.map((column) => {
              const optionsForField = filterOptions[columnToFilterKeyMap[column.field]] || [];
              const isInformationColumn = column.field === 'information';
              const isLocationColumn = column.field === "location";

              return (
                <TableCell
                  key={column.field}
                  sx={{
                    fontSize: '16px',
                    paddingTop: isInformationColumn || isLocationColumn ? '32px' : '',
                    color: theme.palette.obs.main,
                  }}
                >
                  {isInformationColumn || isLocationColumn ? (
                    column.headerName
                  ) : (
                    <Filter
                      options={optionsForField}
                      field={column.field}
                      headerName={column.headerName}
                      selectedColumnData={selectedColumnData}
                      setSelectedColumnData={setSelectedColumnData}
                      fetchObservations={fetchInformations}
                      formatFilters={formatFilters}
                    />
                  )}
                </TableCell>
              );
            })}
          </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row: any, rowIndex: any) => (
              <React.Fragment key={rowIndex}>
                <TableRow
                  onClick={() => handleDetailOpen(row)}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box>{row.aodm}</Box>
                      <Box
                        sx={{
                          color: theme.palette.information.main,
                          fontSize: "12px",
                        }}
                      >
                        ID: {row.id}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {row.loc_details} 
                    {/* {row.loc_details && row.loc_details !== "" && (
                      <span style={{ display: "block", marginTop: 4, fontSize: "0.875rem", color: "#888" }}>
                        {row.loc_details}
                      </span>
                    )} */}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box>{row.info}</Box>
                      <Box
                        sx={{
                          color: theme.palette.information.main,
                          fontSize: "12px",
                        }}
                      >
                        {formatDateTime(row.created_at)}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <IconButton size="small" onClick={(event) => { stopDetailPropagation(event); handleDeleteClick(row.id, row.aodm); }}>
                      <Icon icon="mdi:trashcan" />
                    </IconButton>
                  </TableCell>
                </TableRow>
                {/* {rowIndex < filteredData.length - 1 && (
                  <TableRow>
                    <TableCell colSpan={4}></TableCell>
                  </TableRow>
                )} */}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        <TablePagination
        rowsPerPageOptions={[25, 50, 75]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </TableContainer>
      <InformationDetails
        open={detailOpen}
        onClose={handleDetailClose}
        data={selectedRowData}
      />
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={handleCancelDelete}
        onConfirm={handleDeleteInformation}
        title="Confirm Deletion"
        description="Are you sure you want to delete this information?"
      />
      <Dialog
        open={openDialog}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{ style: { borderRadius: 20, overflow: "visible" } }}
      >
        <Box sx={{ p: 2, width: "100%", ml: -2 }}>
          <DialogTitle>
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                color: theme.palette.information.main,
              }}
            >
              Create New Information
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "grey[500]",
              }}
            >
              <Icon icon="mdi:close" />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Card style={{ borderRadius: "16px" }}>
                  <CardContent>
                    {/* <TextField
                      select
                      label="Location"
                      name="location"
                      value={formValues.location}
                      onChange={handleChange}
                      fullWidth
                      required
                      margin="dense"
                      InputLabelProps={{ shrink: true }}
                      placeholder="Select location"
                      sx={{ marginBottom: 2 }}
                    >
                      {locations.map((location: any) => (
                        <MenuItem key={location.id} value={location.id}>
                          {location.loc_name}
                        </MenuItem>
                      ))}
                    </TextField> */}

                    <TextField
                    name="locationdetails"
                    label="Location"
                    fullWidth
                    sx={{ marginBottom: "16px" }}
                    onChange={handleChange}
                    value={formValues.locationdetails}
                  />
                    <TextField
                      label="Information"
                      name="information"
                      value={formValues.information}
                      onChange={handleChange}
                      fullWidth
                      required
                      margin="dense"
                      multiline
                      rows={9}
                      InputLabelProps={{ shrink: true }}
                      placeholder="Enter information details here"
                      sx={{ marginBottom: 2 }}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card style={{ borderRadius: "16px" }}>
                  <CardContent>
                    <div
                      style={{
                        border: "1px dashed #ccc",
                        borderRadius: "16px",
                        height: "100px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        textAlign: "center",
                        padding: "20px",
                      }}
                    >
                      <span style={{ marginBottom: "10px" }}>
                        Drag and drop image here or
                      </span>
                      <Button
                        variant="contained"
                        component="label"
                        style={{
                          backgroundColor: theme.palette.information.main,
                          color: "#fff",
                          borderRadius: "8px",
                          textTransform: "none",
                        }}
                      >
                        UPLOAD IMAGE
                        <input
                          type="file"
                          hidden
                          multiple
                          onChange={handleImageUpload}
                        />
                      </Button>
                    </div>
                    <Box mt={2}>
                      {images.map((image, index) => (
                        <Paper
                          key={index}
                          elevation={1}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            p: 1,
                            mb: 1,
                          }}
                        >
                          <Typography>{image.name}</Typography>
                          <IconButton
                            onClick={() => handleImageRemove(index)}
                            size="small"
                          >
                            <Icon icon="mdi:close" color="red" />
                          </IconButton>
                        </Paper>
                      ))}
                    </Box>
                  </CardContent>
                </Card>
                <Box sx={{ mt: 16, ml: 10 }}>
                  <LoadingButton
                    onClick={createInfo}
                    variant="contained"
                    loading={loading} // Disable the button when loading
                    // style={{
                    //   backgroundColor: theme.palette.information.main,
                    //   color: "#fff",
                    //   borderRadius: "8px",
                    //   textTransform: "none",
                    //   padding: "16px 32px",
                    //   fontSize: "18px",
                    // }}
                    sx={{
                      mt: 1,
                      backgroundColor: theme.palette.information.main, // Adjust this color to match your brand's exact blue
                      color: 'white',
                      ':hover': {
                          backgroundColor: '#1a237e', // Adjust hover color to a suitable shade
                      },
                      height: 48,
                      borderRadius: 1,
                      fontWeight: 'bold',
                      fontSize: '0.875rem',
                      textTransform: 'uppercase',
                      justifyContent: 'center',
                      padding: '0 30px',
                      boxShadow: 'none',
                  }}
                  >
                    CREATE INFORMATION
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Box>
      </Dialog>
    </Box>
  );
};

export default Information;
