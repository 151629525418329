import { useAuth } from '../context/authContext';

// Function to format date and time into a human-readable format
const formatDateTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const currentDate = new Date();
  const diff = currentDate - date;
  const seconds = Math.floor(diff / 1000);

  if (seconds < 60) {
    return 'Moments ago';
  } else if (seconds < 3600) {
    const mins = Math.floor(seconds / 60);
    return `${mins} ${mins === 1 ? 'min' : 'mins'} ago, ${formatTime(date)}`;
  } else if (seconds < 43200) {
    const hours = Math.floor(seconds / 3600);
    return `${hours} ${hours === 1 ? 'hr' : 'hrs'} ago, ${formatTime(date)}`;
  } else {
    return `${formatDay(date)}, ${formatYear(date)} - ${formatTime(date)} `;
  }
};

// Function to format day and month (e.g., "1st June")
const formatDay = (date) => {
  const day = date.getDate();
  let suffix = '';
  if (day === 1 || day === 21 || day === 31) {
    suffix = 'st';
  } else if (day === 2 || day === 22) {
    suffix = 'nd';
  } else if (day === 3 || day === 23) {
    suffix = 'rd';
  } else {
    suffix = 'th';
  }
  const month = date.toLocaleString('default', { month: 'long' });
  return `${day}${suffix} ${month}`;
};

// Function to format time in 12-hour format (e.g., "06:32 AM")
const formatTime = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Handle midnight (0) as 12
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

// Function to format year (e.g., "2024")
const formatYear = (date) => {
  return date.getFullYear();
};

// Function to format full date (e.g., "1st June, 06:32 AM 2024")
const formatDate = (date) => {
  const day = formatDay(date);
  const time = formatTime(date);
  const year = formatYear(date);
  return `${day}, ${time} ${year}`;
};

const useAodmAdminCheck = (namePassed) => {
  const { user } = useAuth();

  // Check if namePassed is provided and matches the current user's name
  const matchesCurrentUser = namePassed && namePassed === user.name;

  // Check if the user is a super admin (role 10)
  const isSuperAdmin = user?.role === 10;
  // Check if either namePassed matches user.name or user role is at least 5
  const isAdmin = matchesCurrentUser || (user?.role && user.role >= 5) 
  // Return an object with both admin and super admin status.
  return  { isAdmin, isSuperAdmin };
};


export  {formatDateTime, useAodmAdminCheck};
