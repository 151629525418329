import { useState, useEffect } from 'react';
import { axiosInstance as axios } from '../utils/axios';
import { useAuth } from 'src/context/authContext';

type FilterOptionsType = {
  [key: string]: any[];
};

const useFilters = () => {
  const [filterOptions, setFilterOptions] = useState<FilterOptionsType>({});
  const [selectedColumnData, setSelectedColumnData] = useState<{ [key: string]: string[] }>({});
  const { user } = useAuth();

  
  useEffect(() => {
    fetchFilterOptions();
  }, []);
  
  const useDynamicColumnToFilterKeyMap = () => {
    const path = window.location.pathname;
    if (path === '/observations') {
      return {
        aodm: 'users',
        // location: 'locations',
        department: 'departments',
        category: 'categories',
        status: 'status',
      };
    } else if (path === '/snags') {
      return {
        user: 'user_id',
        // location: 'location_id',
        department: 'dept_ids',
        status: 'request_statuses',
      };
    } else if (path === '/information') {
      return {
        aodm: 'users',
        location: 'locations',
      };
    }
    return {};
  };
  const columnToFilterKeyMap = useDynamicColumnToFilterKeyMap();

  const fetchFilterOptions = async () => {
    const options: FilterOptionsType = {};
    try {
      const path = window.location.pathname;
      const isObservations = path === '/observations';
      const isSnags = path === '/snags';
      const isInformation = path === '/information';

      // const userOptions = await axios.get(`hotel/${user?.hotel_id}/all-users`);
      const userOptions = await axios.get(`/aodm/users/usersList`);

      options.user = userOptions.data;

      if (isObservations || isInformation) {
        const locationOptions = await axios.get('/aodm/getLocations');
        options.locations = locationOptions.data;

        if (isObservations) {
          const observationOptions = await axios.get('/aodm/getObservations');
          const categoryOptions = await axios.get('/aodm/getCategories');
          const departmentOptions = await axios.get('/aodm/getDepartments');
          options.observation = observationOptions.data;
          options.category = categoryOptions.data;
          options.department = departmentOptions.data;
          options.status = [
            { id: 1, name: 'Open' },
            { id: 2, name: 'Closed' }
          ];
        }
      } else if (isSnags) {
        const locationOptions = await axios.get(`/hotel/${user?.hotel_id}/locations`);
        const departmentOptions = await axios.get(`/hotel/${user?.hotel_id}/desks`);
        options.locations = locationOptions.data;
        options.department = departmentOptions.data;
        options.status = [
          { id: 'Pending', name: 'Pending' },
          { id: 'Closed', name: 'Closed' },
          { id: 'Parked', name: 'Parked' },
          { id: 'Rejected', name: 'Rejected' },
        ];
      }
    } catch (error) {
      console.error('Error fetching filter options:', error);
    }

    setFilterOptions(options);
  };
  
  
  const formatFilters = (selectedColumnData: { [key: string]: string[] }) => {
    const filters = Object.keys(selectedColumnData).reduce((acc, key) => {
      const filterKey = columnToFilterKeyMap[key as keyof typeof columnToFilterKeyMap]; // Map the column key to the filter key
      if (filterKey && selectedColumnData[key].length > 0) {
        acc[filterKey] = selectedColumnData[key].join(',');
      }
      return acc;
    }, {} as { [key: string]: string });
    return filters;
  };

  return {
    filterOptions,
    selectedColumnData,
    setSelectedColumnData,
    formatFilters,
  };
};

export default useFilters;
