
import { axiosInstance as axios } from "../utils/axios";

const getObservations = async (filters) => {
  let timestamp = new Date().getTime();
  try {
    // const response = await axios.get('/aodm/getObservations');
    const response = await axios.get('/aodm/getObservations?time='+timestamp, {
      params: filters
    });
    console.log('Getting Observations');
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getObservationDetails = async (id) => {
  let timestamp = new Date().getTime();
  try {
    const response = await axios.get('/aodm/getObservations?time='+timestamp, {
      params: {  observation_id: id }
    });
    console.log('Getting Observation Details');
    return response.data;
  } catch (error) {
    console.error('Error fetching observation details:', error);
  }
};

const closeObservation = async (formData) => {
  try {
    const response = await axios.post('/aodm/observation/close',formData);
    return response.data
  } catch (error) {
    console.error('Error updating observation status:', error);
  }
};

const getLocations = async () => {
  let timestamp = new Date().getTime();
  try {
    const response = await axios.get('/aodm/getLocations?time='+timestamp);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getCategories = async () => {
  let timestamp = new Date().getTime();
  try {
    const response = await axios.get('/aodm/getCategories?time='+timestamp);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getDepartments = async () => {
  let timestamp = new Date().getTime();
  try {
    const response = await axios.get('/aodm/getDepartments?time='+timestamp);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const createObservation = async (newObservation) => {
  try {
    await axios.post('/aodm/createObservation', newObservation);
    console.log("Creating Observations");
  } catch (error) {
    throw error;
  }
};

const createLocation = async (locName) => {
  try {
      const response = await axios.post('/aodm/createLocation', { loc_name: locName });
      return response;
  } catch (error) {
    throw error
  }
};
const createDepartment = async (deptName) => {
  try {
      const response = await axios.post('/aodm/createDepartment', { dept_name: deptName });
      return response;
  } catch (error) {
    throw error
  }
};

const createCategory = async (categoryName) => {
  
  try {
      const response = await axios.post('/aodm/createCategory', { category_name: categoryName });
      return response;
  } catch (error) {
    throw error
  }
};

const getUsersList = async (timestamp) => {
  try {
    const response = await axios.get('/aodm/users/usersList?time='+timestamp);
    return response.data;
  } catch (error) {
    throw error;
  }
}

const createAodmUser = async (formValues) => {
  try {
    const response = await axios.post('/aodm/users/newAodmUser',formValues);
    return response.data;
  } catch (error) {
    throw error;
  }
}

const getHistoryLogs = async (formValues) => {
  let timestamp = new Date().getTime();
  try {
    const response = await axios.get('/aodm/history/getLogs?time='+timestamp,{
      params: formValues 
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

const updateHistoryLogs = async (formValues) => {
  try {
    const response = await axios.post('/aodm/history/updateLogs',formValues);
    return response.data;
  } catch (error) {
    throw error;
  }
}

const deleteDepartment = async (deptId) => {
  try {
    const response = await axios.post('/aodm/department/deleteDepartment',{ dept_id: deptId });
    return response.data;
  } catch (error) {
    console.error('Error deleting department:', error);
    throw error;
  }
};

const deleteCategory = async (catId) => {
  try {
    const response = await axios.post('/aodm/category/deleteCategory',{ category_id: catId });
    return response.data;
  } catch (error) {
    console.error('Error deleting category:', error);
    throw error;
  }
};

const deleteLocation = async (locId) => {
  try {
    const response = await axios.post('/aodm/location/deleteLocation',{ loc_id: locId });
    return response.data;
  } catch (error) {
    console.error('Error deleting location:', error);
    throw error;
  }
};

const updateLocation = async (locId, locName) => {
  const response = await axios.post('/aodm/location/editLocation', {
    loc_id: locId,
    loc_name: locName,
  });
  return response.data;
};

const updateCategory = async (catId, catName) => {
  const response = await axios.post('/aodm/category/editCategory', {
    category_id: catId,
    category_name: catName,
  });
  return response.data;
};

const updateDepartment = async (depId, depName) => {
  const response = await axios.post('/aodm/department/editDepartment', {
    dept_id: depId,
    dept_name: depName,
  });
  return response.data;
};

const deleteAodmUser = async (userId) => {
  
    const response = await axios.post('/aodm/users/deleteUser', {
      user_id: userId
    });
    return response.data;
}

const updateAodmUser = async (payload) => {
  const response = await axios.post('/aodm/users/editUser', payload);
  return response.data;
};

const getInformation = async (filters) => {
  let timestamp = new Date().getTime();

  try {
    const response = await axios.get("/aodm/getInformation?time="+timestamp, { params: filters });
    return response.data;
  } catch (error) {
    console.error("Error fetching information:", error.message);
  }
};

const fetchNotifications = async () => {
  let timestamp = new Date().getTime();
  try {
    const response = await axios.get(`/aodm/notifications/getNotifications?time=${timestamp}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching notifications:", error);
  }
};

const fetchUnreadNotificationsCount = async () => {
  let timestamp = new Date().getTime();
  try {
    const response = await axios.get(`/aodm/notifications/getUnreadNotificationsCount?time=${timestamp}`);
    return response.data.unread_count;
  } catch (error) {
    console.error("Error fetching unread notifications count:", error);
  }
};

const markAllAsRead = async () => {
  try {
    await axios.post('/aodm/notifications/setReadNotifications');
  } catch (error) {
    console.error("Error marking notifications as read:", error);
  }
};

const clearAllNotifications = async () => {
  try {
    await axios.post("/aodm/notifications/clearAllNotifications");
  } catch (error) {
    console.error("Error clearing all notifications:", error);
  }
};

const clearNotification = async (id) => {
  try {
    await axios.post("/aodm/notifications/deleteNotification", { id });
  } catch (error) {
    console.error("Error deleting notification:", error);
  }
};

const fetchNotificationHistory = async () => {
  try {
    const response = await axios.get(
      "/aodm/notifications/getNotificationHistory"
    );
    return response.data
  } catch (error) {
    console.error("Error fetching notification history:", error);
  }
};


export { getObservations, getObservationDetails,getInformation, closeObservation, getLocations, getCategories, getDepartments, 
  createObservation, createLocation, createDepartment, createCategory, getUsersList, createAodmUser, getHistoryLogs, updateHistoryLogs, deleteDepartment, deleteCategory, deleteLocation, 
  updateLocation ,updateCategory, updateDepartment, deleteAodmUser ,updateAodmUser, fetchNotifications, fetchUnreadNotificationsCount, markAllAsRead, clearNotification, clearAllNotifications, fetchNotificationHistory };