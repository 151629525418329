import React, { useState } from "react";
import {
  useForm,
  SubmitHandler,
  Controller,
  useFormState,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { axiosInstance as axios } from "../utils/axios";
import { getStorage, setStorage } from "../utils/storage"; // Import the custom storage functions

import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  Stack,
  Link,
  Container,
  Typography,
  Button,
  FormControl,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useAuth } from "src/context/authContext";

type FormData = {
  u: string;
  p: string;
  remember: boolean;
};

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  //minHeight: '100vh',
  flexDirection: "column",
  justifyContent: "center",

  padding: theme.spacing(6, 0),
}));

const loginStyle = {
  backgroundImage: "url(/Bg_IMG.png)",
  //backgroundSize: 'cover',
  backgroundSize: "100% 100vh",
  backgroundPosition: "center",
  backgroundAttachment: "fixed",
  backgroundRepeat: "no-repeat",
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const Login = () => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormData>();
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { login } = useAuth();
  const [showMessage, setShowMessage] = useState(false);

  /**-------------------------OLD LOGIN----------------- */
  // const [altAnimation, setAltAnimation] = useState(false);

  // const apiLogin = async (u: string, p: string) => {
  //   const response = await axios.post("/auth", { u, p });
  //   return response;
  // };

  // const onSubmit: SubmitHandler<FormData> = async (formData) => {
  //   try {
  //     const response = await apiLogin(formData.u, formData.p);
  //     setStorage("user", response.data);
  //     console.log("USER:", response.data);
  //     axios.defaults.headers.common = {
  //       Authorization: `Bearer ${response.data.api_token}`,
  //     };

  //     // setAltAnimation(true);
  //     // setTimeout(() => { playStart() }, 250);
  //     setTimeout(() => {
  //       navigate("/observations", { replace: true });
  //     }, 0);
  //   } catch (err) {
  //     console.error("Login error:", err);
  //     setError("Invalid email or password");
  //   }
  // };

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    try {
      await login(formData.u, formData.p);
      navigate("/observations", { replace: true });
    } catch (err) {
      console.error("Login error:", err);
      setError("Invalid email or password");
    }
  };


  const handleClick = () => {
    setShowMessage(true);
  };

  return (
    <div style={loginStyle}>
      <Container maxWidth="sm" sx={{ mr: 0 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src="/logo/vesta.png" alt="" style={{ width: "50%" }} />
          <Typography sx={{ color: "white" }} variant="subtitle2" gutterBottom>
            Transforming Terminal & Runway Operations
          </Typography>
        </Box>
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography sx={{ color: "white" }} variant="h4" gutterBottom>
                Sign in
              </Typography>
              <Typography sx={{ color: "white" }}>
                Enter your details to continue
              </Typography>
            </Box>
          </Stack>

          {/* <LoginForm /> */}
          <FormControl onSubmit={handleSubmit(onSubmit)} component="form">
            <Stack spacing={3}>
              <Controller
                name="u"
                control={control}
                rules={{ required: true, maxLength: 42 }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Username/Email ID"
                    required
                    sx={{
                      "& .MuiInputLabel-root": { color: "white" }, // Label color
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "white", // Border color
                        },
                        "&:hover fieldset": {
                          borderColor: "white", // Border color on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "white", // Border color when focused
                        },
                        "& input": {
                          color: "white", // Input text color
                        },
                      },
                    }}
                  />
                )}
              />
              <Controller
                name="p"
                control={control}
                rules={{ required: true, maxLength: 42 }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Password"
                    type="password"
                    required
                    sx={{
                      "& .MuiInputLabel-root": { color: "white" }, // Label color
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "white", // Border color
                        },
                        "&:hover fieldset": {
                          borderColor: "white", // Border color on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "white", // Border color when focused
                        },
                        "& input": {
                          color: "white", // Input text color
                        },
                      },
                    }}
                  />
                )}
              />
            </Stack>

            <Stack
              direction="row-reverse"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}
            >
              {/* <Controller
                name="remember"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        sx={{
                          color: "white",
                        }}
                      />
                    }
                    label="Remember Me"
                    sx={{
                      color: "white",
                    }}
                  />
                )}
              /> */}
              <Link
                onClick={handleClick}
                sx={{
                  color: "white",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Forgot password?
              </Link>
            </Stack>
            {showMessage && (
              <Box
                sx={{
                  position: 'relative',
                  bgcolor: 'rgba(255, 255, 0, 0.5)', // Increased opacity
                  p: 2,
                  borderRadius: 1,
                  mb: 2,
                }}
              >
                <Typography sx={{ color: 'black', }}>
                  Kindly contact your IT / Admin to reset your password.
                </Typography>
              </Box>
            )}


            <Button fullWidth size="large" type="submit" variant="contained">
              Login
            </Button>
          </FormControl>
        </ContentStyle>
      </Container>
    </div>
  );
};
