import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  Card,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// import { format, formatDistanceToNow } from "date-fns";
import { formatDateTime } from "src/utils/helperFunctions";
import ObservationHistoryLog from "./ObservationHistoryLog";
import { getDepartments, getCategories } from "src/utils/api";
import { axiosInstance as axios } from "src/utils/axios";
import { useNotifications } from "src/context/NotificationsContext";
import useAlerts from "src/components/Alerts";

interface ObservationDetailsProps {
  observation: any;
  onClose: () => void;
  fetchTableData: any;
  onCategorySave: any;
  onDepartmentSave: any;
}

const ObservationDetails = ({
  observation,
  onClose,
  fetchTableData,
  onCategorySave,
  onDepartmentSave
}: ObservationDetailsProps) => {
  const theme = useTheme();
  const [reassignOpen, setReassignOpen] = useState(false);
  const [newDepartment, setNewDepartment] = useState<any>([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [comments, setComments] = useState('');
  const { refreshNotifications } = useNotifications();
  const { showAlert } = useAlerts();
  const [categoryReassignOpen, setCategoryReassignOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [newCategories, setNewCategories] = useState([]);

  const handleCategoryReassignOpen = () => setCategoryReassignOpen(true);
  const handleCategoryReassignClose = () => setCategoryReassignOpen(false);

  const handleCategoryChange = (event: any) => {
    setSelectedCategory(event.target.value);
  };
  
  const handleCategorySave = () => {
    if (!selectedCategory) {
      showAlert("Please select a new Category", "warning");
      return;
    }
    onCategorySave(observation?.id, selectedCategory, comments);  // Call the parent function with necessary arguments
    handleCategoryReassignClose()
  };

  useEffect(() => {
    fetchDepartments();
    fetchCategories()
  }, []);

  if (!observation) return null;

  const imageUrls =
    observation.attachments && observation.attachments.length > 0
      ? observation.attachments
      : [];

  const fetchCategories = async () => {
    try {
      const categories = await getCategories();
      setNewCategories(categories);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };


  const fetchDepartments = async () => {
    try {
      const departments = await getDepartments();
      setNewDepartment(departments);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const handleReassignOpen = () => setReassignOpen(true);
  const handleReassignClose = () => setReassignOpen(false);

  const handleDepartmentChange = (event: any) => {
    setSelectedDepartment(event.target.value as string);
  };

  const handleReassignSave = () => {
    if (!selectedDepartment) {
      showAlert("Please select a new department", "warning");
      return;
    }
    onDepartmentSave(observation?.id, selectedDepartment, comments);  // Call the parent function with necessary arguments
    handleReassignClose()
  };

  return (
    <>
      <Dialog
        open={Boolean(observation)}
        onClose={onClose}
        fullWidth
        maxWidth={"md"}
        PaperProps={{
          style: {
            borderRadius: 20,
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "#231F20",
              mb: 2,
              fontWeight: "bold",
              textAlign: "center", // Center the text
              width: "100%",
            }}
          >
            Observation Details
          </Typography>
          <IconButton onClick={onClose}>
            <Icon icon="material-symbols:close" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Card
            sx={{
              p: 2,
              mt: 2,
              mb: 2,
              color: "#5A5A5A",
              border: "1.4px dashed #7A96AF ",
              borderRadius: "16px",
            }}
          >
            <Grid container spacing={2} gap={2}>
              <Grid
                item
                xs={5}
                md={3}
                sx={{ maxHeight: "500px", overflowY: "auto" }}
              >
                {/* {imageUrls.map((url: any, index: any) => ( */}
                  <Grid item xs={2} md={2} ml={2}>
                    <a href={imageUrls[0]} target="_blank" rel="noopener noreferrer">
                      <Box
                        component="img"
                        sx={{
                          width: 167.3,
                          height: 167.3,
                          objectFit: "cover",
                        }}
                        src={imageUrls[0]}
                        alt={`Image`}
                      />
                    </a>
                  </Grid>
                {/* ))} */}
              </Grid>
              <Grid item xs>
                <Box
                  sx={{
                    bgcolor: "#7A96AF",
                    borderRadius: 1,
                    p: 1,
                    display: "inline-block",
                    color: "#231F20",
                  }}
                >
                  <Typography variant="body2">
                    <Icon
                      icon="line-md:text-box"
                      width={15}
                      height={15}
                      style={{ position: "relative", top: 3 }}
                    />{" "}
                    ID: {observation?.id}
                  </Typography>
                </Box>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#231F20",
                    mb: 2,
                    mt: 2,
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  {" "}
                  <Icon
                    icon="line-md:map-marker-alt-filled"
                    width={20}
                    height={20}
                    style={{ position: "relative", top: 3, marginRight: 2 }}
                  />
                  {observation.loc_details}
                </Typography>

                <Box
                  sx={{
                    p: 1,
                    // display: "inline-flex",
                    display: "flex",

                  }}
                >
                  <Box sx={{
                    p: 1,
                    display: "inline-flex",
                    alignItems: "center",
                    backgroundColor: "#E8F0F7",
                    borderRadius: "8px",
                    color: "#7A96AF",
                  }}>
                    <Icon
                      icon="mdi:tag"
                      width={24}
                      height={24}
                      style={{ marginRight: "8px", color: "#7A96AF" }}
                    />
                    <Typography variant="body1" sx={{ color: "#7A96AF" }}>
                      {observation.category}
                    </Typography>
                  </Box>
                  <Box
                    onClick={handleCategoryReassignOpen}
                    sx={{
                      paddingLeft: "10px",
                      alignItems: "center",
                      cursor: "pointer",
                      textDecoration: "underline"
                    }}
                  >
                    Change category?
                  </Box>
                </Box>

                <Typography
                  variant="body1"
                  sx={{
                    color: "#2D2D2D",
                    mb: 2,
                    mt: 2,
                    width: "100%",
                  }}
                >
                  {observation.observation}
                </Typography>
                <Box sx={{ mt: 3 }}>
                  <Grid container spacing={-12}>
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                          color: theme.palette.obs.main,
                        }}
                      >
                        <Icon icon="material-symbols:person" />
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          Created by:
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                          color: theme.palette.obs.main,
                        }}
                      >
                        <Icon icon="material-symbols:calendar-today" />
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          Created at:
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                          color: theme.palette.obs.main,
                        }}
                      >
                        <Icon icon="charm:id" />
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          Department:
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                          color: theme.palette.obs.main,
                        }}
                      >
                        <Icon icon="material-symbols:check-circle" />
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          Status:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body2"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                          color: "#2D2D2D",
                        }}
                      >
                        {observation.aodm}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                          color: "#2D2D2D",
                        }}
                      >
                        {" "}
                        {formatDateTime(observation.created_at)}
                      </Typography>
                      <div style={{ display: "flex", }}>
                        <Typography
                          variant="body2"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 2,
                            color: "#2D2D2D",
                          }}
                        >
                          {observation.department}{' '}
                        </Typography>
                        <Box
                          onClick={handleReassignOpen}
                          sx={{
                            //color: "#fff",
                            //"&:hover": { backgroundColor: "#0056b3" },
                            fontWeight: "bold",
                            paddingLeft: "10px",
                            cursor: "pointer",
                            textDecoration: "underline"
                          }}
                        >
                          Change Department?
                        </Box>
                      </div>

                      <Chip
                        label={observation.status}
                        color={
                          observation.status === "OPEN" ? "warning" : "success"
                        }
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Card>
          <Typography
            variant="h6"
            sx={{
              color: "#231F20",
              mb: 2,
              fontWeight: "bold",

              width: "100%",
            }}
          >
            Observation History / Audit Log
          </Typography>
          {/* Command Box */}
          <ObservationHistoryLog id={observation.id} fetchTableData={fetchTableData} status={observation.status} onClose={onClose} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={reassignOpen}
        onClose={handleReassignClose}
        fullWidth
        maxWidth={"sm"}
        PaperProps={{
          style: {
            borderRadius: 20,
            padding: 20, // Added padding to the PaperProps
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "#231F20",
              mb: 2,
              fontWeight: "bold",
              textAlign: "center",
              width: "100%",
            }}
          >
            Reassign Department
          </Typography>
          <IconButton onClick={handleReassignClose}>
            <Icon icon="material-symbols:close" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: 2 }}>
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={5}>
                <Typography sx={{ color: "#1D2952", }}>
                  Currently Assigned to
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  label="Department"
                  value={observation.department}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Typography sx={{ color: "#1D2952", }}>
                  New Assignment
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <FormControl fullWidth required>
                  <InputLabel id="department-select-label">New Department</InputLabel>
                  <Select
                    required
                    labelId="department-select-label"
                    id="department-select"
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    fullWidth
                  >
                    {newDepartment.map((dept: any) => (
                      <MenuItem key={dept.id} value={dept.id}>
                        {dept.dept_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Typography sx={{ color: "#1D2952", }}>
                  Comments (optional)
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  label="Comments (optional)"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={10} sx={{ display: "flex", justifyContent: "center" }}>
                <Button onClick={handleReassignSave} color="primary" variant="contained" fullWidth>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={categoryReassignOpen}
        onClose={handleCategoryReassignClose}
        fullWidth
        maxWidth={"sm"}
        PaperProps={{
          style: {
            borderRadius: 20,
            padding: 20,
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "#231F20",
              mb: 2,
              fontWeight: "bold",
              textAlign: "center",
              width: "100%",
            }}
          >
            Reassign Category
          </Typography>
          <IconButton onClick={handleCategoryReassignClose}>
            <Icon icon="material-symbols:close" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: 2 }}>
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={5}>
                <Typography sx={{ color: "#1D2952" }}>
                  Currently Assigned to
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  label="Category"
                  value={observation.category}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Typography sx={{ color: "#1D2952" }}>
                  New Assignment
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <FormControl fullWidth required>
                  <InputLabel id="category-select-label">New Category</InputLabel>
                  <Select
                    required
                    labelId="category-select-label"
                    id="category-select"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    fullWidth
                  >
                    {newCategories.map((category: any) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.category_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Typography sx={{ color: "#1D2952" }}>
                  Comments (optional)
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  label="Comments (optional)"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={10} sx={{ display: "flex", justifyContent: "center" }}>
                <Button onClick={handleCategorySave} color="primary" variant="contained" fullWidth>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>

    </>
  );
};

export default ObservationDetails;
