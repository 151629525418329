import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Icon } from "@iconify/react";
import { deleteStorage } from "src/utils/storage";
import { useTheme, alpha } from "@mui/material/styles";
import { useAuth } from "../context/authContext";
import { Avatar, Card, CardContent } from "@mui/material";
import EditProfileDialog from "./EditProfileDialog";
import NotificationBox from "./NotificationBox";
import { useNotifications } from "../context/NotificationsContext";
import { markAllAsRead } from "src/utils/api";

export default function HeaderAppBar() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const [editProfileOpen, setEditProfileOpen] = useState(false);
  const [notificationAnchorEl, setNotificationAnchorEl] =
    useState<null | HTMLElement>(null);
  const isNotificationMenuOpen = Boolean(notificationAnchorEl);

  const { notificationCount, refreshNotifications, setNotificationCount } =
    useNotifications();

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        await refreshNotifications();
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    fetchInitialData();
  }, []);

  const handleLogout = () => {
    deleteStorage("user");
    navigate("/");
    window.location.reload();
  };

  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationMenuClose = () => {
    if (notificationCount > 0) {
      markAllAsRead();
      refreshNotifications();
    }
    setNotificationAnchorEl(null);
  };

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileOpen = () => {
    setEditProfileOpen(true);
    handleMenuClose(); // Close the menu when opening the dialog
  };

  const handleProfileClose = () => {
    setEditProfileOpen(false);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleProfileOpen}>
        {" "}
        <Icon icon="mingcute:user-edit-fill" style={{ paddingRight: 5 }} />{" "}
        PROFILE
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        {" "}
        <Icon icon="ri:logout-box-fill" style={{ paddingRight: 5 }} /> LOGOUT
      </MenuItem>
    </Menu>
  );

  const getHeading = () => {
    switch (location.pathname) {
      case "/observations":
        return { text: "AODM OBSERVATIONS", color: theme.palette.obs.main };
      case "/information":
        return {
          text: "AODM INFORMATION",
          color: theme.palette.information.main,
        };
      case "/snags":
        return { text: "AODM SNAGS", color: theme.palette.snags.main };
      case "/reports":
        return { text: "Download Reports", color: theme.palette.snags.main };
      case "/configuration":
        return { text: "CONFIGURATION", color: theme.palette.obs.main };
      case "/configuration/users":
        return { text: "USERS", color: theme.palette.obs.main };
      case "/configuration/departments":
        return { text: "DEPARTMENTS", color: theme.palette.obs.main };
      case "/configuration/locations":
        return { text: "LOCATIONS", color: theme.palette.obs.main };
      case "/configuration/category":
        return { text: "CATEGORY", color: theme.palette.obs.main };
      case "/dashboard":
      return { text: "DASHBOARD", color: theme.palette.snags.main };
      default:
        return { text: "DASHBOARD", color: "#000000" };
    }
  };
  const heading = getHeading();

  return (
    <Card
      sx={{
        flexGrow: 1,
        boxShadow: `${alpha(heading.color, 0.9)} 0px 12px 20px -12px`,
      }}
    >
      <AppBar position="static" sx={{ bgcolor: "white" }}>
        <Toolbar>
          <Typography
            variant="h5"
            sx={{
              display: { color: heading.color, fontWeight: "bold" },
              marginLeft: 9,
            }}
          >
            {heading.text}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{ display: { xs: "none", md: "flex" }, position: "relative" }}
          >
            <IconButton
              size="large"
              aria-label="show new notifications"
              color="default"
              onClick={handleNotificationClick}
            >
              <Badge badgeContent={notificationCount} color="error">
                <Icon icon="line-md:bell-twotone-loop" />
              </Badge>
            </IconButton>
            <NotificationBox
              anchorEl={notificationAnchorEl}
              open={isNotificationMenuOpen}
              onClose={handleNotificationMenuClose}
              setNotificationCount={setNotificationCount}
              refreshNotifications={refreshNotifications}
            />
            <IconButton
              size="medium"
              onClick={handleProfileMenuOpen}
              color="default"
              sx={{ borderRadius: "10%", p: 0, ml: 0.3 }}
            >
              <Card
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  p: 1,
                  height: 20,
                  m: 1.5,
                }}
              >
                <Avatar
                  alt="User Avatar"
                  src={user?.avatar ? user.avatar : undefined}
                  sx={{ width: 25, height: 25 }}
                >
                  {!user?.avatar && <Icon icon="line-md:person-filled" />}
                </Avatar>
                <CardContent>
                  <Typography variant="h6" sx={{ fontSize: "1.1rem" }}>
                    {user?.name}
                  </Typography>
                </CardContent>
                <Icon icon="line-md:chevron-small-down" />
              </Card>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
      <EditProfileDialog open={editProfileOpen} onClose={handleProfileClose} />
    </Card>
  );
}
