import React, { useState, useEffect } from "react";
import {
	Box,
	Button,
	Paper,
	Card,
	Typography,
	InputLabel,
	Switch,
	Divider,
	Select,
	MenuItem,
	SelectChangeEvent,
	Stack,
	Tooltip,
	Link,
	CardActionArea,
	TablePagination,
	Autocomplete, TextField, Grid, BoxProps, SxProps
  } from "@mui/material";
  import { Icon, IconifyIcon } from '@iconify/react';
import FilterRenderer from "src/components/DynamicFilters";
import { REPORT_BASE_URL} from "src/config";
import { useAuth } from 'src/context/authContext';

  const PRODUCT_METADATA = [
	{ id: "Observations", filters: ['created_at_start', 'created_at_end', 'department','category','status'] },
	{ id: "Snags", filters: ['created_at_start', 'created_at_end', 'Snag','dept','Request_status']  },
	{ id: "Information", filters: ['created_at_start', 'created_at_end'] },
  ];

  const EXPORTABLES = [
	{ name: "Excel", id:"xlsx", desc: "Excel Spreadsheet. Works on latest versions of MS Excel (2016+)", supportsImages: false, icon: "vscode-icons:file-type-excel" },
	{ name: "HTML", id:"html", desc: "HTML file, can be opened on any web browser", supportsImages: true, icon: "ph:file-html-thin" },
];




interface Props extends BoxProps {
	sx?: SxProps;
	icon: IconifyIcon | string;
  }
  
export function Iconify({ icon, sx, ...other }: Props) {
return <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />;
}
  
const DownloadReports = () => {
	const [selectedModule, setSelectedModule] = useState(PRODUCT_METADATA[0].id);
	const [filters, setFilters] = useState(PRODUCT_METADATA[0].filters);
	const [reportGenUrl, setReportGenUrl] = useState<string>('');
  const [filterValues, setFilterValues] = useState<{ [key: string]: any }>({});
	//  const [filterValues, setFilterValues] = useState({});
  const [resetTrigger, setResetTrigger] = useState(false); 
  const { user } = useAuth();

  const handleFilterChange = (key: string, value: any) => {
    setFilterValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
	const handleProductChange = (event: any) => {
	  const selected = event.target.value;
	  const module = PRODUCT_METADATA.find((g) => g.id === selected);
	  setSelectedModule(selected);
	  setFilters(module?.filters || []);
    setFilterValues({});
    setResetTrigger(prev => !prev);
	};

  const formatDate = (date: any) => {
    if (!date) return '';
    const d = new Date(date);
    return d.toISOString(); // This will return the date in the format 2024-08-01T12:51
  };

  const generateReportUrl = () => {
    let query = `?product=aodm-${selectedModule.toLowerCase()}&hotel_id=${user?.hotel_id}&token=${user?.hotel?.uid}&timezone=${user?.timezone}`;
  
    query += `&date_from=${formatDate(filterValues.created_at_start)}`;
    query += `&date_to=${formatDate(filterValues.created_at_end)}`;
     // Check if department is an array and map the IDs if available
  if (Array.isArray(filterValues.department)) {
    const departmentIds = filterValues.department.map((dept: any) => dept.id).join(',');
    query += `&aodmDept=${departmentIds}`;
  }

  if (Array.isArray(filterValues.dept)) {
    const departmentIds = filterValues.dept.map((dept: any) => dept.id).join(',');
    query += `&desk_id=${departmentIds}`;
  }

  if (Array.isArray(filterValues.Snag)) {
    const departmentIds = filterValues.Snag.map((dept: any) => dept.id).join(',');
    query += `&item_id=${departmentIds}`;
  }
  // Check if category is an array and map the IDs if available
  if (Array.isArray(filterValues.category)) {
    const categoryIds = filterValues.category.map((cat: any) => cat.id).join(',');
    query += `&aodmCategory=${categoryIds}`;
  }

  
    // Add other filter values, e.g., status, Request_status
    query += `&aodmStatus=${filterValues.status ?? ''}`;
    query += `&requestScope=${filterValues.Request_status ?? ''}`;

    setReportGenUrl(`${REPORT_BASE_URL}/admin/analytics/export${query}`);
  };
  
   // Automatically generate URL when filterValues or selectedModule change
   useEffect(() => {
    generateReportUrl();
  }, [filterValues, selectedModule]);  // 
  return (
	
<Box sx={{ maxWidth: "90%",padding: "20px",marginLeft: "48px"}}>
  {/* Outer Card */}
  <Card sx={{ p: 4, borderRadius: '16px',marginLeft: "48px" }}>
    {/* Module Selector */}
    <Box sx={{ my: 4 }}>
      <InputLabel id="product-selector-label">Module</InputLabel>
      <Select
        sx={{ minWidth: 300 }}
        value={selectedModule}
        onChange={handleProductChange}
      >
        {PRODUCT_METADATA.map((module) => (
          <MenuItem key={module.id} value={module.id}>
            {module.id}
          </MenuItem>
        ))}
      </Select>
    </Box>

    {/* Filter Example */}
    <div>
      <Typography variant="h5" sx={{ mb: 2 }}>Filters </Typography>
      <Box sx={{ p: 1 }}>
        <FilterRenderer filters={filters} handleChange={handleFilterChange} resetTrigger={resetTrigger} />
      </Box>
    </div>

    {/* Download Section */}
    <Typography variant="h6" sx={{ mt: 4, mb: 2, textAlign: 'center' }}>
      Download as
    </Typography>

    {/* Centered Download Cards */}
    <Stack
      direction="row"
      sx={{ width: '100%', mt: 3, justifyContent: 'center' }}
      alignItems="center"
      spacing={3}
    >
      {EXPORTABLES.map((filetype: any, index) => (
        <Card key={index} sx={{ width: 'auto', minWidth: 200 }} variant="elevation">
          <Tooltip title={filetype.desc ?? ''} arrow placement="bottom">
            <Link
              href={`${reportGenUrl+`&format=${filetype?.id}`}&format=${filetype?.id}`}
              target={"_download"}
              style={{ textDecoration: 'none', color: 'inherit' }}
              onClick={(e) => {
                if (!filterValues?.created_at_start || !filterValues?.created_at_end) {
                  // Prevent default link behavior if date range is invalid
                  e.preventDefault();
                  
                  // Display error message
                  alert("Please select both a start date and an end date for the report.");
                }
              }}
            >
              <CardActionArea sx={{ p: 2, textAlign: 'center' }}>
                <Iconify icon={filetype.icon ?? "mdi:file-chart-check-outline"} width={64} height={64} />
                <Typography variant="h6">{filetype.name ?? '-'}</Typography>
                <Typography variant="subtitle2">.{filetype.id ?? '-'}</Typography>
              </CardActionArea>
            </Link>
          </Tooltip>
        </Card>
      ))}
    </Stack>
  </Card>
</Box>

  )
}

export default DownloadReports