import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  InputBase,
  Divider,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  getObservations,
  getCategories,
  createCategory,
  deleteCategory,
  updateCategory,
} from "../../utils/api";
import {formatDateTime} from "src/utils/helperFunctions";
import useAlerts from "src/components/Alerts";
import DeleteDialog from "src/components/DeleteDialog";

interface Categories {
  id: number;
  category_name: string;
}
const Category = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [categories, setCategories] = useState<any>([]);
  const [formValues, setFormValues] = useState({ category_name: "" });
  const { showAlert } = useAlerts();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedColumnData, setSelectedColumnData] = useState<any>({});
  const navigate = useNavigate();
  const theme = useTheme();
  const [editingCatId, setEditingCatId] = useState(null);
  const [newCatName, setNewCatName] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [catToDelete, setCatToDelete] = useState(null);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleBackButton = () => {
    navigate("/configuration");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const fetchCategories = async () => {
    // let cacheBuster = new Date().getTime();
    try {
      const categories = await getCategories();
      setCategories(categories);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const createNewCategory = async () => {
    try {
      const existingCategories = await getCategories();
      const categoryExists = existingCategories.some(
        (cat: Categories) => cat.category_name.toLowerCase() === formValues.category_name.toLowerCase()
      );
  
      if (categoryExists) {
        showAlert("Category already exists", "warning");
        return;
      }
      const res = await createCategory(formValues.category_name);
      if (res.data && res.data.message === "Category created successfully") {
        showAlert("Category created successfully", "success");
      } else {
        showAlert("Unexpected response, please try again", "warning");
      }
      // window.location.reload();
      formValues.category_name = ""; // Clear the form
      fetchCategories(); // Refresh category list
      handleClose();
    } catch (error) {
      showAlert("Failed to create category", "error");
    }
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };
  const filteredData = categories
    .filter((row: any) => {
      return Object.keys(selectedColumnData).every((field: string) => {
        const filterValues = selectedColumnData[field];
        if (!filterValues || filterValues.length === 0) return true;
        return filterValues.includes(row[field]?.toString());
      });
    })
    .filter((row: any) => {
      return Object.values(row).some(
        (value: any) =>
          value?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
          row.id?.toString().includes(searchQuery) // Include the id field in the search
      );
    });

  const handleDeleteClick = (catId: any) => {
    setCatToDelete(catId);
    setDeleteDialogOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setCatToDelete(null);
  };

  // const handleDelete = async () => {
  //   try {
  //     await deleteCategory(catToDelete);
  //     showAlert("Category deleted successfully", "success");
  //     setCategories(categories.filter((cat: any) => cat.id !== catToDelete));
  //     setDeleteDialogOpen(false);
  //     setCatToDelete(null);
  //   } catch (error) {
  //     console.error("Error deleting category:", error);
  //     showAlert("Failed to delete category", "error");
  //   }
  // };
  const fetchObservations = async () => {
    try {
      const response = await getObservations();
      return response?.data;
    } catch (error) {
      console.error("Error fetching observations:", error);
    }
  };

  // Fetch categories from the API and create a mapping of category IDs to names
const fetchCategoriesMap = async (): Promise<{ [key: number]: string }> => {
  const categories: Categories[] = await getCategories()
  return categories.reduce((map, category) => {
    map[category.id] = category.category_name;
    return map;
  }, {} as { [key: number]: string });
};
  const handleDelete = async () => {
    try {
      if (catToDelete === null) {
        showAlert("Category to delete is not selected", "error");
        return;
      }
      // Fetch observations and category mapping
      const observations = await fetchObservations();
      const categoriesMap = await fetchCategoriesMap();

      // Find the category name associated with catToDelete
      const categoryNameToDelete = categoriesMap[catToDelete];
      // console.log("categoryNameToDelete",categoryNameToDelete);

      if (!categoryNameToDelete) {
        showAlert("Category not found", "error");
        return;
      }
  
      // Check if the category is assigned to any observation
      const isCategoryAssigned = observations.some((obs: any) => obs.category === categoryNameToDelete);
      // console.log("isCategoryAssigned",isCategoryAssigned);
  
      if (isCategoryAssigned) {
        // Show alert if category is assigned
        showAlert("Cannot delete category because it is assigned to one or more requests.", "error");
      } else {
        // Proceed with deletion if the category is not assigned
        await deleteCategory(catToDelete);
        showAlert("Category deleted successfully", "success");
        setCategories(categories.filter((cat: any) => cat.id !== catToDelete));
        setDeleteDialogOpen(false);
        setCatToDelete(null);
      }
    } catch (error) {
      console.error("Error handling delete operation:", error);
      showAlert("Failed to delete category", "error");
    }
  };
  

  const handleEditClick = (catId: any, catName: any) => {
    setEditingCatId(catId);
    setNewCatName(catName);
  };

  const handleSaveClick = async (catId: any) => {
    try {
      await updateCategory(catId, newCatName);
      showAlert("Category updated successfully", "success");
      setCategories(
        categories.map((cat: any) =>
          cat.id === catId ? { ...cat, category_name: newCatName } : cat
        )
      );
      setEditingCatId(null);
      setNewCatName("");
    } catch (error) {
      showAlert("Failed to update category", "error");
    }
  };

  const handleCancelClick = () => {
    setEditingCatId(null);
    setNewCatName("");
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginLeft: "70px",
          }}
          onClick={handleBackButton}
        >
          <Icon
            icon="mdi:arrow-left"
            style={{
              fontSize: 74,
              color: theme.palette.obs.main,
              marginRight: 8,
            }}
          />
          <Typography variant="h6" sx={{ color: theme.palette.obs.main }}>
            Configuration Home Page
          </Typography>
        </Box>
        <Button
          variant="contained"
          startIcon={<Icon icon="mdi:plus-circle-outline" />}
          sx={{
            backgroundColor: theme.palette.obs.main,
            color: "#fff",
            borderRadius: "8px",
            textTransform: "none",
          }}
          onClick={handleClickOpen}
        >
          Add Category
        </Button>
      </Box>

      <TableContainer
        component={Paper}
        style={{
          maxWidth: "88%",
          margin: "auto",
          padding: "0 20px",
          marginLeft: "80px",
          marginTop: "40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
          }}
        >
          <Typography variant="h6" color={theme.palette.obs.main}>
            <b> No. of Categories: {categories.length}</b>
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "2px 10px",
              width: "300px",
            }}
          >
            <Icon icon="mdi:search" />
            <InputBase
              placeholder="Search categories"
              inputProps={{ "aria-label": "search" }}
              sx={{ ml: 1, width: "100%" }}
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Box>
        </Box>
        <Divider />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.obs.main }}
              >
                ID
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.obs.main }}
              >
                CATEGORY NAME
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.obs.main }}
              >
                CREATED AT
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.obs.main }}
              >
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((category: any, index: any) => (
              <TableRow key={index}>
                <TableCell>{category.id}</TableCell>
                <TableCell>
                  {editingCatId === category.id ? (
                    <TextField
                      value={newCatName}
                      onChange={(e) => setNewCatName(e.target.value)}
                      autoFocus
                    />
                  ) : (
                    category.category_name
                  )}
                </TableCell>
                <TableCell>{formatDateTime(category.created_at)}</TableCell>
                <TableCell
                  sx={{ display: "flex", justifyContent: "center", gap: 2 }}
                >
                  {editingCatId === category.id ? (
                    <>
                      <IconButton onClick={() => handleSaveClick(category.id)}>
                        <Icon
                          icon="mdi:check"
                          style={{ fontSize: 24, color: "green" }}
                        />
                      </IconButton>
                      <IconButton onClick={handleCancelClick}>
                        <Icon
                          icon="mdi:close"
                          style={{ fontSize: 24, color: "red" }}
                        />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton
                        onClick={() =>
                          handleEditClick(category.id, category.category_name)
                        }
                      >
                        <Icon
                          icon="mdi:pencil"
                          style={{ fontSize: 24, color: "#5a6e8c" }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteClick(category.id)}
                      >
                        <Icon
                          icon="mdi:delete"
                          style={{ fontSize: 24, color: "#e53935" }}
                        />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <DeleteDialog
        open={deleteDialogOpen}
        onClose={handleCancelDelete}
        onConfirm={handleDelete}
        title="Confirm Deletion"
        description="Are you sure you want to delete this category?"
      />

      <Dialog
        open={openDialog}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 20,
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
          Create Category
        </DialogTitle>
        <DialogContent>
          <Box sx={{ p: 1 }}>
            <TextField
              label="Category Name"
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              name="category_name"
              onChange={handleChange}
              value={formValues.category_name}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.obs.main,
                  color: "#fff",
                  borderRadius: "8px",
                  textTransform: "none",
                  padding: "6px 32px",
                  fontSize: "18px",
                }}
                onClick={createNewCategory}
              >
                CREATE CATEGORY
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Category;
