// filterConfig.ts
 // FilterRenderer.tsx
 import { useEffect, useState } from 'react';
 import { Box, Grid, FormControl,InputLabel  } from '@mui/material';
 import {  Button,TextField, Checkbox, Select, MenuItem, FormControlLabel, Autocomplete, CircularProgress } from '@mui/material';
 import { axiosInstance as axios } from '../utils/axios';
 import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import Iconify from './Iconify';
import { useAuth } from 'src/context/authContext';

interface FieldMeta {
    key: string;
    render: string;
    label: string;
    placeholder?: string;
    api_method?: string;
    api_endpoint?: string;
    api_params?: any;
    optionLabelKey?: string;  // To handle dynamic key in API response (e.g. 'dept_name', 'cat_name')
    staticOptions?: { id: string; name: string }[]; // 
  }

interface FilterRendererProps {
  filters: string[]; // list of filter keys to render
  handleChange: (key: string, value: any) => void;
  resetTrigger: boolean; // Add resetTrigger as prop
}

const FilterRenderer = ({ filters, handleChange, resetTrigger  }: FilterRendererProps) => {
  const [dynamicOptions, setDynamicOptions] = useState<{ [key: string]: any[] }>({});
  const [loadingOptions, setLoadingOptions] = useState<{ [key: string]: boolean }>({});
  const [filterValues, setFilterValues] = useState<{ [key: string]: any }>({}); // Hold filter values
  const { user } = useAuth();

  
  const filterConfig = {
    auto_emit: false,
    cache_list_apis: true,
    field_size: "small", // can be overridden by props
    default_use_case: "filter", // filter/form
    date_render: "default", // default | mui
    date_format_input: "dd MMM yyyy hh:mm a",
    default_render: ['created_at_start', 'created_at_end'],
    fieldMeta: [
      {
        key: "created_at_start",
        render: "dateTime",
        label: "Start Date/Time",
      },
      {
        key: "created_at_end",
        render: "dateTime",
        label: "End Date/Time",
      },
      {
        key: "Snag",
        render: "multiSelect",
        label: "Snag",
        optionLabelKey:"name",
        placeholder: "Select a Snag",
        api_method: "GET",
        api_endpoint: `/hotel/${user?.hotel_id}/all-items-with-dept`,  // Dynamic API endpoint
        api_params: {}, // Any additional params if necessary
      },
      {
        key: "department",
        render: "multiSelect",
        label: "Departments",
        optionLabelKey:"dept_name",
        placeholder: "Select a Departments",
        api_method: "GET",
        api_endpoint: `/aodm/getDepartments`,  // Dynamic API endpoint
        api_params: {}, // Any additional params if necessary
      },
      {
        key: "dept",
        render: "multiSelect",
        label: "Departments",
        optionLabelKey:"name",
        placeholder: "Select a Departments",
        api_method: "GET",
        api_endpoint: `/hotel/${user?.hotel_id}/desks`,  // Dynamic API endpoint
        api_params: {}, // Any additional params if necessary
      },
      {
        key: "category",
        render: "multiSelect",
        label: "Category",
        optionLabelKey:"category_name",
        placeholder: "Select a Category",
        api_method: "GET",
        api_endpoint: `/aodm/getCategories`,  // Dynamic API endpoint
        api_params: {}, // Any additional params if necessary
      },
      {
        key: "status",
        render: "dropdown",
        label: "Status",
        placeholder: "Select a status",
        staticOptions: [
          { id: 'Open', name: 'Open' },
          { id: 'Closed', name: 'Closed' },
        ]
      },
      {
        key: "Request_status",
        render: "dropdown",
        label: "Status",
        placeholder: "Select a status",
        staticOptions: [
          { id: 'Pending', name: 'Pending' },
          { id: 'Closed', name: 'Closed' },
          { id: 'Parked', name: 'Parked' },
          { id: 'Rejected', name: 'Rejected' }
        ]
      },
    ] as FieldMeta[],
  };
  
  const handleReset = () => {
    setFilterValues({}); // Reset filter values to empty
    filters.forEach((filterKey) => handleChange(filterKey, null)); // Reset all filters
  };

  // Reset filter values when resetTrigger changes
  useEffect(() => {
    handleReset();
  }, [resetTrigger]);

  useEffect(() => {
    filterConfig.fieldMeta.forEach((meta) => {
      if (meta.render === 'multiSelect' && meta.api_method === 'GET' && meta.api_endpoint) {
        setLoadingOptions((prev) => ({ ...prev, [meta.key]: true }));

        axios
          .get(meta.api_endpoint, { params: meta.api_params })
          .then((response) => {
            const data = response.data;
            setDynamicOptions((prev) => ({ ...prev, [meta.key]: data }));
          })
          .catch((error) => {
            console.error('Error fetching options:', error);
          })
          .finally(() => {
            setLoadingOptions((prev) => ({ ...prev, [meta.key]: false }));
          });
      }
    });
  }, []);

  const getOptionLabel = (meta: FieldMeta, option: any) => {
    return option[meta.optionLabelKey || 'name'] || ''; // Default to 'name' if optionLabelKey is not defined
  };
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} alignItems="center">
          {filterConfig.fieldMeta
            .filter((meta) => filters.includes(meta.key))
            .map((meta) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={meta.key}>
                {(() => {
                  switch (meta.render) {
                    case 'inputText':
                      return (
                        <TextField
                          label={meta.label}
                          placeholder={meta.placeholder}
                          // size="small"
                          fullWidth
                          value={filterValues[meta.key] || ''}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFilterValues((prev) => ({ ...prev, [meta.key]: value }));
                            handleChange(meta.key, value);
                          }}
                        />
                      );

                    case 'checkbox':
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!!filterValues[meta.key]}
                              onChange={(e) => {
                                const checked = e.target.checked;
                                setFilterValues((prev) => ({ ...prev, [meta.key]: checked }));
                                handleChange(meta.key, checked);
                              }}
                            />
                          }
                          label={meta.label}
                        />
                      );

                    case 'dropdown':
                      return (
                        <FormControl fullWidth>
                        <InputLabel>{meta.label}</InputLabel>
                        <Select
                          label={meta.label}
                          fullWidth
                          // size="small"
                          value={filterValues[meta.key] || ''}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFilterValues((prev) => ({ ...prev, [meta.key]: value }));
                            handleChange(meta.key, value);
                          }}
                          displayEmpty
                        >
                          {/* <MenuItem value="" disabled>
                            {meta.placeholder || 'Select an option'}
                          </MenuItem> */}
                          {meta.staticOptions?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                        </FormControl>
                      );

                    case 'multiSelect':
                      return (
                        <Autocomplete
                          multiple
                          options={dynamicOptions[meta.key] || []}
                          loading={loadingOptions[meta.key] || false}
                          getOptionLabel={(option) => getOptionLabel(meta, option)}
                          value={filterValues[meta.key] || []}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          // onChange={(event, value) => handleChange(meta.key, value)}
                          onChange={(event, value) => {
                            setFilterValues((prev) => ({ ...prev, [meta.key]: value })); // Set selected options as the value
                            handleChange(meta.key, value); // Pass the full array of selected objects
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={meta.label}
                              placeholder={meta.placeholder}
                              // size="small"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loadingOptions[meta.key] ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                              fullWidth
                            />
                          )}
                        />
                      );

                    case 'dateTime':
                      return (
                        <DateTimePicker
                          label={meta.label}
                          value={filterValues[meta.key] || null}
                          onChange={(newValue) => {
                            setFilterValues((prev) => ({ ...prev, [meta.key]: newValue }));
                            handleChange(meta.key, newValue);
                          }}
                          slots={{ textField: (params) => <TextField {...params}  fullWidth /> }}
                        />
                      );

                    default:
                      return null;
                  }
                })()}
              </Grid>
            ))}
          {/* Reset Button */}
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button
                variant="text"
                onClick={handleReset}
                sx={{ color: 'grey.800', mr: 2, fontWeight: 'bold', backgroundColor: '#ffffff' }}
              >
                <Iconify icon={'mdi:filter-variant-remove'} width={24} height={24} sx={{ mr: 1 }} /> Reset Filters
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};
export default FilterRenderer;
