import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  CardHeader,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Iconify from "src/components/Iconify";
import EmptyContent from "src/components/EmptyContent";

const InformationDash = ({ data }: any) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleGoToInfo = () => {
    navigate("/information");
  };

  return (
    <Grid container spacing={0}>
      {/* Card as a Horizontal Bar */}
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: theme.palette.information.main,
            borderRadius: 2,
            padding: 2,
            marginBottom: 3,
          }}
        >
            <Typography
              variant="h6"
              component="div"
              sx={{
                alignContent: "center",
                fontSize:17,
                backgroundColor: theme.palette.success.main,
                color: "white",
                padding: "4px 8px",
                borderRadius: "4px",
                textAlign: "center",
              }}
            > 
              Total Count: {data.infoCount}
            </Typography>

          <Typography
            variant="h6"
            component="div"
            color={theme.palette.information.dark}
            sx={{ alignContent: "center" }}
          >
            10 Most Recent Information
          </Typography>

          <Button variant="contained" color="success" onClick={handleGoToInfo}>
          <Iconify icon={"material-symbols:directions-alt"} width={25} height={25} /><span>Go to Information</span> 
          </Button>
        </Box>
      </Grid>

      {/* Table for Recent Information */}
      <Grid item xs={12}>
        <Card>
          {data.recentInfo?.length > 0 ? <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>AODM</strong>
                  </TableCell>
                  <TableCell>
                    <strong>LOCATION</strong>
                  </TableCell>
                  <TableCell>
                    <strong>INFORMATION</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.recentInfo?.map((row: any, index: any) =>
                  row && row.user_id && row.loc_details && row.info ? (
                    <TableRow key={index}>
                      <TableCell>{row.aodm ?? `ID: `+row.user_id}</TableCell>
                      <TableCell>{row.loc_details}</TableCell>
                      <TableCell>{row.info}</TableCell>
                    </TableRow>
                  ) : null
                )}
              </TableBody>
            </Table>
          </TableContainer> : <EmptyContent/>}
        </Card>
      </Grid>
    </Grid>
  );
};

export default InformationDash;
