import { setup } from "axios-cache-adapter";
import { useEffect, useMemo, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { API_BASE } from "../config.js";
import { getStorage} from "src/utils/storage";
import LoadingScreen from "src/components/LoadingScreen";

export const axiosInstance = setup({
  baseURL: API_BASE,
  validateStatus: function (status: any) {
    return status >= 200 && status < 300;
  },
  cache: {
    maxAge: 5 * 60 * 1000, // 5 minutes
    limit: false,
    exclude: {
      query: false,
    },
    debug: false,
  },
});

axiosInstance.interceptors.request.use((config: any) => {
  const token = getStorage("token") || getStorage("user")?.api_token;
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      alert(error.response.data?.messages)
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export const useAxiosLoader = () => {
  const [loadingCounter, setCounter] = useState(0);

  const interceptors = useMemo(() => {
    const inc = () => setCounter((prevCounter) => prevCounter + 1);
    const dec = () => setCounter((prevCounter) => prevCounter - 1);
    return {
      request: (config: any) => (inc(), config),
      response: (response: any) => (dec(), response),
      error: (error: any) => (dec(), Promise.reject(error)),
    };
  }, []); // Removed loadingCounter from the dependency array

  useEffect(() => {
    // add request interceptors
    const reqInterceptor = axiosInstance.interceptors.request.use(
      interceptors.request,
      interceptors.error
    );
    // add response interceptors
    const resInterceptor = axiosInstance.interceptors.response.use(
      interceptors.response,
      interceptors.error
    );
    return () => {
      // remove all intercepts when done
      axiosInstance.interceptors.request.eject(reqInterceptor);
      axiosInstance.interceptors.response.eject(resInterceptor);
    };
  }, [interceptors]);

  return [loadingCounter > 0];
};

export const useAxiosMessages = () => {
  const [messages, setMessages] = useState([]);
  const [type, setType] = useState("error");

  const interceptors = useMemo(() => {
    const inc = () => setMessages([]);
    const dec = (res: any, type: any) => {
      let messagesTemp = [];
      let typeTemp = type;
      switch (type) {
        case "success":
          messagesTemp = res.data?.messages?.length ? res.data.messages : [];
          break;
        case "error":
          messagesTemp = res.response?.data?.messages?.length
            ? res.response.data.messages
            : [];
          break;
      }
      /* Laravel Validation Errors */
      /* Since this will always be an ERROR status code, data would be in res.response.data */
      if (typeof res?.response?.data?.errors !== "undefined") {
        let laraValidationErrors: any = [];
        // for (let key in res.response.data.errors) {
        //   res.response.data.errors[key].forEach((err: any) => {
        //     laraValidationErrors.push(err);
        //   });
        // }
        // Using forEach to iterate through each key in res.response.data.errors
        Object.keys(res.response.data.errors).forEach((key) => {
        console.log(res.response.data.errors[key]);
        
        // Push each error into laraValidationErrors array
        laraValidationErrors.push(...res.response.data.errors[key]);
        });
        messagesTemp.push(...laraValidationErrors);
      }
      setType(typeTemp);
      setMessages(messagesTemp);
    };
    return {
      request: (config: any) => (inc(), config),
      response: (response: any) => (dec(response, "success"), response),
      error: (error: any) => (dec(error, "error"), Promise.reject(error)),
    };
  }, []); // create the interceptors

  useEffect(() => {
    // add request interceptors
    const reqInterceptor = axiosInstance.interceptors.request.use(
      interceptors.request,
      interceptors.error
    );
    // add response interceptors
    const resInterceptor = axiosInstance.interceptors.response.use(
      interceptors.response,
      interceptors.error
    );
    return () => {
      // remove all intercepts when done
      axiosInstance.interceptors.request.eject(reqInterceptor);
      axiosInstance.interceptors.response.eject(resInterceptor);
    };
  }, [interceptors]);

  return {
    type: type,
    messages: messages || [],
  };
};

export default function ApiLoading() {
  const [loading] = useAxiosLoader();
  const message = useAxiosMessages();

  const config = {
    useHtmlMessages: false,
  };

  let msgStr = "";

  if (message && message.messages && message.messages.length) {
    message.messages.forEach((m, i) => {
      //if(m.length!==i+1) msgStr += " • ";
      msgStr +=
        "success" === message.type
          ? "Success"
          : "Info" +
            // eslint-disable-next-line no-useless-concat
            `: ${m}` +
            "\n";
    });
    if (config.useHtmlMessages) msgStr += ` <br />`;
    // alert(msgStr);
  }
  return (
    <>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            zIndex: 9000,
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
          }}
        >
          {/* <CircularProgress /> */}
          <LoadingScreen/>
        </Box>
      )}
      {/*{
              showNoti &&
              <div className="flex flex-vert-center flex-horiz-space-bw notif-custom">
                  <div>
                      <div dangerouslySetInnerHTML={ { __html: msgStr }} />
                      {msg}
                  </div>
                  <div></div>
              </div>
          }*/}
    </>
  );
}
