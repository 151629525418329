import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  InputBase,
  Divider,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  getObservations,
  getInformation,
  getLocations,
  createLocation,
  updateLocation,
  deleteLocation,
} from "../../utils/api";
import {formatDateTime} from "src/utils/helperFunctions";
import useAlerts from "src/components/Alerts";
import DeleteDialog from "src/components/DeleteDialog";

interface Location {
  id: number;
  loc_name: string;
}
const Locations: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [locations, setLocations] = useState<any>([]);
  const [formValues, setFormValues] = useState({ loc_name: "" });
  const { showAlert } = useAlerts();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedColumnData, setSelectedColumnData] = useState<any>({});
  const navigate = useNavigate();
  const theme = useTheme();
  const [editingLocId, setEditingLocId] = useState(null);
  const [newLocName, setNewLocName] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [locToDelete, setLocToDelete] = useState(null);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleBackButton = () => {
    navigate("/configuration");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const fetchLocations = async () => {
    // let cacheBuster = new Date().getTime();
    try {
      const locations = await getLocations();
      setLocations(locations);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  const createNewLocation = async () => {
    try {
      const existingLocation = await getLocations();
      const locationExists = existingLocation.some(
        (loction: Location) => loction.loc_name.toLowerCase() === formValues.loc_name.toLowerCase()
      );
  
      if (locationExists) {
        showAlert("Location already exists", "warning");
        return;
      }

      const res = await createLocation(formValues.loc_name);
      if (
        res &&
        res.data &&
        res.data.message === "Location created successfully"
      ) {
        showAlert("Location created successfully", "success");
      } else {
        showAlert("Unexpected response, please try again", "warning");
      }
      // window.location.reload();
      formValues.loc_name = ""; // Clear the form
      fetchLocations(); // Refresh loc list
      handleClose();
    } catch (error) {
      showAlert("Failed to create location", "error");
    }
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };
  const filteredData = locations
    .filter((row: any) => {
      return Object.keys(selectedColumnData).every((field: string) => {
        const filterValues = selectedColumnData[field];
        if (!filterValues || filterValues.length === 0) return true;
        return filterValues.includes(row[field]?.toString());
      });
    })
    .filter((row: any) => {
      return Object.values(row).some(
        (value: any) =>
          value?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
          row.id?.toString().includes(searchQuery) // Include the id field in the search
      );
    });

  const handleDeleteClick = (catId: any) => {
    setLocToDelete(catId);
    setDeleteDialogOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setLocToDelete(null);
  };

  // const handleDelete = async () => {
  //   try {
      // await deleteLocation(locToDelete);
      // showAlert("Location deleted successfully", "success");
      // setLocations(locations.filter((loc: any) => loc.id !== locToDelete));
      // setDeleteDialogOpen(false);
      // setLocToDelete(null);
  //   } catch (error) {
  //     console.error("Error deleting location:", error);
  //     showAlert("Failed to delete location", "error");
  //   }
  // };
  const fetchObservations = async () => {
    try {
      const response = await getObservations();
      return response?.data;
    } catch (error) {
      console.error("Error fetching observations:", error);
    }
  };

  const fetchInformation = async () => {
    try {
      const response = await getInformation();
      return response?.data;
    } catch (error) {
      console.error("Error fetching observations:", error);
    }
  };

  const fetchLocationsMap = async (): Promise<{ [key: number]: string }> => {
    const locations:Location[] = await getLocations() 
    return locations.reduce((map, locations) => {
      map[locations.id] = locations.loc_name;
      return map;
    }, {} as { [key: number]: string });
  };
    const handleDelete = async () => {
      try {
        if (locToDelete === null) {
          showAlert("Location to delete is not selected", "error");
          return;
        }
        // Fetch observations and Location mapping
        const observations = await fetchObservations();
        const information = await fetchInformation()
        const locationsMap = await fetchLocationsMap(); 
        
        // Find the Location name associated with locToDelete
        const locationNameToDelete = locationsMap[locToDelete]; 
        // console.log("Locations",locationNameToDelete);

        if (!locationNameToDelete) {
          showAlert("Location not found", "error");
          return;
        }
    
        // Check if the Location is assigned to any observation
        const islocationAssigned = observations.some((obs: any) => obs.location === locationNameToDelete);
        const isLocationAssignedInInformation = information.some((info: any) => info.location === locationNameToDelete);

        // console.log("isLocationAssignedInInformation",isLocationAssignedInInformation);
    
        if (islocationAssigned || isLocationAssignedInInformation) {
          // Show alert if Location is assigned
          showAlert("Cannot delete Location because it is assigned to one or more requests.", "error");
        } else {
          // Proceed with deletion if the Location is not assigned
          await deleteLocation(locToDelete);
          showAlert("Location deleted successfully", "success");
          setLocations(locations.filter((loc: any) => loc.id !== locToDelete));
          setDeleteDialogOpen(false);
          setLocToDelete(null);
        }
      } catch (error) {
        console.error("Error handling delete operation:", error);
        showAlert("Failed to delete Location", "error");
      }
    };

  const handleEditClick = (locId: any, locName: any) => {
    setEditingLocId(locId);
    setNewLocName(locName);
  };

  const handleSaveClick = async (locId: any) => {
    try {
      await updateLocation(locId, newLocName);
      showAlert("Location updated successfully", "success");
      setLocations(
        locations.map((loc: any) =>
          loc.id === locId ? { ...loc, loc_name: newLocName } : loc
        )
      );
      setEditingLocId(null);
      setNewLocName("");
    } catch (error) {
      showAlert("Failed to update location", "error");
    }
  };

  const handleCancelClick = () => {
    setEditingLocId(null);
    setNewLocName("");
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginLeft: "70px",
          }}
          onClick={handleBackButton}
        >
          <Icon
            icon="mdi:arrow-left"
            style={{
              fontSize: 74,
              color: theme.palette.obs.main,
              marginRight: 8,
            }}
          />
          <Typography variant="h6" sx={{ color: theme.palette.obs.main }}>
            Configuration Home Page
          </Typography>
        </Box>
        <Button
          variant="contained"
          startIcon={<Icon icon="mdi:plus-circle-outline" />}
          sx={{
            backgroundColor: theme.palette.obs.main,
            color: "#fff",
            borderRadius: "8px",
            textTransform: "none",
          }}
          onClick={handleClickOpen}
        >
          Add Location
        </Button>
      </Box>

      <TableContainer
        component={Paper}
        style={{
          maxWidth: "88%",
          margin: "auto",
          padding: "0 20px",
          marginLeft: "80px",
          marginTop: "40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
          }}
        >
          <Typography variant="h6" color={theme.palette.obs.main}>
            <b>No. of Locations: {locations.length}</b>
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "2px 10px",
              width: "300px",
            }}
          >
            <Icon icon="mdi:search" />
            <InputBase
              placeholder="Search locations"
              inputProps={{ "aria-label": "search" }}
              sx={{ ml: 1, width: "100%" }}
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Box>
        </Box>
        <Divider />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.obs.main }}
              >
                ID
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.obs.main }}
              >
                LOCATION NAME
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.obs.main }}
              >
                CREATED AT
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.obs.main }}
              >
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((loc: any, index: any) => (
              <TableRow key={index}>
                <TableCell>{loc.id}</TableCell>
                <TableCell>
                  {editingLocId === loc.id ? (
                    <TextField
                      value={newLocName}
                      onChange={(e) => setNewLocName(e.target.value)}
                      autoFocus
                    />
                  ) : (
                    loc.loc_name
                  )}
                </TableCell>
                <TableCell>{formatDateTime(loc.created_at)}</TableCell>
                <TableCell
                  sx={{ display: "flex", justifyContent: "center", gap: 2 }}
                >
                  {editingLocId === loc.id ? (
                    <>
                      <IconButton onClick={() => handleSaveClick(loc.id)}>
                        <Icon
                          icon="mdi:check"
                          style={{ fontSize: 24, color: "green" }}
                        />
                      </IconButton>
                      <IconButton onClick={handleCancelClick}>
                        <Icon
                          icon="mdi:close"
                          style={{ fontSize: 24, color: "red" }}
                        />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton
                        onClick={() => handleEditClick(loc.id, loc.loc_name)}
                      >
                        <Icon
                          icon="mdi:pencil"
                          style={{ fontSize: 24, color: "#5a6e8c" }}
                        />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteClick(loc.id)}>
                        <Icon
                          icon="mdi:delete"
                          style={{ fontSize: 24, color: "#e53935" }}
                        />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <DeleteDialog
        open={deleteDialogOpen}
        onClose={handleCancelDelete}
        onConfirm={handleDelete}
        title="Confirm Deletion"
        description="Are you sure you want to delete this location?"
      />

      <Dialog
        open={openDialog}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 20,
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
          Create Location
        </DialogTitle>
        <DialogContent>
          <Box sx={{ p: 1 }}>
            <TextField
              label="Location Name"
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              name="loc_name"
              onChange={handleChange}
              value={formValues.loc_name}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.obs.main,
                  color: "#fff",
                  borderRadius: "8px",
                  textTransform: "none",
                  padding: "6px 32px",
                  fontSize: "18px",
                }}
                onClick={createNewLocation}
              >
                CREATE LOCATION
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Locations;
