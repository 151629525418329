// @mui
import { Box, Typography, Stack, Button } from '@mui/material';
// assets
// import { UploadIllustration } from '../../assets';

// ----------------------------------------------------------------------

export default function BlockContent() {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100%', // Ensures it takes full width
        height: '25vh', // Ensures it takes full viewport height
        textAlign: 'center',
        backgroundColor: '#E0E0F8', // Light blue background
        color: '#333', // Dark text color
        m: 0, // Removes margin
        p: 0, // Removes padding
      }}
    >
      <Typography gutterBottom variant="h6">
        Drag and drop image here or
      </Typography>
      <Button variant="contained" component="span" sx={{ mt: 1 }}>
        UPLOAD IMAGE
      </Button>
    </Stack>
  );
}
