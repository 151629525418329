import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Card,
  Grid,
  Box,
  CardContent,
} from "@mui/material";
import {
  MostReportedTasks,
  TrendChart,
  StatusBreakDownChart,
} from "./TasksAnalytics";
import { useTheme } from "@mui/material/styles";
import { axiosInstance as axios } from "src/utils/axios";
import { useAuth } from "src/context/authContext";

const SnagsDash = ({ requestSummaryData }: any ) => {
  const theme = useTheme();
  const { user } = useAuth();
  const [trendData, setTrendData] = useState<any>([]);

  // Function to fetch trend data
  const fetchTrendData = async () => {
    try {
      const org_ids = user?.hotels.map((hotel: any) => hotel.id);
      const response = await axios.get("/dashboard/task-trends", {
        params: {
          org: org_ids,
          aodm: true,
        },
      });
      setTrendData(response.data);
    } catch (error) {
      console.error("Error fetching trend data:", error);
    }
  };

  // Fetch trend data when the component mounts or when requestSummaryData changes
  useEffect(() => {
    fetchTrendData();
  }, []);

  // Status Card component for displaying status counts
  const StatusCard = ({ status, count }: { status: string; count: number }) => {
    const colorMap: { [key: string]: string } = {
      Pending: theme.palette.warning.main,
      Parked: theme.palette.primary.main,
      Rejected: theme.palette.error.main,
      Closed: theme.palette.success.dark,
      Total: "#002D62",
    };
    const cardColor = colorMap[status] || theme.palette.grey[500];

    return (
      <Card
        variant="outlined"
        sx={{
          textAlign: "center",
          minHeight: 140,
          width: "100%",
          flex: 1,
          maxWidth: 200,
          m: 1,
        }}
      >
        <CardContent>
          <Typography variant="h6" sx={{ mb: 1 }}>
            {status}
          </Typography>
          <Card
            sx={{
              backgroundColor: cardColor,
              borderRadius: 5,
              padding: 0.5,
              width: "100%",
              display: "inline-block",
              color: "white",
            }}
          >
            <Typography variant="h4">{count}</Typography>
          </Card>
        </CardContent>
      </Card>
    );
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        {/* Task Breakdown by Status Cards */}
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ ml: "5%", mr: "5%" }}
          >
            {requestSummaryData?.status_count && (
              <>
                <StatusCard
                  status="Total"
                  count={requestSummaryData.total_count}
                />
                <StatusCard
                  status="Pending"
                  count={requestSummaryData.status_count.pending}
                />
                <StatusCard
                  status="Closed"
                  count={requestSummaryData.status_count.closed}
                />
                <StatusCard
                  status="Parked"
                  count={requestSummaryData.status_count.parked}
                />
                <StatusCard
                  status="Rejected"
                  count={requestSummaryData.status_count.rejected}
                />
              </>
            )}
          </Box>
        </Grid>

        {/* Status Breakdown Chart */}
        <Grid item xs={12} md={5} lg={5}>
          {requestSummaryData?.status_count && (
            <StatusBreakDownChart
              statusCount={requestSummaryData.status_count}
              totalCount={requestSummaryData.total_count}
            />
          )}
        </Grid>

        {/* Most Reported Tasks */}
        <Grid item xs={12} md={7} lg={7}>
          {requestSummaryData?.status_count && (
            <MostReportedTasks data={requestSummaryData.mostReportedTasks} />
          )}
        </Grid>

        {/* Trend of Tasks Created vs. Closed */}
        <Grid item xs={12}>
          {trendData && <TrendChart data={trendData} />}
        </Grid>
      </Grid>
    </Container>
  );
};

export default SnagsDash;
