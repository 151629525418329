import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete, TextField, Box } from '@mui/material';

type FilterProps = {
  options: any[];
  field: string;
  headerName: string;
  selectedColumnData: { [key: string]: string[] };
  setSelectedColumnData: React.Dispatch<React.SetStateAction<{ [key: string]: string[] }>>;
  fetchObservations: (filters: { [key: string]: string }) => void;
  formatFilters: (selectedColumnData: { [key: string]: string[] }) => { [key: string]: string };
};

const Filter: React.FC<FilterProps> = ({
  // data,
  // columns,
  // selectedColumnData,
  // setSelectedColumnData,
  options,
  field,
  headerName,
  selectedColumnData,
  setSelectedColumnData,
  fetchObservations,
  formatFilters
}) => {
  const autocompleteRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [visibleAutocomplete, setVisibleAutocomplete] = useState<{ [key: string]: boolean }>({});

  const handleAutocompleteChange = (
    event: React.ChangeEvent<{}>,
    value: any[],
    field: string
  ) => {
    const updatedSelectedColumnData = {
      ...selectedColumnData,
      [field]: value.map(option => option.id) // Extracting id properties
    };
    setSelectedColumnData(updatedSelectedColumnData);
    const filters = formatFilters(updatedSelectedColumnData);
    fetchObservations(filters);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      Object.keys(autocompleteRefs.current).forEach((field) => {
        if (
          visibleAutocomplete[field] &&
          autocompleteRefs.current[field] &&
          !autocompleteRefs.current[field]!.contains(event.target as Node)
        ) {
          setVisibleAutocomplete((prevState) => ({ ...prevState, [field]: false }));
        }
      });
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [visibleAutocomplete]);

  const getOptionLabel = (option: any) => {    
    return option.loc_name || option.category_name || option.dept_name || option.name || option.toString();
  };

  const getSelectedOptions = (field: string) => {
    const selectedIds = selectedColumnData[field] || [];
    return options.filter(option => selectedIds.includes(option.id));
  };

  return (
    <>
      <Box key={field}>
      <Autocomplete
        multiple
        options={options}
       // getOptionLabel={(option) => option.loc_name || option.category_name || option.dept_name  ||  option.name || option}
       getOptionLabel={getOptionLabel} 
       style={{ width: 130 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={`${headerName}`}
            variant="standard"
            InputLabelProps={{ style: { color: '#7A96AF' } }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: {
                border: 'none',
                outline: 'none',
                boxShadow: 'none',
              },
            }}
          />
        )}
        onChange={(event, value) =>
          handleAutocompleteChange(event, value, field)
        }
        value={getSelectedOptions(field)}
        disableClearable
      />
    </Box>
    </>
  );
};

export default Filter;