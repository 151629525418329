import React, { useState, useEffect } from "react";
import {
  Paper,
  Button,
  Dialog,
  DialogContent,
  TextField,
  MenuItem,
  IconButton,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,

} from "@mui/material";
import { Icon } from "@iconify/react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import {
  getLocations,
  getCategories,
  getDepartments,
  createObservation,
} from "../../utils/api";
import useAlerts from "src/components/Alerts";
import { useNotifications } from "src/context/NotificationsContext";
import { LoadingButton } from '@mui/lab';


const CreateObservation = ({ open, onClose, fetchObservations }: any) => {
  const { refreshNotifications } = useNotifications();
  const { showAlert } = useAlerts();
  const [locations, setLocations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const initialFormValues = {
    location: "",
    category: "",
    details: "",
    department: "",
    loc_name: "",
    locationdetails:"",
  };
  const [formValues, setFormValues] = useState(initialFormValues);

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const [dragging, setDragging] = useState(false);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
    const files = Array.from(e.dataTransfer.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "files") {
      //setSelectedFiles(Array.from(event.target.files || []));
      const files = Array.from(event.target.files || []);
      setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
      event.target.value = ""; // Clear the input so the same file can be selected again
    } else {
      setFormValues({ ...formValues, [event.target.name]: event.target.value });
    }
  };

  const handleRemoveFile = (index: number) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const fetchLocations = async () => {
    try {
      const locations = await getLocations();
      setLocations(locations);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const categories = await getCategories();
      setCategories(categories);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchDepartments = async () => {
    try {
      const departments = await getDepartments();
      setDepartments(departments);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const formatFileSize = (size: number) => {
    if (size < 1024) return `${size} bytes`;
    const kb = size / 1024;
    if (kb < 1024) return `${kb.toFixed(2)} KB`;
    return `${(kb / 1024).toFixed(2)} MB`;
  };

  const handleSubmit = async () => {
     // Prevent multiple submissions
    if (loading) return;

    // Set loading state immediately to disable the button
    setLoading(true);

    // Check if any required field is missing
    if (!formValues.locationdetails.trim()) {
      showAlert("Location details are required", "warning");
      setLoading(false);
      return;
    } else if (!formValues.category) {
      showAlert("Category is required", "warning");
      setLoading(false);
      return;
    } else if (!formValues.details) {
      showAlert("Observation Details are required", "warning");
      setLoading(false);
      return;
    } else if (!formValues.department) {
      showAlert("Department is required", "warning");
      setLoading(false);
      return;
    } else if (selectedFiles.length === 0) {
      showAlert("Please upload at least one image", "warning");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("observation", formValues.details);
    formData.append("location_id", formValues.location);
    formData.append("category_id", formValues.category);
    formData.append("department_id", formValues.department);
    formData.append("loc_details", formValues.locationdetails);
    
    selectedFiles.forEach((file) => {
      formData.append("files[]", file);
    });

    try {
      // Simulate a successful image upload message
      showAlert("Images uploaded successfully", "success");

      // Submit the form data
      await createObservation(formData);
      showAlert("Observation created successfully", "success");
      setFormValues(initialFormValues); // Clear the form
      setSelectedFiles([]); // Clear selected files
      fetchObservations(); // Refresh Observations list
      refreshNotifications();
      onClose();
      setLoading(false);

      // window.location.reload();
    } catch (error) {
      showAlert("Failed to create observation", "error");
      console.error("Error creating observation:", error);
    }
  };

 

  useEffect(() => {
    fetchLocations();
    fetchCategories();
    fetchDepartments();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 20,
          overflow: "visible",
        },
      }}
    >
      <Box sx={{ p: 2, width: "100%", ml: -2 }}>
        <Typography
          variant="h5"
          sx={{
            color: "#231F20",
            mb: 2,
            textAlign: "center",
            fontWeight: "bold",
            width: "100%",
          }}
        >
          Create New Observation
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "grey[500]",
          }}
        >
          <Icon icon="mdi:close" />
        </IconButton>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Card style={{ borderRadius: "20px", height: "100%" }}>
                <CardContent>
                  {/* <TextField
                    select
                    label="Location"
                    name="location"
                    value={formValues.location}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Select location"
                    sx={{ marginBottom: "16px" }}
                  >
                    {locations.map((location: any) => (
                      <MenuItem key={location.id} value={location.id}>
                        {location.loc_name}
                      </MenuItem>
                    ))}
                  </TextField> */}
                  <TextField
                    name="locationdetails"
                    label="Location"
                    fullWidth
                    sx={{ marginBottom: "16px" }}
                    onChange={handleChange}
                    value={formValues.locationdetails}
                    required
                  />

                  {/* <TextField
                    name="locationdetails"
                    label="Additional Location Details"
                    fullWidth
                    sx={{ marginBottom: "16px" }}
                    onChange={handleChange}
                    value={formValues.locationdetails}
                  /> */}
                  <TextField
                    select
                    label="Category"
                    name="category"
                    value={formValues.category}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Select category"
                    sx={{ marginBottom: "16px" }}
                  >
                    {categories.map((category: any) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.category_name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    label="Observation Details"
                    name="details"
                    value={formValues.details}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="dense"
                    multiline
                    rows={4}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Enter observation details here"
                    sx={{ marginBottom: "16px" }}
                  />
                  <TextField
                    select
                    label="Department"
                    name="department"
                    value={formValues.department}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="dense"
                    placeholder="Select department"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ marginBottom: "16px" }}
                  >
                    {departments.map((department: any) => (
                      <MenuItem key={department.id} value={department.id}>
                        {department.dept_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card style={{ borderRadius: "16px" }}>
                <CardContent>
                  <div
                    style={{
                      border: dragging ? "2px solid #000" : "1px dashed #ccc",
                      backgroundColor: "#E6EDF4",
                      borderRadius: "16px",
                      height: "100px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      textAlign: "center",
                      padding: "20px",
                      position: "relative",
                    }}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  >
                    {/* <Icon icon="mdi:upload" width="48" height="48" /> */}
                    <Icon
                      icon="flat-color-icons:add-image"
                      width="48"
                      height="48"
                    />
                    <Typography
                      variant="body1"
                      style={{ marginBottom: "10px" }}
                    >
                      Drag and drop image here or{" "}
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        browse
                      </span>
                    </Typography>
                    <input
                      accept="image/*"
                      id="file-upload"
                      type="file"
                      name="files"
                      multiple
                      onChange={handleChange}
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        cursor: "pointer",
                      }}
                    />
                    <Button
                      variant="contained"
                      component="label"
                      style={{
                        backgroundColor: theme.palette.obs.main,
                        color: "#fff",
                        borderRadius: "8px",
                        textTransform: "none",
                      }}
                    >
                      UPLOAD IMAGE
                      <input
                        accept="image/*"
                        id="file-upload"
                        type="file"
                        name="files"
                        multiple
                        onChange={handleChange}
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                        }}
                      />
                    </Button>
                  </div>
                  <Typography sx={{ fontSize: '0.875rem', mt:4 }}>
                    All Image file types supported. Max file size: 6 MB
                  </Typography>
                  {selectedFiles.length > 0 && (
                    <Box mt={2} sx={{ maxHeight: "200px", overflowY: "auto" }}>
                      {selectedFiles.map((image, index) => (
                        <Paper
                          key={index}
                          elevation={1}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            p: 1,
                            mb: 1,
                          }}
                        >
                          <Typography>
                            {image.name} ({formatFileSize(image.size)})
                          </Typography>
                          <IconButton
                            onClick={() => handleRemoveFile(index)}
                            size="small"
                            color="error"
                          >
                            <Icon icon="tabler:trash" />
                          </IconButton>
                        </Paper>
                      ))}
                    </Box>
                  )}
                </CardContent>
              </Card>
              <Box
                mt={15}
                mr={8}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <LoadingButton
                  // type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  loading={loading} // Disable the button when loading
                  // style={{
                  //   backgroundColor: theme.palette.obs.main,
                  //   color: "#fff",
                  //   borderRadius: "8px",
                  //   textTransform: "none",
                  //   padding: "16px 32px",
                  //   fontSize: "18px",
                  // }}
                  sx={{
                    mt: 1,
                    backgroundColor: theme.palette.obs.main, // Adjust this color to match your brand's exact blue
                    color: 'white',
                    ':hover': {
                        backgroundColor: '#1a237e', // Adjust hover color to a suitable shade
                    },
                    height: 48,
                    borderRadius: 1,
                    fontWeight: 'bold',
                    fontSize: '0.875rem',
                    textTransform: 'uppercase',
                    justifyContent: 'center',
                    padding: '0 30px',
                    boxShadow: 'none',
                }}
                >
                  CREATE OBSERVATION
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default CreateObservation;
