import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  InputBase,
  Divider,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  getObservations,
  getDepartments,
  createDepartment,
  deleteDepartment,
  updateDepartment,
} from "../../utils/api";
import {formatDateTime} from "src/utils/helperFunctions";
import useAlerts from "src/components/Alerts";
import DeleteDialog from "src/components/DeleteDialog";

interface Department {
  id: number;
  dept_name: string;
}
const Departments: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [departments, setDepartments] = useState<any>([]);
  const [formValues, setFormValues] = useState({ dept_name: "" });
  const navigate = useNavigate();
  const { showAlert } = useAlerts();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedColumnData, setSelectedColumnData] = useState<any>({});
  const theme = useTheme();
  const [editingDeptId, setEditingDeptId] = useState<number | null>(null);
  const [newDeptName, setNewDeptName] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deptToDelete, setDeptToDelete] = useState(null);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleBackButton = () => {
    navigate("/configuration");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const fetchDepartments = async () => {
    // let cacheBuster = new Date().getTime();
    try {
      const departments = await getDepartments();
      setDepartments(departments);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartments().then((r) => console.log("Departments fetched"));
  }, []);

  const createNewDepartment = async () => {
    try {
      const existingDepartments = await getDepartments();
      const departmentExists = existingDepartments.some(
        (department: Department) => department.dept_name.toLowerCase() === formValues.dept_name.toLowerCase()
      );
  
      if (departmentExists) {
        showAlert("Department already exists", "warning");
        return;
      }

      await createDepartment(formValues.dept_name);
      showAlert("Department created successfully", "success");
      // window.location.reload();
      formValues.dept_name = ""; // Clear the form
      fetchDepartments(); // Refresh dept list
      handleClose(); // Close dialog
    } catch (error) {
      console.error("Error creating department:", error);
      showAlert("Failed to create department", "error");
    }
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = departments
    .filter((row: any) => {
      return Object.keys(selectedColumnData).every((field: string) => {
        const filterValues = selectedColumnData[field];
        if (!filterValues || filterValues.length === 0) return true;
        return filterValues.includes(row[field]?.toString());
      });
    })
    .filter((row: any) => {
      return Object.values(row).some(
        (value: any) =>
          value?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
          row.id?.toString().includes(searchQuery) // Include the id field in the search
      );
    });

  const handleDeleteClick = (catId: any) => {
    setDeptToDelete(catId);
    setDeleteDialogOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setDeptToDelete(null);
  };

  // const handleDelete = async () => {
  //   try {
      // await deleteDepartment(deptToDelete);
      // showAlert("Department deleted successfully", "success");
      // setDepartments(
      //   departments.filter((dept: any) => dept.id !== deptToDelete)
      // );
      // setDeleteDialogOpen(false);
      // setDeptToDelete(null);
  //   } catch (error) {
  //     console.error("Error deleting department:", error);
  //     showAlert("Failed to delete department", "error");
  //   }
  // };
  const fetchObservations = async () => {
    try {
      const response = await getObservations();
      return response?.data;
    } catch (error) {
      console.error("Error fetching observations:", error);
    }
  };
  
  const fetchDepartmentsMap = async (): Promise<{ [key: number]: string }> => {
    const departments: Department[] = await getDepartments() // Adjust the URL as needed
    return departments.reduce((map, department) => {
      map[department.id] = department.dept_name;
      return map;
    }, {} as { [key: number]: string });
  };
    const handleDelete = async () => {
      try {
        if (deptToDelete === null) {
          showAlert("Department to delete is not selected", "error");
          return;
        }
        // Fetch observations and department mapping
        const observations = await fetchObservations();
        const departmentsMap = await fetchDepartmentsMap(); 

        // Find the department name associated with deptToDelete
        const departmentNameToDelete = departmentsMap[deptToDelete]; 

        if (!departmentNameToDelete) {
          showAlert("Department not found", "error");
          return;
        }
    
        // Check if the department is assigned to any observation
        const isdepartmentAssigned = observations.some((obs: any) => obs.department === departmentNameToDelete);
        // console.log("isdepartmentAssigned",isdepartmentAssigned);
    
        if (isdepartmentAssigned) {
          // Show alert if department is assigned
          showAlert("Cannot delete department because it is assigned to one or more requests.", "error");
        } else {
          // Proceed with deletion if the department is not assigned
          await deleteDepartment(deptToDelete);
          showAlert("Department deleted successfully", "success");
          setDepartments(
            departments.filter((dept: any) => dept.id !== deptToDelete)
          );
          setDeleteDialogOpen(false);
          setDeptToDelete(null);
        }
      } catch (error) {
        console.error("Error handling delete operation:", error);
        showAlert("Failed to delete department", "error");
      }
    };

  const handleEditClick = (deptId: number, deptName: string) => {
    setEditingDeptId(deptId);
    setNewDeptName(deptName);
  };

  const handleSaveClick = async (deptId: number) => {
    try {
      await updateDepartment(deptId, newDeptName);
      showAlert("Department updated successfully", "success");
      setDepartments(
        departments.map((dept: any) =>
          dept.id === deptId ? { ...dept, dept_name: newDeptName } : dept
        )
      );
      setEditingDeptId(null);
      setNewDeptName("");
    } catch (error) {
      showAlert("Failed to update department", "error");
    }
  };

  const handleCancelClick = () => {
    setEditingDeptId(null);
    setNewDeptName("");
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginLeft: "70px",
          }}
          onClick={handleBackButton}
        >
          <Icon
            icon="mdi:arrow-left"
            style={{
              fontSize: 74,
              color: theme.palette.obs.main,
              marginRight: 8,
            }}
          />
          <Typography variant="h6" sx={{ color: theme.palette.obs.main }}>
            Configuration Home Page
          </Typography>
        </Box>
        <Button
          variant="contained"
          startIcon={<Icon icon="mdi:plus-circle-outline" />}
          sx={{
            backgroundColor: theme.palette.obs.main,
            color: "#fff",
            borderRadius: "8px",
            textTransform: "none",
          }}
          onClick={handleClickOpen}
        >
          Add Department
        </Button>
      </Box>

      <TableContainer
        component={Paper}
        style={{
          maxWidth: "88%",
          margin: "auto",
          padding: "0 20px",
          marginLeft: "80px",
          marginTop: "40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
          }}
        >
          <Typography variant="h6" color={theme.palette.obs.main}>
            <b>No. of Departments: {departments.length}</b>
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "2px 10px",
              width: "300px",
            }}
          >
            <Icon icon="mdi:search" />
            <InputBase
              placeholder="Search departments"
              inputProps={{ "aria-label": "search" }}
              sx={{ ml: 1, width: "100%" }}
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Box>
        </Box>
        <Divider />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.obs.main }}
              >
                ID
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.obs.main }}
              >
                DEPARTMENT NAME
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.obs.main }}
              >
                CREATED AT
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.obs.main }}
              >
                NO. OF USERS
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  color: theme.palette.obs.main,
                }}
              >
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((dept: any, index: any) => (
              <TableRow key={index}>
                <TableCell>{dept.id}</TableCell>
                <TableCell>
                  {editingDeptId === dept.id ? (
                    <TextField
                      value={newDeptName}
                      onChange={(e) => setNewDeptName(e.target.value)}
                      autoFocus
                    />
                  ) : (
                    dept.dept_name
                  )}
                </TableCell>
                <TableCell>{formatDateTime(dept.created_at)}</TableCell>
                <TableCell>{dept.users_count}</TableCell>
                <TableCell
                  sx={{ display: "flex", justifyContent: "center", gap: 2 }}
                >
                  {editingDeptId === dept.id ? (
                    <>
                      <IconButton onClick={() => handleSaveClick(dept.id)}>
                        <Icon
                          icon="mdi:check"
                          style={{ fontSize: 24, color: "green" }}
                        />
                      </IconButton>
                      <IconButton onClick={handleCancelClick}>
                        <Icon
                          icon="mdi:close"
                          style={{ fontSize: 24, color: "red" }}
                        />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton
                        onClick={() => handleEditClick(dept.id, dept.dept_name)}
                      >
                        <Icon
                          icon="mdi:pencil"
                          style={{ fontSize: 24, color: "#5a6e8c" }}
                        />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteClick(dept.id)}>
                        <Icon
                          icon="mdi:delete"
                          style={{ fontSize: 24, color: "#e53935" }}
                        />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <DeleteDialog
        open={deleteDialogOpen}
        onClose={handleCancelDelete}
        onConfirm={handleDelete}
        title="Confirm Deletion"
        description="Are you sure you want to delete this department?"
      />

      <Dialog
        open={openDialog}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 20,
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
          <>Create Department</>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ p: 1 }}>
            <TextField
              label="Department Name"
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              name="dept_name"
              onChange={handleChange}
              value={formValues.dept_name}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.obs.main,
                  color: "#fff",
                  borderRadius: "8px",
                  textTransform: "none",
                  padding: "6px 32px",
                  fontSize: "18px",
                }}
                onClick={createNewDepartment}
              >
                CREATE DEPARTMENT
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Departments;
