import React from 'react'
import HeaderAppBar from './Header'
import Navbar from './Navbar'

const Layout = () => {
  return (
    <>
      <HeaderAppBar/>
      <Navbar/>
    </>
  )
}

export default Layout
