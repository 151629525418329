export const getEnvVar = (keyName, defaultVal) => {
  const envVar = process.env[keyName] || process.env[`REACT_APP_${keyName}`];
  return envVar !== undefined ? envVar : defaultVal;
};

// [DEPLOYMENT BUILD]: AODM WEB *************************************** Edit BUILD_FOR and run build
export const BUILDING_FOR = getEnvVar("BUILD_FOR", "AODM")?.toUpperCase(); // Values: AODM

export const CONFIGS = {
  AODM: {
    /* API base (generic for all 'products') */
    API_BASE: "https://checkinn.co/api/v1",
    API_BASE_LOCAL_DEV: "http://cinext.localhost/api/v1",
    API_BASE_STAGING_DEV: "https://staging.checkinn.co/api/v1",
    /* URL base (For AODM) */
    URL_BASE: "https://aodm.teamvesta.com",
    URL_BASE_LOCAL_DEV: "http://localhost:4000",
  },
};

export const PROFILE_IMG_FALLBACK =
  "https://checkinn.co/app/assets/images/icons/userDefaultIcon.png";

export const isProduction = process.env.NODE_ENV === "production" ?? true;
export const isStaging = window?.location?.hostname?.includes('staging') ?? false;

export const API_BASE = isStaging
  ? CONFIGS[BUILDING_FOR].API_BASE_STAGING_DEV
  : isProduction
  ? CONFIGS[BUILDING_FOR].API_BASE
  : CONFIGS[BUILDING_FOR].API_BASE_LOCAL_DEV;

export const URL_BASE_FBS = isStaging
  ? CONFIGS[BUILDING_FOR].URL_BASE_STAGING_DEV
  : isProduction
  ? CONFIGS[BUILDING_FOR].URL_BASE
  : CONFIGS[BUILDING_FOR].URL_BASE_LOCAL_DEV;

export const COMPANY_BRAND_NAME = "Vesta";

export const getProductMetaObj = (hostname = window.location.hostname) => {
  return {
    product: getEnvVar("PRODUCT", "unknown"),
    productName: getEnvVar("PRODUCT_NAME", "Unknown Product"),
    companyBrandName: getEnvVar("COMPANY_BRAND_NAME", "Vesta"),
  };
};

export const REPORT_BASE_URL = isProduction ? "https://checkinn.co" : "http://cinext.localhost"
