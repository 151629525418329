import { ReactNode } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

type Props = {
  children: ReactNode;
};

const theme = createTheme({
  palette: {
    obs: {
      //old code #84A7C6 
      main: "#7A96AF",
      light: "#B5CADC",
      contrastText: "#ffffff",
    },
    information: {
      main: "#84AC85",  // light green
      light: "#B5CDB5",
      dark:"#425642", // dark green
      contrastText: "#ffffff",
    },
    snags: {
      main: "#1F285A",  // dark blue
      light: "#4B527A",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

const CustomTheme = ({ children }: Props) => {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

export default CustomTheme;
