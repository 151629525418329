import { useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  MenuItem,
  Select,
  FormControl,
  TextField,
  Checkbox,
  ListItemText,
  InputLabel,
  Tooltip,
  Autocomplete,
  Avatar,
  ListItem,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { format, parse, isValid } from "date-fns"; // Import isValid to check date validity
import { useAuth } from "src/context/authContext";
import Iconify from "src/components/Iconify";
import { getDepartments, getCategories, getUsersList } from "src/utils/api";
import { axiosInstance as axios } from "src/utils/axios";

export default function DashFilters(props: any) {
  const { user } = useAuth();
  const { entity } = props;

  const initialFilterValues = {
    start_date: null,
    end_date: null,
    department: [],
    dept: [],
    category: [],
    date_range: "today",
    aodm_id: [],
  };

  const [filters, setLocalFilters] = useState<any>(initialFilterValues);

  const [selectedDateRange, setSelectedDateRange] = useState<any>("today");
  const [customDateRange, setCustomDateRange] = useState(false);
  const [departments, setDepartments] = useState<any[]>([]);
  const [snagsDepartments, setSnagsDepartments] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [appliedDateRange, setAppliedDateRange] = useState("today");
  const [aodmList, setAodmList] = useState([]);

  const fetchCategories = async () => {
    try {
      const categories = await getCategories();
      setCategories(categories);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchUsers = async () => {
    let cacheBuster = new Date().getTime();
    try {
      const users = await getUsersList(cacheBuster);
      setAodmList(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchDepartments = async () => {
    try {
      // Fetch data for snags and aodm departments
      const res = await axios.get(`/hotel/${user?.hotel_id}/desks`);
      const snagsDept = res.data.map((dept: any) => ({
        id: dept.id,
        name: dept.name,
      }));
      const dept = await getDepartments();

      setSnagsDepartments(snagsDept);
      setDepartments(dept);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  // Get Departments List
  useEffect(() => {
    fetchDepartments();
    fetchUsers();
    fetchCategories();
  }, []);

  const RenderCustomOption = (option: any) => {
    return (
      <>
        <Avatar src={option.avatar} />
        <Box sx={{ ml: 1 }}>
          <Box>{option.name}</Box>
          <Box sx={{ fontSize: "small", color: "grey" }}>
            {option.username} &#183; {option.email}
          </Box>
        </Box>
      </>
    );
  };

  const handleDateRangeChange = (event: any, newRange: string | null) => {
    // Check if no option is selected or if custom is selected
    if (newRange === null) {
      setSelectedDateRange("today"); // Reset to Today
      setCustomDateRange(false);
    } else {
      setSelectedDateRange(newRange);
      setCustomDateRange(newRange === "custom");

      // Additional logic for setting filters based on selected range
      let dateRangeValue = "";
      if (newRange === "custom") {
        dateRangeValue = "custom";
      } else if (newRange === "today") {
        dateRangeValue = "today";
      } else if (newRange === "7days") {
        dateRangeValue = "last_7_days";
      } else if (newRange === "30days") {
        dateRangeValue = ""; // API Default to 30 Days
      }

      setLocalFilters((prevFilters: any) => ({
        ...prevFilters,
        date_range: dateRangeValue,
      }));
    }
  };

  const handleCategoryChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setLocalFilters((prevFilters: any) => ({
      ...prevFilters,
      category: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleDeptChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setLocalFilters({
      ...filters,
      department: typeof value === "string" ? value.split(",") : value,
    });
  };

  const handleSnagsDeptChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setLocalFilters({
      ...filters,
      dept: typeof value === "string" ? value.split(",") : value,
    });
  };

  const handleStartDateChange = (date: any) => {
    // Ensure we only store a valid dayjs object or null
    const validDate = dayjs(date);
    setLocalFilters((prevFilters: any) => ({
      ...prevFilters,
      start_date: validDate.isValid() ? validDate : null,
    }));
  };

  const handleEndDateChange = (date: any) => {
    // Ensure we only store a valid dayjs object or null
    const validDate = dayjs(date);
    setLocalFilters((prevFilters: any) => ({
      ...prevFilters,
      end_date: validDate.isValid() ? validDate : null,
    }));
  };

  const resetFilters = () => {
    setSelectedDateRange("today");
    setCustomDateRange(false);
    setAppliedDateRange("today");
    setLocalFilters(initialFilterValues);
    props.setFilters(initialFilterValues);
  };

  const applyFilters = () => {
    setAppliedDateRange(filters.date_range);

    // Format the dates if they exist
    const formattedFilters = {
      ...filters,
      start_date: filters.start_date
        ? format(new Date(filters.start_date), "yyyy-MM-dd HH:mm:ss")
        : "",
      end_date: filters.end_date
        ? format(new Date(filters.end_date), "yyyy-MM-dd HH:mm:ss")
        : "",
    };

    props.setFilters(formattedFilters);
  };

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      {/* Row 1: Organization and Department Selector */}
        <Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            {" "}
            {/* AODM Category Dropdown */}
            {entity != "snags" && entity != "information" && (
              <Grid item xs={4}>
                {" "}
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="category-label">Category</InputLabel>
                  <Select
                    multiple
                    labelId="category-label"
                    label="Category"
                    value={filters.category}
                    onChange={handleCategoryChange}
                    renderValue={(selected) =>
                      selected
                        .map(
                          (id: any) =>
                            categories.find(
                              (category: any) => category.id === id
                            )?.category_name
                        )
                        .join(", ")
                    }
                  >
                    {categories.map((category: any) => (
                      <MenuItem key={category.id} value={category.id}>
                        <Checkbox
                          checked={filters.category.indexOf(category.id) > -1}
                        />
                        <ListItemText primary={category.category_name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {/* AODM Department Dropdown */}
            {entity != "snags" && entity != "information" && (
              <Grid item xs={4}>
                {" "}
                <FormControl fullWidth>
                  <InputLabel id="department-label">Department</InputLabel>
                  <Select
                    multiple
                    label="Department"
                    labelId="department-label"
                    value={filters.department}
                    onChange={handleDeptChange}
                    renderValue={(selected) =>
                      selected
                        .map(
                          (id: any) =>
                            departments.find((dept) => dept.id === id)
                              ?.dept_name
                        )
                        .join(", ")
                    }
                  >
                    {departments.map((dept: any) => (
                      <MenuItem key={dept.id} value={dept.id}>
                        <Checkbox
                          checked={filters.department.indexOf(dept.id) > -1}
                        />
                        <ListItemText primary={dept.dept_name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {/* Snags Department Dropdown */}
            {entity === "snags" && (
              <Grid item xs={4}>
                {" "}
                <FormControl fullWidth>
                  <InputLabel id="dept-label">Department</InputLabel>
                  <Select
                    multiple
                    label="Department"
                    labelId="dept-label"
                    value={filters.dept}
                    onChange={handleSnagsDeptChange}
                    renderValue={(selected) =>
                      selected
                        .map(
                          (id: any) =>
                            snagsDepartments.find((dept) => dept.id === id)
                              ?.name
                        )
                        .join(", ")
                    }
                  >
                    {snagsDepartments.map((dept: any) => (
                      <MenuItem key={dept.id} value={dept.id}>
                        <Checkbox
                          checked={filters.dept.indexOf(dept.id) > -1}
                        />
                        <ListItemText primary={dept.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {/* 'AODM' Dropdown */}
            <Grid item xs={4}>
              {" "}
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={aodmList}
                value={aodmList.filter((user: any) =>
                  filters.aodm_id.includes(user.id)
                )}
                isOptionEqualToValue={(option: any, value: any) =>
                  +option.id === +value.id
                }
                getOptionLabel={(option: any) => option.fname}
                onChange={(e, val) => {
                  const selectedIds = val.map((user: any) => user.id);
                  setLocalFilters((prevFilters: any) => ({
                    ...prevFilters,
                    aodm_id: selectedIds,
                  }));
                }}
                renderOption={(props, option: any) => (
                  <ListItem {...props} key={option.id} sx={{ width: "100%" }}>
                    <RenderCustomOption {...option} />
                  </ListItem>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="AODM" />
                )}
              />
            </Grid>
          </Stack>
        </Grid>

      {/* Row 2: Date Range Toggle and Action Buttons */}
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{ height: 50 }}
        >
          {/* Date Range Toggle */}
          <ToggleButtonGroup
            color="secondary"
            value={selectedDateRange}
            exclusive
            onChange={handleDateRangeChange}
          >
            <ToggleButton value="today" sx={{ pr: 2, pl: 2 }}>
              <Box
                sx={{
                  padding: 0.1,
                  boxShadow:
                    appliedDateRange === "today"
                      ? "0 4px 2px -2px #0066b2"
                      : "none",
                }}
              >
                Today
              </Box>
            </ToggleButton>

            <ToggleButton value="7days" sx={{ pr: 2, pl: 2 }}>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  padding: 0.1,
                  boxShadow:
                    appliedDateRange === "last_7_days"
                      ? "0 4px 2px -2px #0066b2"
                      : "none",
                }}
              >
                Last 7 Days
                <Tooltip title="Displaying tasks created in the last 7 days">
                  <span>
                    <Iconify
                      icon={"material-symbols:info-outline"}
                      sx={{ mt: 0.5, ml: 0.7 }}
                      width={18}
                      height={18}
                    />
                  </span>
                </Tooltip>
              </Box>
            </ToggleButton>

            <ToggleButton value="30days" sx={{ pr: 3, pl: 3 }}>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  padding: 0.1,
                  boxShadow:
                    appliedDateRange === "" ? "0 4px 2px -2px #0066b2" : "none",
                }}
              >
                Last 30 Days
                <Tooltip title="Displaying tasks created in the last 30 days">
                  <span>
                    <Iconify
                      icon={"material-symbols:info-outline"}
                      sx={{ mt: 0.5, ml: 0.7 }}
                      width={18}
                      height={18}
                    />
                  </span>
                </Tooltip>
              </Box>
            </ToggleButton>

            <ToggleButton value="custom" sx={{ pr: 3, pl: 3 }}>
              <Box
                sx={{
                  padding: 0.1,
                  boxShadow:
                    appliedDateRange === "custom"
                      ? "0 4px 2px -2px #0066b2"
                      : "none",
                }}
              >
                Custom Date Range
              </Box>
            </ToggleButton>
          </ToggleButtonGroup>

          {/* Buttons for Reset and Apply Filters */}
          <Box display="flex" gap={2}>
            <Button
              variant="text"
              sx={{ height: 40, mt: 1 }}
              onClick={resetFilters}
            >
              Reset Filters
            </Button>
            <Button
              variant="contained"
              sx={{ height: 40, mt: 1 }}
              onClick={applyFilters}
            >
              Apply Filters
            </Button>
          </Box>
        </Stack>
      </Grid>

      {/* Row 3: Custom Date Range Input */}
      {customDateRange && (
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction="row" spacing={2}>
              <DateTimePicker
                label="Start Date"
                value={filters.start_date || null}
                onChange={handleStartDateChange}
                format="DD/MM/YYYY hh:mm a"
                slotProps={{
                  textField: {
                    variant: "outlined",
                    size: "small",
                    sx: {
                      borderRadius: "8px",
                    },
                  },
                }}
              />

              <DateTimePicker
                label="End Date"
                value={filters.end_date || null}
                onChange={handleEndDateChange}
                format="DD/MM/YYYY hh:mm a"
                slotProps={{
                  textField: {
                    variant: "outlined",
                    size: "small",
                    sx: {
                      borderRadius: "8px",
                    },
                  },
                }}
              />
            </Stack>
          </LocalizationProvider>
        </Grid>
      )}
    </Grid>
  );
}
