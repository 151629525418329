import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

const Configuration: React.FC = () => {
  const navigate = useNavigate();

  const handleUserClick = () => {
    navigate("/configuration/users");
  };

  const handleDepartmentClick = () => {
    navigate("/configuration/departments");
  };

  const handleLocationClick = () => {
    navigate("/configuration/locations");
  };

  const handleCategoryClick = () => {
    navigate("/configuration/category");
  };
  return (
    <Grid
      container
      spacing={4}
      justifyContent="center"
      sx={{ padding: 14, marginLeft: "10px" }}
    >
      <Grid item xs={12} sm={6} md={6}>
        <Card
          sx={{ borderRadius: 2, cursor: "pointer", padding: 2 }}
          onClick={handleUserClick}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon
                icon="mdi:account-group"
                style={{ fontSize: 78, color: "#5a6e8c", marginRight: 16 }}
              />
              <Typography variant="h6" sx={{ color: "#5a6e8c" }}>
                USERS
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Card
          sx={{ borderRadius: 2, cursor: "pointer", padding: 2 }}
          onClick={handleDepartmentClick}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon
                icon="mdi:office-building"
                style={{ fontSize: 78, color: "#5a6e8c", marginRight: 16 }}
              />
              <Typography variant="h6" sx={{ color: "#5a6e8c" }}>
                DEPARTMENTS
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Card
          sx={{ borderRadius: 2, cursor: "pointer", padding: 2 }}
          onClick={handleLocationClick}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon
                icon="mdi:map-marker"
                style={{ fontSize: 78, color: "#5a6e8c", marginRight: 16 }}
              />
              <Typography variant="h6" sx={{ color: "#5a6e8c" }}>
                LOCATIONS
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Card sx={{ borderRadius: 2, cursor: "pointer", padding: 2 }} onClick={handleCategoryClick}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon
                icon="mdi:tag"
                style={{ fontSize: 78, color: "#5a6e8c", marginRight: 16 }}
              />
              <Typography variant="h6" sx={{ color: "#5a6e8c" }}>
                CATEGORIES
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Configuration;
