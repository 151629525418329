import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Button,
  TextField, Card, CardContent, Typography, Avatar, IconButton
} from '@mui/material';
import { useAuth } from '../context/authContext';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { axiosInstance as axios } from '../utils/axios';
import useAlerts from "src/components/Alerts";

interface FormValues {
  name?: string;
  username?: string;
  password: string;
  email?: string;
  avatar?: string;
}

const EditProfileDialog = ({ open, onClose }: any) => {
  const { user } = useAuth();
  const [initialValues, setInitialValues] = useState<FormValues>({
    name: user?.name,
    username: user?.username,
    password: '',
    email: user?.email,
    avatar: user?.avatar
  });


  const [formValues, setFormValues] = useState<FormValues>(initialValues);
  const [changedValues, setChangedValues] = useState<Partial<FormValues>>({});
  const [userUpdated, setUserUpdated] = useState(false);
  const { showAlert } = useAlerts();

  useEffect(() => {
    // Update initialValues and formValues when the `user` data changes
    setInitialValues({
      name: user?.name,
      username: user?.username,
      password: '',
      email: user?.email,
      avatar: user?.avatar
    });
    setFormValues({
      name: user?.name,
      username: user?.username,
      password: '',
      email: user?.email,
      avatar: user?.avatar
    });
    setChangedValues({});
    setUserUpdated(false);
    setChangedValues({});
    setUserUpdated(false);
  }, [user, open]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
  
    setChangedValues(prevValues => {
      const newValues: Partial<FormValues> = { ...prevValues, [name]: value };
      if (value === initialValues[name as keyof FormValues]) {
        delete newValues[name as keyof FormValues];
      }
      return newValues;
    });
  
    // Set userUpdated to true only if there are changes
    if (value !== initialValues[name as keyof FormValues]) {
      setUserUpdated(true);
    } else {
      setUserUpdated(Object.keys(changedValues).length > 0);
    }
  };
  
  const handleAvatarChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const avatar = reader.result as string;
        setFormValues(prevValues => ({
          ...prevValues,
          avatar
        }));
        setChangedValues(prevValues => {
          const newValues: Partial<FormValues> = { ...prevValues, avatar };
          if (avatar === initialValues.avatar) {
            delete newValues.avatar;
          }
          return newValues;
        });
  
        // Set userUpdated to true only if the avatar changes
        if (avatar !== initialValues.avatar) {
          setUserUpdated(true);
        } else {
          setUserUpdated(Object.keys(changedValues).length > 0);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    if (userUpdated) {
      const payload = {
        user_id: user?.id,
        ...changedValues,
      };
  
      axios.post('/aodm/users/editUser', payload)
        .then(response => {
          console.log(response.data);
          setUserUpdated(false);
          onClose();
          showAlert(`User updated successfully!`, "success");
        })
        .catch(error => console.error('Error:', error));
    } else {
      showAlert(`No changes to update!`, "warning");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Profile</DialogTitle>
      <DialogContent>
        <Card>
          <CardContent>
            <Typography variant="h6">
              {user?.role && user.role >= 5 ? 'AODM Admin' : 'AODM'}
            </Typography>
            <Avatar
              src={formValues.avatar}
              alt="User Avatar"
              sx={{ width: 100, height: 100, margin: 'auto' }}
            />
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="avatar-upload"
              type="file"
              onChange={handleAvatarChange}
            />
            <label htmlFor="avatar-upload">
              <IconButton color="primary" aria-label="upload picture" component="span">
                <PhotoCamera />
              </IconButton>
            </label>
            <TextField
              margin="normal"
              label="Name"
              fullWidth
              name="name"
              value={formValues.name}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              label="Username"
              fullWidth
              disabled
              name="username"
              value={formValues.username}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              label="Password"
              type="password"
              fullWidth
              name="password"
              value={formValues.password}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              label="Email"
              type="email"
              fullWidth
              disabled
              name="email"
              value={formValues.email}
              onChange={handleChange}
            />
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions sx={{ mb: 2, mr: 5, ml: 5, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          size="large"
          onClick={handleSave}
          color="success"
          disabled={!userUpdated}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="large"
          onClick={onClose}
          color="error"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditProfileDialog;
