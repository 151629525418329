import isString from 'lodash/isString';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
// type
import { UploadProps } from './type';
//
// import Image from '../Image';
// import RejectionFiles from './RejectionFiles';
import BlockContent from './BlockContent';
import { Icon } from '@iconify/react';
import IconButton from '@mui/material/IconButton';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  border: `2px dashed ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.secondary,
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  transition: 'border-color 0.3s ease',

  '&:hover': {
    borderColor: theme.palette.primary.main,
    opacity: 0.8,
  },
}));

// ----------------------------------------------------------------------

export default function UploadSingleFile({
  error = false,
  file,
  helperText,
  onClear,
  sx,
  ...other
}: UploadProps) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    ...other,
  });

  const handleClear = () => {
    if (onClear) {
      onClear(); // Call the onClear function passed from parent to remove the file
    }
  };

  return (
    <Box sx={{ width: '100%', p: 2, ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { borderColor: 'primary.main' }),
          ...(error && {
            borderColor: 'error.main',
            color: 'error.main',
          }),
        }}
      >
        <input {...getInputProps()} />
        <BlockContent />
      </DropZoneStyle>
      {file && (file instanceof File || (typeof file === 'string' && file !== '')) && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '16px',
          }}
        >
          <Typography variant="body2" style={{ marginRight: '10px' }}>
            {typeof file !== 'string' ? file.name : file}
          </Typography>
          <IconButton onClick={handleClear} aria-label="Remove attachment">
            <Icon icon="mdi:close" />
          </IconButton>
        </div>
      )}

      {helperText && (
        <Typography sx={{ mt: 1, color: 'text.secondary' }}>
          All file types supported. Max file size: 6 MB
        </Typography>
      )}
    </Box>
  );
}
