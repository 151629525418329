import React, { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAodmAdminCheck } from 'src/utils/helperFunctions';
import useAlerts from "src/components/Alerts";

interface AodmAdminGuardProps {
  children: ReactNode;
}

const AodmAdminGuard: React.FC<AodmAdminGuardProps> = ({ children }) => {
  const {isAdmin} = useAodmAdminCheck();
  const { showAlert } = useAlerts();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdmin) {
      showAlert("Only AODM ADMINS can access this", "warning");
      setTimeout(() => {
        navigate('/observations');
      }, 1000); // Delay navigation by 1 second
    }
  }, [isAdmin, navigate, showAlert]);

  if (!isAdmin) {
    return null; // Return null or some kind of loading/redirect message
  }

  return <>{children}</>;
};

export default AodmAdminGuard;
