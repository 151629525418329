import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import image from "src/assets/NoData.jpg"; // Import image directly

// Styled container for centering content
const RootStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  height: "100%", // Ensure it takes full height of the parent container
  width: "100%", // Ensure it takes full width of the parent container
  textAlign: "center",
}));


const EmptyContent = () => {
  return (
    <RootStyle>
      <img
        src={image}
        alt="No Data"
        style={{
          width: "auto",
          maxWidth: "100%", // Ensure the image is responsive
          maxHeight: "300px", // Limit the max height for consistency
          marginBottom: 100, // Spacing below the image
        }}
      />
    </RootStyle>
  );
};

export default EmptyContent;
