import { useState, useEffect } from "react";
import { Card, Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import ObservationDash from "./observationsDash/ObservationDash";
import InformationDash from "./informationDash/InformationDash";
import SnagsDash from "./snagsDash/SnagsDash";
import DashFilters from "./DashFilters";
import { useTheme } from "@mui/material/styles";
import MuiToggleButton from "@mui/material/ToggleButton";
import { styled } from "@mui/material/styles";
import { axiosInstance as axios } from "src/utils/axios";
import { useAuth } from "src/context/authContext";

// Custom styled ToggleButton to apply background color on toggle-buttons based on selection
const CustomToggleButton = styled(MuiToggleButton)<any>(
  ({ theme, value, entity }: any) => {
    const themeKey = value === "observations" ? "obs" : value;

    return {
      color: entity === value ? "white" : "black",
      backgroundColor:
        entity === value ? theme.palette[themeKey].main : "transparent",
      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: theme.palette[themeKey].main,
        color: "white",
      },
    };
  }
);

const Dashboard = () => {
  const { user } = useAuth();
  const [selectedEntity, setSelectedEntity] = useState("observations");
  const theme = useTheme();
  const [filters, setFilters] = useState({
    start_date: null,
    end_date: null,
    department: [],
    dept: [],
    category: [],
    date_range: "today",
    aodm_id: [],
  });
  const [summaryData, setSummaryData] = useState<any>(null);
  const [requestSummaryData, setRequestSummaryData] = useState<any>(null);
  const handleFiltersUpdate = (newFilters: any) => {
    console.log("Updated Filters:", newFilters);
    setFilters(newFilters);
  };

  const handleToggle = (event: any, newOption: any) => {
    if (newOption !== null) {
      if (newOption === "snags") fetchRequestSummaryData();
      if (
        (newOption === "observations" || newOption === "information") &&
        selectedEntity === "snags"
      )
        fetchSummaryData();
      setSelectedEntity(newOption);
    }
  };

  const fetchSummaryData = async () => {
    try {
      let timestamp = new Date().getTime();
      const response = await axios.get(
        "/aodm/dashboard-summary?time=" + timestamp,
        {
          params: filters,
        }
      );
      setSummaryData(response.data);
    } catch (error) {
      console.error("Error fetching dashboard summary data:", error);
    }
  };

  const fetchRequestSummaryData = async () => {
    try {
      // Extract hotel IDs into the org_ids array
      const org_ids = user?.hotels.map((hotel: any) => hotel.id);
      const filtersForRequests = {
        ...filters,
        org: org_ids,
        created_by: filters.aodm_id,
        aodm: true,
      };
      let timestamp = new Date().getTime();
      const response = await axios.get("/dashboard/summary?time=" + timestamp, {
        params: filtersForRequests,
      });
      setRequestSummaryData(response.data);
    } catch (error) {
      console.error("Error fetching dashboard summary data:", error);
    }
  };

  useEffect(() => {
    if (selectedEntity === "snags") fetchRequestSummaryData();
    else fetchSummaryData();
  }, [filters]);

  return (
    <div
      style={{
        padding: "20px",
        marginLeft: "90px",
        marginRight: "20px",
        marginTop: "10px",
      }}
    >
      <Grid container spacing={0}>
        {/* Entity Toggle Buttons */}
        <Grid item xs={12}>
          <ToggleButtonGroup
            value={selectedEntity}
            exclusive
            color="primary"
            onChange={handleToggle}
            fullWidth
            sx={{ display: "flex" }}
          >
            <CustomToggleButton value="observations" entity={selectedEntity}>
              Observations
            </CustomToggleButton>
            <CustomToggleButton value="snags" entity={selectedEntity}>
              Snags
            </CustomToggleButton>
            <CustomToggleButton value="information" entity={selectedEntity}>
              Information
            </CustomToggleButton>
          </ToggleButtonGroup>
        </Grid>
        {/* Dashboard Filters */}
        <Grid item xs={12}>
          <DashFilters
            setFilters={handleFiltersUpdate}
            entity={selectedEntity}
          />
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ mt: 4, p: 4 }}>
            {selectedEntity === "observations" && (
              <ObservationDash data={summaryData?.observations} />
            )}
            {selectedEntity === "snags" && (
              <SnagsDash requestSummaryData={requestSummaryData} />
            )}
            {selectedEntity === "information" && (
              <InformationDash data={summaryData?.info} />
            )}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
