// TableComponent.tsx
import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Button,
  Typography,
  Switch,
  Box,
  InputBase,
  Divider,
  TablePagination,
  IconButton,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import {
  getObservations,
  getObservationDetails,
  closeObservation,
} from "../../utils/api";
import Filter from "src/components/Filters";
import { formatDateTime, useAodmAdminCheck } from "src/utils/helperFunctions";
import ObservationDetails from "./ObservationDetails";
import CreateObservation from "./CreateObservation";
import useFilters from "src/hook/useFilter";
import { useNotifications } from "src/context/NotificationsContext";
import { axiosInstance as axios } from "src/utils/axios";
import useAlerts from "src/components/Alerts";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteDialog from "src/components/DeleteDialog";

type RowType = {
  aodm: string;
  location: string;
  observation: string;
  category: string;
  department: string;
  status: string;
  id: number;
  created_at: string;
  loc_details: string;
};

type ColumnType = {
  field: keyof RowType;
  headerName: string;
};

const columns: ColumnType[] = [
  { field: "aodm", headerName: "AODM" },
  { field: "location", headerName: "LOCATION" },
  { field: "observation", headerName: "OBSERVATION DETAILS" },
  { field: "category", headerName: "CATEGORY" },
  { field: "department", headerName: "DEPARTMENT" },
  { field: "status", headerName: "STATUS" },
];

interface MyDateRangePickerProps {
  onDateRangeChange: (startDate: string, endDate: string) => void;
  dateRange: { startDate: string; endDate: string } | null; 
}

function MyDateRangePicker({ onDateRangeChange, dateRange }: MyDateRangePickerProps) {
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);

   // Reset date pickers when the parent clears the date range
   useEffect(() => {
    if (!dateRange) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [dateRange]);

  const handleStartDateChange = (date: dayjs.Dayjs | null) => {
    setStartDate(date);
    if (date && endDate) {
      onDateRangeChange(date.toISOString(), endDate.toISOString());
    }
  };

  const handleEndDateChange = (date: dayjs.Dayjs | null) => {
    setEndDate(date);
    if (startDate && date) {
      onDateRangeChange(startDate.toISOString(), date.toISOString());
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ p: 2, borderRadius: 6, maxWidth: '500px', margin: '0 auto' }}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            <DateTimePicker
              label="Start Date"
              value={startDate}
              onChange={handleStartDateChange}
              format="MM/DD/YYYY hh:mm a" // Control the display format
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: 'outlined',
                  size: 'small', // Smaller input size
                  InputLabelProps: { shrink: true }, // Always show label on top
                  sx: {
                    borderRadius: '8px', // Rounded corners
                    backgroundColor: 'white',
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateTimePicker
              label="End Date"
              value={endDate}
              onChange={handleEndDateChange}
              format="MM/DD/YYYY hh:mm a" // Control the display format
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: 'outlined',
                  size: 'small', // Smaller input size
                  InputLabelProps: { shrink: true }, // Always show label on top
                  sx: {
                    borderRadius: '8px', // Rounded corners
                    backgroundColor: 'white',
                  }
                }
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
}



const Observations = () => {
  // const [data, setData] = useState<RowType[]>([]);
  // const [selectedColumnData, setSelectedColumnData] = useState<{
  //   [key: string]: string[];
  // }>({});
  const [tableData, setTableData] = useState<RowType[]>([]);
  const {
    filterOptions,
    selectedColumnData,
    setSelectedColumnData,
    formatFilters,
  } = useFilters();
  const [selectedObservation, setSelectedObservation] = useState(null);
  const { refreshNotifications } = useNotifications();
  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchQuery, setSearchQuery] = useState("");
  const [showTodayObservations, setShowTodayObservations] = useState(false);
  const [todayObservationCount, setTodayObservationCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [dateRange, setDateRange] = useState<{ startDate: string; endDate: string } | null>(null); 
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [ObsToDel, setObsToDelete] = useState(null);
  const { isSuperAdmin } = useAodmAdminCheck();
  // New state to store the today filter
  const [todayFilter, setTodayFilter] = useState<any>(false);
  const { showAlert } = useAlerts();

  const columnToFilterKeyMap: { [key: string]: string } = {
    aodm: "user",
    // location: "locations",
    department: "department",
    category: "category",
    status: "status",
    // Add other mappings as needed
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    fetchTableData(); // Refresh observations list
  };
  const fetchTableData = async (filters: { [key: string]: string } = {}) => {
    try {
      const response = await getObservations({
        ...todayFilter,
        ...filters,
        searchWord: searchQuery, // Add the search query to the API request
        page: page +1 ,
        rowsPerPage: rowsPerPage,
      });
  
      const { total, data } = response;

      // const observations = await getObservations(filters);   
      setTableData(data);
      setTotalCount(total);
      if (!filters.today) {
        const today = new Date();
        const count = data.filter((observation: any) =>
          isToday(observation, today)
        ).length;
        setTodayObservationCount(count);
      } else {
        setTodayObservationCount(data.length);
      }
  
    } catch (error) {
      console.error("Error fetching observations:", error);
    }
  };

  // const handleDateRangeChange = (startDate: string, endDate: string, filters: { [key: string]: string } = {}) => {
  //   const newFilters = {
  //     ...filters,
  //     start_date: startDate,
  //     end_date: endDate,
  //   };
  //   fetchTableData(newFilters);
  // };
  const handleDateRangeChange = (startDate: string, endDate: string) => {
    setDateRange({ startDate, endDate });
    fetchTableData({ start_date: startDate, end_date: endDate });
  };

  const clearDateRange = () => {
    setDateRange(null);
    fetchTableData(); // Fetch without date filters
  };


 const handleTodayObservations = () => {
    const todayFilter = { today: 'true' };
    setTodayFilter(todayFilter); // Persist the today filter
    // fetchTableData(todayFilter);
    setShowTodayObservations(true); // Show close icon
  };

// Function to toggle between all observations and today's observations
const handleToggleChange = () => {
  if (showTodayObservations) {
    setTodayFilter(null); // Clear today filter
    fetchTableData(); // Fetch all observations
    // setTodayObservationCount(0); // Reset the count when showing all observations
  } else {
    handleTodayObservations();
  }
  setShowTodayObservations(!showTodayObservations);
};

  const isToday = (observation: any, today: Date) => {
    const observationDate = new Date(observation.created_at);
    return (
      observationDate.getDate() === today.getDate() &&
      observationDate.getMonth() === today.getMonth() &&
      observationDate.getFullYear() === today.getFullYear()
    );
  };
// Handle search input change
const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  setSearchQuery(e.target.value); // Update search query immediately
  setPage(0)
};

// Debounce the API call inside useEffect
useEffect(() => {
  const delayDebounceFn = setTimeout(() => {
    // Fetch table data after a delay (e.g., 500ms)
    fetchTableData(formatFilters(selectedColumnData));
  }, 500); // Delay of 500ms

  return () => clearTimeout(delayDebounceFn); // Cleanup the timeout if searchQuery changes before delay is over
}, [searchQuery, todayFilter, page, rowsPerPage, selectedColumnData]); // Trigger when searchQuery or other dependencies change

  useEffect(() => {
    fetchTableData();
  }, []);

  const fetchObservations = (filters: { [key: string]: string }) => {
    setPage(0); // Reset page to the first page when a filter is applied
    fetchTableData(filters);
  };

  const fetchObservationDetails = async (id: any) => {
    try {
      const observation = await getObservationDetails(id);
      setSelectedObservation(observation?.data[0]);
    } catch (error) {
      console.error("Error fetching observation details:", error);
    }
  };

  const handleRowClick = (id: any) => {
    fetchObservationDetails(id);
  };
  const closePopup = () => {
    setSelectedObservation(null);
  };

  const handleCategorySave = async (id: any, selectedCategory: any, comments: string) => {
    const data = {
      observation_id: id,
      category_id: selectedCategory,
      comments: comments,
    };
  
    try {
      const response = await axios.post("/aodm/observation/editeObservation", data);
      showAlert("Category reassigned successfully", "success");
      await fetchObservationDetails(id);  // Refresh the observation details
      fetchTableData();                   // Refresh the main table
      refreshNotifications();             // Refresh notifications
    } catch (error) {
      console.error("Error updating category:", error);
      showAlert("Unexpected response, please try again", "warning");
    }
  };

  const handleReassignSave  = async (id: any, selectedDepartment: any, comments: string) => {
    const data = {
      observation_id: id,
      department_id: selectedDepartment,
      comments: comments,
    };
  
    try {
      const response = await axios.post("/aodm/observation/editeObservation", data);
      showAlert("Department reassigned successfully", "success");
      await fetchObservationDetails(id); 
      fetchTableData();                   
      refreshNotifications();             
    } catch (error) {
      console.error("Error updating category:", error);
      showAlert("Unexpected response, please try again", "warning");
    }
  };

  const handleDeleteClick = (ObsId: any) => {
    setObsToDelete(ObsId);
    setDeleteDialogOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setObsToDelete(null);
  };
  const handleDeleteObservation = async () => {
    if(!isSuperAdmin) {
      showAlert("Only AODM SupreAdmins can delete Observation created by other users", "warning");
      setDeleteDialogOpen(false);
      setObsToDelete(null);
      return}
    try {
      await axios.post('/aodm/observation/delete', { observation_id: ObsToDel });
      showAlert("Observation deleted successfully", "success");
      setDeleteDialogOpen(false);
      setObsToDelete(null);
      fetchTableData();
    } catch (error) {
      console.error('Failed to delete Observation', error);
    }
  };
  
  const stopDetailPropagation = (event:any) => {
    // Prevent the event from propagating to the TableRow and opening detail dialog
    event.stopPropagation();
  };
  const filteredData = tableData
  return (
    <div style={{ padding: '20px' }}>
    <Box sx={{ maxWidth: "90%", margin: "auto", padding: "20px" }}>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          backgroundColor: theme.palette.obs.main,
          color: "#fff",
          borderRadius: "8px",
          textTransform: "none",
          padding: "10px 20px",
          marginLeft: "30px",
          marginTop: "-20px",
          display: "flex",
          alignItems: "center",
          gap: "8px",
          marginBottom: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "24px",
            height: "24px",
            border: "2px solid #fff",
            borderRadius: "4px",
            "&:hover": {
              backgroundColor: theme.palette.obs.main,
              boxShadow: "none",
            },
          }}
        >
          <Icon icon="mdi:plus" color="#fff" />
        </Box>
        CREATE OBSERVATION
      </Button>
      <br />
      <CreateObservation
        open={openDialog}
        onClose={handleClose}
        fetchObservations={fetchObservations}
      />
      <TableContainer
        component={Paper}
        style={{
          maxWidth: "97.5%",
          margin: "auto",
          padding: "0 20px",
          marginLeft: "32px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
            padding: "10px 0",
          }}
        >
          <Box
            onClick={handleTodayObservations}
            sx={{
              backgroundColor: "transparent",
              color: theme.palette.obs.main,
              borderRadius: "8px",
              textTransform: "none",
              border: "1px solid #6c8ebf",
              padding: "8px 16px",
              fontSize: "16px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Today’s Observations (
              {todayObservationCount}
            )
          </Box>
          <Switch
        checked={showTodayObservations}
        onChange={handleToggleChange}
        color="primary"
      />
        {/* <MyDateRangePicker onDateRangeChange={handleDateRangeChange}/> */}
        {/* <MyDateRangePicker 
        onDateRangeChange={handleDateRangeChange} 
        dateRange={dateRange} // Pass current date range
        // clearDateRange={clearDateRange} // Pass clear function to reset date picker
      />
        {dateRange && (
        <button onClick={clearDateRange} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
          <CancelIcon />
        </button>
      )} */}
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
    <MyDateRangePicker
      onDateRangeChange={handleDateRangeChange}
      dateRange={dateRange} // Pass current date range
    />
    {dateRange && (
      <button
        onClick={clearDateRange}
        style={{
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          padding: 0, // Remove extra padding
          margin: 0, // Remove extra margin
          display: 'flex', // Align the icon properly
          alignItems: 'center',
        }}
      >
        <CancelIcon style={{ fontSize: '24px', color: '#f00' }} />
      </button>
    )}
  </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #A6BED4",
              borderRadius: "8px",
              padding: "2px 10px",
              marginLeft: "auto",
              width: "300px",
            }}
          >
            <Icon icon="mdi:search" />
            <InputBase
              placeholder="Search observations"
              inputProps={{ "aria-label": "search" }}
              sx={{ ml: 1, width: "100%" }}
              value={searchQuery}
              // onChange={(e) => setSearchQuery(e.target.value)}
              onChange={handleSearchChange}
            />
          </Box>
        </Box>

        <Divider />
        <Table>
          <TableHead>
            <TableRow sx={{ borderBottom: `5px solid #F6F5F5` }}>
              {columns.map((column) => {
                const optionsForField =
                  filterOptions[columnToFilterKeyMap[column.field]] || [];
                const isObservationColumn = column.field === "observation";
                const isLocationColumn = column.field === "location";

                return (
                  <TableCell
                    key={column.field}
                    sx={{
                      fontSize: "16px",
                      paddingTop: isObservationColumn || isLocationColumn ? "32px" : "",
                      color: theme.palette.obs.main,
                    }}
                  >
                    {isObservationColumn || isLocationColumn ? (
                      column.headerName
                    ) : (
                      <Filter
                        options={optionsForField}
                        field={column.field}
                        headerName={column.headerName}
                        selectedColumnData={selectedColumnData}
                        setSelectedColumnData={setSelectedColumnData}
                        fetchObservations={fetchObservations}
                        formatFilters={formatFilters}
                      />
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <React.Fragment key={row.id}>
                  <TableRow
                    onClick={() => handleRowClick(row.id)}
                    style={{ cursor: "pointer" }}
                  >
                    {columns.map((column) => (
                      <TableCell key={column.field}>
                        {column.field === "status" ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: 6,
                            }}
                          >
                            <Chip
                              label={row[column.field]}
                              color={
                                row[column.field] === "OPEN"
                                  ? "warning"
                                  : "success"
                              }
                            />
                            <Typography variant="caption" color="textSecondary">
                              ID: {row.id}
                            </Typography>
                          </div>
                        ) : column.field === "observation" ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 6,
                            }}
                          >
                            <Typography variant="body2">
                              {row[column.field]}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              {formatDateTime(row.created_at)}
                            </Typography>
                          </div>
                        ) : column.field === "location" ? (
                          <Typography variant="body2">
                            {row.loc_details}
                            {/* {row.loc_details && row.loc_details !== "" && (
                              <span
                                style={{
                                  display: "block",
                                  marginTop: 4,
                                  fontSize: "0.875rem",
                                  color: "#888",
                                }}
                              >
                                {row.loc_details}
                              </span>
                            )} */}
                          </Typography>
                        ) : (
                          row[column.field]
                        )}
                      </TableCell>
                      
                    ))}

                    <TableCell>
                    {isSuperAdmin && (
                    <IconButton size="small" onClick={(event) => { stopDetailPropagation(event); handleDeleteClick(row.id); }}>
                      <Icon icon="mdi:trashcan" />
                    </IconButton>
                     )}
                  </TableCell>
                  </TableRow>
                  {/* {filteredData.length - 1 !== row.id && (
                    <TableRow>
                      <TableCell colSpan={columns.length}></TableCell>
                    </TableRow>
                  )} */}
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 75]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      {selectedObservation && (
        <ObservationDetails
          observation={selectedObservation}
          onClose={closePopup}
          fetchTableData={fetchTableData}
          onCategorySave={handleCategorySave} 
          onDepartmentSave={handleReassignSave}
        />
      )}
      
       <DeleteDialog
        open={deleteDialogOpen}
        onClose={handleCancelDelete}
        onConfirm={handleDeleteObservation}
        title="Confirm Deletion"
        description="Are you sure you want to delete this Observation?"
      />
    </Box>
    </div>
  );
};

export default Observations;
