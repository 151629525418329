import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputBase,
  Switch,
  Divider,
  Select,
  MenuItem,
  SelectChangeEvent,
  TablePagination
} from "@mui/material";
import { Icon } from "@iconify/react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import CreateSnag from "./CreateSnagDialog";
import { axiosInstance as axios } from "../../utils/axios";
import { useAuth } from "src/context/authContext";
import {formatDateTime} from "src/utils/helperFunctions";
import SnagDetails from "./SnagDetails";
import Filter from "src/components/Filters";
import useFilters from "src/hook/useFilter";


type RowType = {
  user: string;
  location: string;
  snag: string;
  department: string;
  status: string;
  id: number;
  created_at: string;
};

type ColumnType = {
  field: keyof RowType;
  headerName: string;
};
const columns: ColumnType[] = [
  { field: "user", headerName: "AODM" },
  { field: "location", headerName: "LOCATION" },
  { field: "snag", headerName: "SNAG" },
  { field: "department", headerName: "DEPARTMENT" },
  { field: "status", headerName: "STATUS" },
];

const statusColor = (status: string) => {
  if (status == "Pending") {
    return "#FFBF00";
  } else if (status == "Rejected") {
    return "#C85250";
  } else if (status == "Closed") {
    return "#59981A";
  } else if (status == "Parked") {
    return "#2E8BC0";
  } else {
    return "#2E8BC0";
  }
};

// const StatusChip = styled(Chip)<{ status: string }>(({ status }) => ({
//     backgroundColor:
//         status === "OPEN" ? "#ff9800" : status === "CLOSED" ? "#4caf50" : "#1976d2",
//     color: "#fff",
// }));

const RenderSnags = () => {
  const theme = useTheme();
  const [selectedOption, setSelectedOption] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [requestsData, setRequestsData] = useState<any>([]);
  // const [isFetching, setIsFetching] = useState(false);
  // const [pageData, setPageData] = useState<any>();
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalRows, setTotalRows] = useState(0);

  const [searchQuery, setSearchQuery] = useState("");
  const [detailOpen, setDetailOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);

  const [tableData, setTableData] = useState<RowType[]>([]);
  const { filterOptions, selectedColumnData, setSelectedColumnData, formatFilters } = useFilters();
  const [showTodaySnags, setShowTodaySnags] = useState(false);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { user } = useAuth();

  const columnToFilterKeyMap: { [key: string]: string } = {
    user: 'user',
    // location: 'locations',
    department: 'department',
    category: 'category',
    status: 'status'
    // Add other mappings as needed
  };

  useEffect(() => {
    fetchTableData();
  }, [page, rowsPerPage]);

  // const fetchTableData = async (filters: { [key: string]: string } = {}) => {
  //   try {
  //     const response = await axios.get(`hotel/${user?.hotel_id}/desk/0/requests/All?assign_to=anyone&trig=aodm`, { params: filters });
  //     setTableData(response.data);
  //   } catch (error) {
  //     console.error('Error fetching table data:', error);
  //   }
  // };
  const fetchTableData = async (filters: { [key: string]: string } = {}) => {
    let cacheBuster = new Date().getTime();
    try {
      const response = await axios.get(
        `hotel/${user?.hotel_id}/desk/0/requests/All`, 
        { 
          params: { 
            assign_to: 'anyone', 
            trig: 'aodm', 
            time: cacheBuster, 
            searchWord: searchQuery,
            page: page + 1, // API might be 1-based, so increment page by 1
            per_page: rowsPerPage,
            ...filters 
          } 
        }
      );
      console.log('Getting Snags');
      setTableData(response.data.data);
      setTotalRows(response.data.total); // Assuming the total count is inside response.data.total
    } catch (error) {
      console.error('Error fetching table data:', error);
    }
  };

  const fetchObservations = (filters: { [key: string]: string }) => {
    setPage(0);
    fetchTableData(filters);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (user?.hotels?.length > 0) {
      setSelectedOption(user?.hotels[0].id); // Set default to the first hotel's ID
    }
  }, [user]);

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value as string);
  };

  const handleDetailOpen = (row: any) => {
    setSelectedRowData(row);
    setDetailOpen(true);
  };

  const handleDetailClose = () => {
    setDetailOpen(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  // Debounce the API call inside useEffect
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Fetch table data after a delay (e.g., 500ms)
      fetchTableData(formatFilters(selectedColumnData));
    }, 500); // Delay of 500ms

    return () => clearTimeout(delayDebounceFn); // Cleanup the timeout if searchQuery changes before delay is over
  }, [searchQuery, page, rowsPerPage, selectedColumnData]); // Trigger when searchQuery or other dependencies change

  const handleToggleChange = () => {
    if (showTodaySnags) {
      handleResetSnags();
    } else {
      handleTodaySnags();
    }
    setShowTodaySnags(!showTodaySnags);
  };

  const handleTodaySnags = () => {
    const today = new Date();
    const filteredData = tableData.filter((snag) =>
      isToday(snag, today)
    );
    setTableData(filteredData);
    // setTableData(filteredData);
    setShowTodaySnags(true); // Show close icon
  };

  const isToday = (snag: any, today: Date) => {
    const snagDate = new Date(snag.created_at);
    return (
      snagDate.getDate() === today.getDate() &&
      snagDate.getMonth() === today.getMonth() &&
      snagDate.getFullYear() === today.getFullYear()
    );
  };

  const handleResetSnags = () => {
    fetchTableData();
    setTableData(tableData);
    setShowTodaySnags(false); // Hide close icon
  };

  const filteredData = tableData
    // .filter((row) => {
    //   return Object.values(row).some(
    //     (value: any) => 
    //       value !== null && (
    //         value.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
    //         row.id.toString().includes(searchQuery)
    //       )
    //   );
    // });
  return (
    <Box sx={{ maxWidth: "90%", margin: "auto", padding: "20px" }}>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          backgroundColor: theme.palette.snags.main,
          color: "#fff",
          borderRadius: "8px",
          textTransform: "none",
          padding: "10px 20px",
          marginLeft: "48px",
          display: "flex",
          alignItems: "center",
          gap: "8px",
          marginBottom: '15px',
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "24px",
            height: "24px",
            border: "2px solid #fff",
            borderRadius: "4px",
          }}
        >
          <Icon icon="mdi:plus" color="#fff" />
        </Box>
        CREATE SNAG
      </Button>
      <br />
      <TableContainer
        component={Paper}
        style={{
          maxWidth: "94%",
          margin: "auto",
          padding: "0 20px",
          marginLeft: "48px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
            padding: "10px 0",
          }}
        >
          {/* <Select
            value={selectedOption}
            onChange={handleSelectChange}
            sx={{
              width: "200px", 
              borderRadius: "8px",
              border: "1px solid #1a237e",
              padding: "0", 
              height: "38px",
              display: "flex",
              alignItems: "center",
              "& .MuiSelect-select": {
                padding: "0 4px", 
                height: "100%", 
                display: "flex",
                alignItems: "center",
              },
            }}
          >
            {user?.hotels?.map((org: any) => (
              <MenuItem key={org.id} value={org.id}>
                {org.name}
              </MenuItem>
            ))}
           
          </Select> */}
          <Box
            onClick={handleTodaySnags}
           sx={{
              backgroundColor: "transparent",
              color: theme.palette.snags.main,
              borderRadius: "8px",
              textTransform: "none",
              border: "1px solid #6c8ebf",
              padding: "8px 16px",
              fontSize: "16px",
              // fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Today’s Snags (
            {
              tableData.filter((snag) =>
                isToday(snag, new Date())
              ).length
            }
            )
          </Box>
          <Switch
        checked={showTodaySnags}
        onChange={handleToggleChange}
        color="primary"
        sx={{
          marginRight: "auto",
          cursor: "pointer",
          fontSize: "20px",
        }}
      />
          
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #1a237e",
              borderRadius: "8px",
              padding: "2px 10px",
              width: "300px",
            }}
          >
            <Icon icon="mdi:magnify" />
            <InputBase
              placeholder="Search snags"
              inputProps={{ "aria-label": "search" }}
              sx={{ ml: 1, width: "100%" }}
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Box>
        </Box>
        <br />
        <Divider />
        <Table>
          <TableHead>
            <TableRow sx={{ borderBottom: `5px solid #F6F5F5` }}>
              {columns.map((column) => {
                const optionsForField = filterOptions[columnToFilterKeyMap[column.field]] || [];
                const isSnagColumn = column.field === 'snag';
                const isLocationColumn = column.field === 'location';
                return (
                  <TableCell key={column.field} sx={{ fontSize: '16px', paddingTop: isSnagColumn || isLocationColumn ? '32px' : '', color: theme.palette.obs.main }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {isSnagColumn  || isLocationColumn ? (
                        column.headerName
                      ) : (
                        <Filter
                          options={optionsForField}
                          field={column.field}
                          headerName={column.headerName}
                          selectedColumnData={selectedColumnData}
                          setSelectedColumnData={setSelectedColumnData}
                          fetchObservations={fetchObservations}
                          formatFilters={formatFilters}
                        />
                      )}
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row: any, rowIndex: any) => (
              <React.Fragment key={rowIndex}>
                <TableRow onClick={() => handleDetailOpen(row)} style={{ cursor: 'pointer' }}>
                  <TableCell>{row.user?.name}</TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      {row.unit}
                      {row.gc === 1 && (
                        <span style={{ display: 'block', marginTop: '5px', color: '#888'  }}>
                          <Icon icon="fa-solid:user" /> Passenger Complaint
                        </span>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      {row.priority !== "normal" && (
                        <Box
                          style={{
                            backgroundColor: "#CD5C5C",
                            color: "white",
                            textAlign: "center",
                            width: "90px",
                            borderRadius: "3px",
                            fontSize: "small",
                          }}
                        >
                          {/* {row.priority} */}
                          {row.priority.charAt(0).toUpperCase() + row.priority.slice(1)}
                        </Box>
                      )}
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {row.items[0]?.name}
                      </Box>
                      <Box sx={{ color: theme.palette.snags.main }}>
                        {row.comments}
                      </Box>
                      <Box
                        sx={{
                          color: theme.palette.snags.main,
                          fontSize: "12px",
                        }}
                      >
                        {formatDateTime(row.created_at)}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>{row.desk?.name}</TableCell>
                  <TableCell>
                    <Box
                      style={{
                        backgroundColor: statusColor(row.status_text),
                        color: "white",
                        textAlign: "center",
                        width: "90px",
                        borderRadius: "3px",
                      }}
                    >
                      {row.status_text}
                    </Box>
                    <Box color={"gray"} fontSize={"small"}>
                      ID: {row.id}
                      {row.extId && <Box>Ext ID: {row.extId}</Box>}
                    </Box>
                  </TableCell>
                </TableRow>

              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        <TablePagination
        rowsPerPageOptions={[25, 50, 75]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </TableContainer>
      <CreateSnag open={openDialog} onClose={handleClose} getdata={fetchTableData} />
      <SnagDetails
        open={detailOpen}
        onClose={handleDetailClose}
        data={selectedRowData}
        getdata={fetchTableData}
      />
    </Box>
  );
};

export default RenderSnags;
