import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CardHeader,
  Box,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import EmptyContent from "src/components/EmptyContent";

const MostReportedTasks = ({ data }: { data: any[] }) => {
  return (
    <Card sx={{ maxHeight: "420px", m: 2, height: "100%" }}>
      <CardHeader title="Most Reported Tasks" sx={{ mb: 1 }} />
      {data && data.length > 0 ? (
        <TableContainer
          component={Paper}
          sx={{ maxHeight: "400px", overflowY: "auto" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Task Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Department</strong>
                </TableCell>
                <TableCell>
                  <strong>Count</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((task: any, index: number) => (
                <TableRow key={index}>
                  <TableCell>{task.item_name}</TableCell>
                  <TableCell>{task.department_name}</TableCell>
                  <TableCell>{task.count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <EmptyContent />
      )}
    </Card>
  );
};

const TrendChart = ({ data }: any) => {
  const [isLastWeek, setIsLastWeek] = useState<boolean>(true);
  const [chartData, setChartData] = useState<any>({
    options: {
      chart: { type: "line" },
      xaxis: { categories: [], labels: { rotate: 0 } },
      yaxis: { title: { text: "Number of Tasks" } },
      legend: { show: true },
    },
    series: [
      { name: "Tasks Created", data: [] },
      { name: "Tasks Closed", data: [] },
    ],
  });

  // Process and format the chart data based on the selected period (Last Week or Last 6 Months)
  const processChartData = () => {
    const createdData: number[] = [];
    const closedData: number[] = [];
    const labels: string[] = [];

    if (isLastWeek) {
      const dates = Object.keys(data?.created || {});
      dates.forEach((date) => {
        labels.push(date);
        // Ensure data is a valid number (fallback to 0 if invalid)
        createdData.push(
          !isNaN(data?.created[date]) ? Math.round(data?.created[date]) : 0
        );
        closedData.push(
          !isNaN(data?.closed[date]) ? Math.round(data?.closed[date]) : 0
        );
      });
    } else {
      const months = Object.keys(data?.createdMonthly || {});
      months.forEach((month) => {
        labels.push(month);
        // Ensure data is a valid number (fallback to 0 if invalid)
        createdData.push(
          !isNaN(data?.createdMonthly[month])
            ? Math.round(data?.createdMonthly[month])
            : 0
        );
        closedData.push(
          !isNaN(data?.closedMonthly[month])
            ? Math.round(data?.closedMonthly[month])
            : 0
        );
      });
    }

    // Set chart data state with the processed data
    setChartData((prev: any) => ({
      ...prev,
      options: {
        ...prev.options,
        xaxis: { categories: labels },
      },
      series: [
        { name: "Tasks Created", data: createdData },
        { name: "Tasks Closed", data: closedData },
      ],
    }));
  };

  // Effect hook to process data when either `data` or `isLastWeek` changes
  useEffect(() => {
    if (data) {
      processChartData();
    }
  }, [data, isLastWeek]);

  return (
    <Card sx={{ maxHeight: 450, margin: 2, height: "100%" }}>
      <CardHeader title="Trend of Tasks Created vs. Closed" sx={{ mb: 1 }} />
      <Box sx={{ p: 2 }}>
        <FormControlLabel
          control={
            <Switch
              checked={isLastWeek}
              onChange={() => setIsLastWeek((prev) => !prev)}
              color="primary"
            />
          }
          label={isLastWeek ? "Last Week" : "Last 6 Months"}
        />
        {chartData.series[0].data.length > 0 ? (
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="line"
            width="100%"
            height={320}
          />
        ) : (
          <EmptyContent />
        )}
      </Box>
    </Card>
  );
};

const StatusBreakDownChart = ({ statusCount, totalCount }: any) => {
  const theme = useTheme();
  const [chartOptions, setChartOptions] = useState<ApexOptions>({
    chart: {
      type: "donut",
    },
    labels: ["Pending", "Parked", "Rejected", "Closed"],
    legend: {
      show: false, // Hide the legend
    },
    colors: [
      theme.palette.warning.main,
      theme.palette.primary.main,
      theme.palette.error.main,
      theme.palette.success.dark,
    ],
    responsive: [
      {
        breakpoint: 680,
        options: {
          chart: {
            width: "100%",
          },
        },
      },
    ],
    dataLabels: {
      enabled: true,
      formatter: (val: any) => {
        return ""; // Hide the individual value labels
      },
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        colors: ["#000"],
      },
    },
  });

  const [chartSeries, setChartSeries] = useState<number[]>([0, 0, 0, 0]);

  useEffect(() => {
    if (statusCount) {
      setChartSeries([
        statusCount.pending || 0,
        statusCount.parked || 0,
        statusCount.rejected || 0,
        statusCount.closed || 0,
      ]);
    }
  }, [statusCount]);

  const isEmpty = chartSeries.every((count) => count === 0);

  return (
    <Card sx={{ maxHeight: 420, margin: 2, height: "100%" }}>
      <CardHeader title="Task Breakdown by Status" sx={{ mb: 1 }} />
      {isEmpty ? (
        <EmptyContent />
      ) : (
        <div style={{ position: "relative", height: "400px" }}>
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="donut"
            width="100%"
            height="340"
          />
          <div
            style={{
              position: "absolute",
              top: "40%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              fontSize: "24px",
              fontWeight: "bold",
            }}
          >
            <div>Total</div>
            <div>{totalCount || 0}</div>
          </div>
        </div>
      )}
    </Card>
  );
};

export { MostReportedTasks, TrendChart, StatusBreakDownChart };
