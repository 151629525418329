import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Grid,
  Card,
  IconButton,
  InputBase,
  Chip,
  Button,
  DialogActions,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useTheme } from "@mui/material/styles";
import { format, formatDistanceToNow } from "date-fns";
import {formatDateTime} from "src/utils/helperFunctions";
import SnagHistoryLog from "./SnagHistoryLog";

interface SnagDetailsProps {
  open: boolean;
  onClose: () => void;
  data: any;
  getdata:any;
}

interface Attachment {
  url: string;
}

interface Meta {
  attachments?: Attachment[];
}

interface ImageData {
  meta?: Meta;
}

// Function to find the oldest valid attachment URL (last in the list)
const getValidImageUrl = (images: ImageData[] | null) => {
  if (!Array.isArray(images)) return null;

  for (let i = images.length - 1; i >= 0; i--) {
    const attachments = images[i].meta?.attachments;
    if (attachments?.length) {
      for (let j = attachments.length - 1; j >= 0; j--) {
        if (attachments[j]?.url) {
          return attachments[j].url;
        }
      }
    }
  }
  return null;
};

const SnagDetails: React.FC<SnagDetailsProps> = ({ open, onClose, data, getdata }) => {
  const [isreassignOpen, setReassignOpen] = useState(false);
  const [image, setimage] = useState<any>([]);

  const theme = useTheme();

  if (!data) {
    return null;
  }
  const imageUrl = getValidImageUrl(image);

  const createdAt = new Date(data.created_at);
  const formattedCreatedAt = format(createdAt, "MMMM d, hh:mm a");
  const relativeTime = formatDistanceToNow(createdAt, { addSuffix: true });
  const imageUrls =
    data.attachments && data.attachments.length > 0 ? data.attachments : [];

  const statusColor = (status: string) => {
    if (status == "Pending") {
      return "#FFBF00";
    } else if (status == "Rejected") {
      return "#C85250";
    } else if (status == "Closed") {
      return "#59981A";
    } else if (status == "Parked") {
      return "#2E8BC0";
    } else {
      return "#2E8BC0";
    }
  };

  const handleReassignOpen = () => {
    setReassignOpen(true);
  };
  // const handleReassignOpen = () => setReassignOpen(true);
  const handleReassignClose = () => setReassignOpen(false);

  console.log("isreassignOpen",isreassignOpen);
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 20,
        },
      }}
    >
      <DialogTitle>
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", textAlign: "center" }}
        >
          Snag Details
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8, color: "grey[500]" }}
        >
          <Icon icon="mdi:close" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ padding: 2 }}>
          <Card
            sx={{
              p: 2,
              mt: 2,
              mb: 2,
              color: "#5A5A5A",
              border: "1.4px dashed #7A96AF",
              borderRadius: "12px",
            }}
          >
            <Grid container spacing={2} gap={2}>
              <Grid
                item
                xs={5}
                md={3}
                sx={{ maxHeight: "500px", overflowY: "auto" }}
              >
                 {/* {
                    image[0]?.meta?.attachments?.length > 0 && (
                      <a 
                        href={image[0]?.meta?.attachments[0]?.url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                      >
                        <Box
                          component="img"
                          sx={{
                            width: 167.3,
                            height: 167.3,
                            objectFit: "cover",
                          }}
                          src={image[0]?.meta?.attachments[0]?.url}
                          alt="Image" 
                        />
                      </a>
                    )
                  } */}
                  {imageUrl && (
        <a href={imageUrl} target="_blank" rel="noopener noreferrer">
          <Box
            component="img"
            sx={{
              width: 167.3,
              height: 167.3,
              objectFit: "cover",
            }}
            src={imageUrl}
            alt="Image"
          />
        </a>
      )}
              </Grid>
              <Grid item xs>
                <Box
                  sx={{
                    bgcolor: "#7A96AF",
                    borderRadius: 2,
                    p: 0.7,
                    display: "inline-block",
                    color: "#231F20",
                  }}
                >
                  <Typography variant="body2">
                    <Icon
                      icon="line-md:text-box"
                      width={15}
                      height={15}
                      style={{ position: "relative", top: 3 }}
                    />{" "}
                    ID: {data?.id}
                  </Typography>
                </Box>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#231F20",
                    mb: 2,
                    mt: 2,
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  {" "}
                  <Icon
                    icon="line-md:map-marker-alt-filled"
                    width={20}
                    height={20}
                    style={{ position: "relative", top: 3 }}
                  />{" "}
                  {data.unit}
                </Typography>

                <Box
                  sx={{
                    p: 1,
                    display: "inline-flex",
                    alignItems: "center",
                    backgroundColor: theme.palette.information.light,
                    borderRadius: "8px",
                  }}
                >
                  <Typography variant="body1" sx={{ color: "black" }}>
                    {data?.items[0]?.name}
                  </Typography>
                  <Typography variant="body1" sx={{ color: "black" }}>
                    {data.comments}
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#2D2D2D",
                    mb: 2,
                    mt: 2,
                    width: "100%",
                  }}
                >
                  {data?.department}
                </Typography>
                <Box sx={{ mt: 3 }}>
                  <Grid container spacing={-12}>
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                          color: theme.palette.information.main,
                        }}
                      >
                        <Icon icon="material-symbols:person" />
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          AODM:
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                          color: theme.palette.information.main,
                        }}
                      >
                        <Icon icon="material-symbols:calendar-today" />
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          Created at:
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                          color: theme.palette.information.main,
                        }}
                      >
                        <Icon icon="charm:id" />
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          Department:
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                          color: theme.palette.information.main,
                        }}
                      >
                        <Icon icon="material-symbols:check-circle" />
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          Status:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body2"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                          color: "#2D2D2D",
                        }}
                      >
                        {data?.user?.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                          color: "#2D2D2D",
                        }}
                      >
                        {" "}
                        {formatDateTime(data?.created_at)}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                          color: "#2D2D2D",
                        }}
                      >
                        {data?.desk?.name}

                        <Box
                          onClick={handleReassignOpen}
                          sx={{
                            //color: "#fff",
                            // "&:hover": { backgroundColor: "#0056b3" },
                            fontWeight: "bold",
                            paddingLeft:"10px",
                            cursor: "pointer",
                            textDecoration: "underline" 
                          }}
                        >
                          Change Department?
                        </Box>
                      </Typography>
                     
                      <Chip
                        label={data.status_text}
                        style={{
                          backgroundColor: statusColor(data.status_text),
                          color: "white",
                        }}
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Card>
          <Box
            sx={{ borderTop: "1px solid #ccc", paddingTop: 2, marginTop: 2 }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#231F20",
                mb: 2,
                fontWeight: "bold",
                width: "100%",
              }}
            >
              Snag History / Audit Log
            </Typography>
            <SnagHistoryLog  requestData={data} onClose={onClose} isreassignOpen={isreassignOpen} handleReassignClose={handleReassignClose} getTabledata={getdata} setimage={setimage}/>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SnagDetails;
