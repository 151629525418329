import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Card,
  Grid,
  Box,
  CardContent,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  StatusBreakdown,
  AnalysisTablesandCharts,
} from "./ObservationAnalytics";

const ObservationDash = ({ data }: any) => {
  const theme = useTheme();
  const [observationData, setObservationData] = useState({
    created: 0,
    open: 0,
    closed: 0,
    closureRate: 0,
    openPercent: 0,
    closedPercent: 0,
  });

  const processData = () => {
    if (!data) return;

    const total = data?.observationsCount;
    const open = data?.openObservationsCount;
    const closed = data?.closedObservationsCount;

    // Calculate closure rate
    const closureRate =
      total > 0 ? Number(((closed / total) * 100).toFixed(2)) : 0;

    // Calculate open and closed percentages
    const openPercent =
      total > 0 ? Number(((open / total) * 100).toFixed(2)) : 0;
    const closedPercent =
      total > 0 ? Number(((closed / total) * 100).toFixed(2)) : 0;

    setObservationData({
      created: total,
      open,
      closed,
      closureRate,
      openPercent,
      closedPercent,
    });
  };

  useEffect(() => {
    processData();
  }, [data]);

  // Status Card component for displaying status counts
  const StatusCard = ({ status, count }: any) => {
    const colorMap: { [key: string]: string } = {
      Created: "#002D62", // Custom color for 'Total'
      Open: theme.palette.warning.main, // For 'Open'
      Closed: theme.palette.success.dark, // For 'Closed'
      "Closure Rate": theme.palette.info.main, // For 'Closure Rate'
    };

    const cardColor = colorMap[status] || theme.palette.grey[500];

    return (
      <Card
        variant="outlined"
        sx={{
          textAlign: "center",
          maxHeight: 70,
          width: "100%",
          flex: 1,
          maxWidth: 300,
          m: 1,
          p: 1,
          border: "6px outset",
          borderRadius: 4,
          borderColor: cardColor,
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ mr: 2, ml: 1, textAlign: "center" }}>
            {status}
          </Typography>
          <Card
            sx={{
              backgroundColor: cardColor,
              borderRadius: 10,
              padding: 0.2,
              width: "40%",
              display: "flex", // Set to flex for count alignment
              justifyContent: "center", // Center the count horizontally
              alignItems: "center", // Center the count vertically
              color: "white",
            }}
          >
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              {status === "Closure Rate" ? `${count}%` : count}
            </Typography>
          </Card>
        </CardContent>
      </Card>
    );
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        {/* Task Breakdown by Status Cards */}
        <Grid item xs={12} md={12} lg={4} sx={{ maxHeight: 540 }}>
          <Card sx={{ width: "100%" }}>
            <CardContent>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                {observationData && (
                  <>
                    <StatusCard
                      status="Created"
                      count={observationData.created}
                    />
                    <StatusCard status="Open" count={observationData.open} />
                    <StatusCard
                      status="Closed"
                      count={observationData.closed}
                    />
                    <StatusCard
                      status="Closure Rate"
                      count={observationData.closureRate}
                    />
                  </>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Status Breakdown Chart */}
        <Grid item xs={12} md={12} lg={8}>
          <StatusBreakdown
            open={observationData.openPercent}
            closed={observationData.closedPercent}
          />
        </Grid>

        {/* Dept-Category Analysis Tables */}
        <Grid item xs={12} md={12} lg={12}>
          <AnalysisTablesandCharts data={data?.analysisTableData} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ObservationDash;
