// contexts/authContext.tsx

import React, { createContext, useContext, useState, ReactNode, useEffect  } from "react";
import { apiLogin } from "../utils/authservice";
import { getStorage, setStorage } from "../utils/storage";
import { axiosInstance as axios } from "../utils/axios";

type User = {
  api_token: string;
  id:number;
  hotel_id: number; 
  hotels:any;
  hotel:any;
  fname:string;
  name:string;
  lname:string;
  avatar:string;
  username:string;
  email:string;
  role:number;
  timezone:any;
  // Add other user properties if needed
};

type AuthContextType = {
  user: User | null;
  loading: boolean;
  isAuthenticated: boolean;
  isInitialized: boolean;
  login: (u: string, p: string) => Promise<void>;
  logout: () => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const storedUser = getStorage("user");
    if (storedUser) {
      setUser(storedUser);
      axios.defaults.headers.common = {
        Authorization: `Bearer ${storedUser.api_token}`,
      };
    }
    setLoading(false);
    setIsInitialized(true);
  }, []);

  const login = async (u: string, p: string) => {
    const userData = await apiLogin(u, p);
    setStorage("user", userData);
    setUser(userData);
    axios.defaults.headers.common = {
      Authorization: `Bearer ${userData.api_token}`,
    };
  };

  const logout = () => {
    setUser(null);
    setStorage("user", null);
    delete axios.defaults.headers.common["Authorization"];
  };
  return (
    <AuthContext.Provider value={{ user, loading, isAuthenticated: !!user, isInitialized, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
